import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { ReactNode, useState } from "react";

import { Checkbox } from "../checkbox";
import { ChevronDown, ChevronUp } from "../icons";

interface SelectItemProps {
  value: string;
  children: ReactNode;
  disabled?: boolean;
}

interface SelectProps {
  type: "single" | "multi";
  trigger: ReactNode;
  items: SelectItemProps[];
  onCheckedChange?: (value: SelectItemProps["value"][]) => void;
  disabled?: boolean;
  align?: DropdownMenu.DropdownMenuContentProps["align"];
  sideOffset?: DropdownMenu.DropdownMenuContentProps["sideOffset"];
  defaultSelected?: SelectItemProps["value"][];
}

const SelectTrigger = ({
  label,
  open,
  disabled,
  checked,
}: {
  label: ReactNode;
  open: boolean;
  disabled?: boolean;
  checked: boolean;
}) => {
  return (
    <DropdownMenu.Trigger
      className={`grid w-full grid-cols-[1fr_1rem] items-center gap-4 rounded-sm border border-solid px-3 py-2 transition-colors focus-within:outline-none ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      } ${
        open
          ? "border-primary-default"
          : disabled
            ? "border-gray-disabled"
            : "border-gray-700"
      }`}
    >
      <div
        className={`col-start-1 col-end-1 select-none overflow-hidden text-ellipsis whitespace-nowrap text-left transition-colors ${
          checked ? "text-gray-primary" : "text-gray-disabled"
        }`}
      >
        {label}
      </div>
      <div
        className={`col-start-2 col-end-2 ${
          open
            ? "text-primary-default"
            : disabled
              ? "text-gray-disabled"
              : "text-gray-700"
        }`}
      >
        {open ? (
          <ChevronUp className="h-4 w-4" />
        ) : (
          <ChevronDown className="h-4 w-4" />
        )}
      </div>
    </DropdownMenu.Trigger>
  );
};

/**
 *
 * Why not use @radix-ui/react-select?
 * Because the aforementioned primitive component
 * does not support multi-select behavior.
 * See: https://github.com/radix-ui/primitives/issues/1342
 * Why not introduce a new library that supports multi-select?
 * Because the goal is to keep the number of 3rd party libraries low
 * For our use case a simple implementation like this is sufficient for time being.
 *
 * @params SelectProps
 * @returns <DropdownMenu.Root />
 */
export const Select = ({
  type,
  defaultSelected,
  items,
  onCheckedChange,
  trigger,
  disabled,
  align,
  sideOffset,
}: SelectProps) => {
  const [open, setOpen] = useState(false);
  const [itemsChecked, setItemsChecked] = useState<SelectItemProps["value"][]>(
    defaultSelected ?? [],
  );

  function handleMultipleCheckedChange(value: string) {
    return function innerHandleCheckedChange(checked: boolean) {
      const newItemsChecked = checked
        ? [...itemsChecked, value]
        : itemsChecked.filter((item) => item !== value);

      setItemsChecked(newItemsChecked);
      onCheckedChange?.(newItemsChecked);
    };
  }

  function handleSingleCheckedChange(value: string) {
    const newItemsChecked = itemsChecked.includes(value) ? [] : [value];

    setItemsChecked(newItemsChecked);
    onCheckedChange?.(newItemsChecked);
  }

  return (
    <DropdownMenu.Root onOpenChange={setOpen} open={disabled ? false : open}>
      <SelectTrigger
        label={
          itemsChecked?.length === 1
            ? items.find((_item) => _item.value === itemsChecked[0])?.children
            : itemsChecked?.length
              ? `+${itemsChecked.length} selected`
              : trigger
        }
        open={disabled ? false : open}
        disabled={disabled}
        checked={!!itemsChecked?.length}
      />

      <DropdownMenu.Content
        className="dropdown-transition z-50 min-w-[220px] max-w-[300px] overflow-hidden rounded bg-white py-2 shadow-regular"
        align={align}
        sideOffset={sideOffset}
      >
        <DropdownMenu.Arrow className="fill-white" />

        {type === "multi" ? (
          items.map(({ value, children, disabled }) => (
            <DropdownMenu.CheckboxItem
              key={value}
              checked={itemsChecked.includes(value)}
              onCheckedChange={handleMultipleCheckedChange(value)}
              onSelect={(e) => e.preventDefault()}
              disabled={disabled}
              className={`grid grid-cols-[1rem_1fr] items-center gap-3 px-3 py-2 outline-none transition-colors focus-within:cursor-pointer focus-within:bg-primary-50 focus-within:outline-none ${
                disabled ? "text-gray-disabled" : "text-gray-primary"
              }`}
            >
              <div className="col-start-1 col-end-1">
                <Checkbox
                  checked={itemsChecked.includes(value)}
                  disabled={disabled}
                />
              </div>
              <div className="col-start-2 col-end-2">{children}</div>
            </DropdownMenu.CheckboxItem>
          ))
        ) : (
          <DropdownMenu.RadioGroup
            value={itemsChecked[0]}
            onValueChange={handleSingleCheckedChange}
          >
            {items.map(({ value, children, disabled }) => (
              <DropdownMenu.RadioItem
                key={value}
                value={value}
                disabled={disabled}
                className={`grid grid-cols-1 items-center gap-3 px-3 py-2 outline-none transition-colors focus-within:cursor-pointer focus-within:bg-primary-50 focus-within:outline-none ${
                  disabled ? "text-gray-disabled" : "text-gray-primary"
                } ${value === itemsChecked[0] ? "bg-primary-100" : ""}`}
              >
                {children}
              </DropdownMenu.RadioItem>
            ))}
          </DropdownMenu.RadioGroup>
        )}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
