import { z } from "zod";

export const vipContactsActionSerializedSchema = z.array(z.string());

export const vipContactsUpdateSerializedSchema =  z.array(
    z.object({
    id: z.string(),
    email_address: z.string(),
    created_at: z.string().datetime({ offset: true }).nullable().optional(),
    updated_at: z.string().datetime({ offset: true }).nullable().optional(),
  })
  );