import { QueryClient } from "@tanstack/react-query";
import { Suspense } from "react";
import {
  Await,
  LoaderFunction,
  Navigate,
  Outlet,
  defer,
  redirect,
  useLoaderData,
} from "react-router-dom";

// import { NotificationRequest } from "../components/NotificationRequest";
import { PageLoader } from "../components/PageLoader";
import { UndoArchiveToast } from "../components/info/UndoArchiveToast";
import { authQuery } from "../services/auth/queries";
import { useInfoListSubscriptionWithNotification } from "../services/info/subscription";

type LoaderData = Awaited<ReturnType<typeof getLoaderData>>;

const getLoaderData = (queryClient: QueryClient) => {
  return {
    auth: queryClient.ensureQueryData({
      ...authQuery(),
      staleTime: 1000 * 60 * 60, // 1 hour
    }),
  };
};

// eslint-disable-next-line react-refresh/only-export-components
export const loader = (queryClient: QueryClient) =>
  (() => {
    if (location.href.includes("fromAuth=1")) {
      return redirect("/pre-import" + location.hash);
    }
    return defer(getLoaderData(queryClient));
  }) satisfies LoaderFunction;

export const Root = () => {
  useInfoListSubscriptionWithNotification();

  const data = useLoaderData() as LoaderData;

  return (
    <Suspense
      fallback={
        <div className="min-w-screen flex min-h-screen flex-col items-center bg-main">
          <PageLoader>
            <h1 className="text-xl ">Authenticating...</h1>
          </PageLoader>
        </div>
      }
    >
      <Await resolve={data.auth}>
        {() => (
          <>
            {location.pathname == "/" && <Navigate to={`/home`} replace />}
            <Outlet />
            <UndoArchiveToast />
            {/* <NotificationRequest /> */}
          </>
        )}
      </Await>
    </Suspense>
  );
};
