import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";

import { SessionProvider } from "./context/session/provider";
import { ToastProvider } from "./context/toast/provider";
import { Default as DefaultError } from "./routes/error/Default";
import { postOAuthMessage } from "./services/auth/oauth";
import { withErrorBoundary } from "./services/monitoring";
import { router } from "./services/router";
import { queryClient } from "./services/store";
import { Session } from "@supabase/supabase-js";
import { getSession } from "./services/auth/session";

postOAuthMessage();

const App = () => {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    const fetchSession = async () => {
      const session = await getSession();
      setSession(session);
    };

    void fetchSession();
  }, []);

  const registerServiceWorker = async (session: Session) => {
    if ("serviceWorker" in navigator) {
      try {
        console.log("Registering Service Worker....");
        const registration = await navigator.serviceWorker.register("/sw.js");
        console.log("Service Worker registered with scope:", registration.scope);

        registration.addEventListener("updatefound", () => {
          const installingWorker = registration.installing;
          if (installingWorker) {
            console.log("A new service worker is being installed:", installingWorker);
          }
        });

        // Send serializable session data to the service worker
        if (session.user) {
          const { access_token, user } = session;
          const sendSessionData = async () => {
            const swRegistration = await navigator.serviceWorker.ready;
            if (swRegistration.active) {
              swRegistration.active.postMessage({ access_token, user });
            }
          };
          void sendSessionData();
        }
      } catch (error) {
        console.error("Service worker registration failed:", error);
      }
    } else {
      console.error("Service workers are not supported.");
    }
  };

  useEffect(() => {
    if (session) {
      void registerServiceWorker(session);
    }
  }, [session]);

  return (
    <ToastProvider>
      <QueryClientProvider client={queryClient}>
        <SessionProvider>
          <RouterProvider router={router} />
          <ReactQueryDevtools
            initialIsOpen={false}
            buttonPosition="bottom-left"
          />
        </SessionProvider>
      </QueryClientProvider>
    </ToastProvider>
  );
};

const AppWithErrorBoundary = withErrorBoundary(App, {
  fallback: <DefaultError />,
});

export default AppWithErrorBoundary;
