import { Link } from "react-router-dom";

import { Button } from "../../components/button";

export const AuthError = () => {
  const query = new URLSearchParams(location.search);
  let errorMessage =
    "Looks like you are trying to login using an email that has not been used to sign up. Please, sign-up or login with a different account.";
  if (query.get("error")) {
    switch (query.get("error")) {
      case "relogin":
        errorMessage = "Your session has expired. Please re-login.";
        break;
      case "userNotFound":
        errorMessage = "Failed to verify account setup.";
        break;
      default:
    }
  }
  return (
    <>
      <div className="my-auto flex w-full flex-col items-center gap-6 xl:w-1/2 xl:items-center xl:justify-center xl:px-32">
        <span className="text-center text-2xl font-bold text-white">
          Oh no, something went wrong
        </span>
        <p className="text-center text-white">{errorMessage}</p>
        <div className="flex w-full flex-row gap-2">
          <Button as="button" variant="primary" className="rounded-full">
            <Link to={"/signup"}>Signup</Link>
          </Button>
          <Button
            as="button"
            variant="outline"
            className="rounded-full text-primary-default"
          >
            <Link to={"/login"}>Login</Link>
          </Button>
        </div>
      </div>
    </>
  );
};
