import classNames from "classnames";
import { ReactElement, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import loaderSrc from "../../assets/images/loader.gif";
import socialMediasSrc from "../../assets/images/social-medias.png";
import tickSrc from "../../assets/images/tick.svg";
import { getSession, supabaseClient } from "../../services/auth/session";
import { GmailIcon, LockIcon, SlackIcon } from "../icons";

interface IntegrationBoxProps {
  icon: ReactElement;
  active?: boolean;
}

interface IntegrationsProps {
  slackAuthUrl: string;
  isImportFlow: boolean;
}

interface SlackWorkspace {
  id: string;
  created_at: string;
  updated_at: string | null;
  workspace_id: string;
  bot_access_token: string | null;
  workspace_name: string | null;
}


export const IntegrationBox = ({ icon, active }: IntegrationBoxProps) => {
  const containerClass = classNames({
    relative: true,
    flex: true,
    "h-24": true,
    "w-24": true,
    "items-center": true,
    "justify-center": true,
    "rounded-md": true,
    "bg-white": active,
    "bg-gray-disabled": !active,
  });
  return (
    <>
      <div className={containerClass}>
        {active ? (
          <img
            className="absolute right-[5px] top-[5px] h-4 w-4"
            src={tickSrc}
            alt=""
          />
        ) : null}
        {icon}
      </div>
    </>
  );
};

export const SocialMediaIntegrations = ({
  slackAuthUrl,
  isImportFlow,
}: IntegrationsProps) => {
  const [slackConfigured, setSlackConfigured] = useState({
    loading: true,
    configured: false,
  });
  const [workspaceNames, setWorkspaceNames] = useState<(string | null)[]>([]);
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");

  const load = async () => {
    try {
      const session = await getSession();
      setUserEmail(session.user.email!);
      const response = await supabaseClient
        .from("integration")
        .select()
        .eq("user_id", session.user.id)
        .limit(1)
        .maybeSingle();

      if (response.data) {
        setSlackConfigured({
          loading: false,
          configured: true,
        });
      } else {
        setSlackConfigured({
          loading: false,
          configured: false,
        });
      }
    } catch (error) {
      console.error("Error loading integration:", error);
      setSlackConfigured({
        loading: false,
        configured: false,
      });
    }
  };

  const getWorkspaces = async () => {
    try {
      const session = await getSession();
      const response = await supabaseClient
        .from("workspace")
        .select()
        .eq("user_id", session.user.id);
        
      if (response.data) {
        const slack_workspaces = await supabaseClient
          .from("slack_workspace")
          .select()
          .in("workspace_id", (response.data as {workspace_id: string}[]).map(
            (workspace) => workspace.workspace_id)
        );
        setWorkspaceNames(slack_workspaces.data?.map((slack_workspace: SlackWorkspace) => slack_workspace.workspace_name) || []);
        
        
      }
    } catch (error) {
      console.error("Error loading workspaces:", error);
    }
  }

  useEffect(() => {
    void load();
    void getWorkspaces();
  }, []);
  const renderBelowText = () => {
    return (
      <>
        <img src={socialMediasSrc} alt="" />

        <div className="flex w-full items-center text-center text-sm text-gray-secondary">
          <div className="grow border-t-[0.5px] text-gray-secondary"></div>
          <span className="mx-4 shrink text-gray-secondary">
            Coming Soon to Lesen
          </span>
          <div className="grow border-t-[0.5px] text-gray-secondary"></div>
        </div>
        <div className="w-full items-center text-center text-sm text-gray-secondary">
          We take data privacy very seriously, read our{" "}
          <a
            href="https://lesen.ai/encryption-policy"
            target="__blank"
            rel="noreferrer"
            className="text-hyperlink-new decoration-1 hover:no-underline"
          >
            Encryption Policy
          </a>
          ,{" "}
          <a
            href="https://lesen.ai/data-storage"
            target="__blank"
            rel="noreferrer"
            className="text-hyperlink-new decoration-1 hover:no-underline"
          >
            Data Storage
          </a>
          {", "}
          <a
            href="https://lesen.ai/regulation-policy"
            target="__blank"
            rel="noreferrer"
            className="text-hyperlink-new decoration-1 hover:no-underline"
          >
            AI Regulations Policy
          </a>
        </div>
      </>
    );
  };

  return (
    <>
      {slackConfigured.loading ? (
        <div className="my-auto flex w-full flex-col items-center gap-6 xl:w-1/2 xl:items-center xl:justify-center xl:px-32">
          <img className="w-10" src={loaderSrc} alt="" />
        </div>
      ) : (
        <>
          <div className="my-auto flex w-full flex-col items-center gap-12 xl:w-1/2 xl:items-center xl:justify-center xl:px-32">
            {isImportFlow && (
              <span className="text-center text-2xl font-bold ">
                Next, we import what matters
              </span>
            )}
            <div className="flex w-full justify-center gap-4 max-w-xs">
              <div className="flex flex-col items-center">
                <IntegrationBox
                icon={<GmailIcon className="h-7 w-7" />}
                active={true}
                ></IntegrationBox>
                <span className="block text-center my-2 custom-text-wrap">{userEmail}</span></div>
              <div className="flex flex-col items-center">
                <IntegrationBox
                icon={<SlackIcon className="h-7 w-7" />}
                active={slackConfigured.configured}
                ></IntegrationBox>
                {slackConfigured.configured && (
                <>
                {workspaceNames?.map((workspace, index) => (
                  <span key={index} className="block text-center my-1 custom-text-wrap">{workspace}</span>
                ))}
                </>
              )}
                </div>
              <div className="flex flex-col items-center">
                <IntegrationBox
                  icon={<LockIcon className="h-7 w-7" />}
                  active={false}
                ></IntegrationBox>
              </div>
            </div>
              <>
                <a href={slackAuthUrl} rel="noreferrer">
                  <button className="flex w-full items-center justify-center whitespace-nowrap rounded-full border border-gray-400 bg-white px-3 py-2.5 text-center text-base font-bold transition-colors hover:bg-gray-100 disabled:cursor-not-allowed disabled:bg-white disabled:opacity-50">
                    <span className="inline-flex flex-row items-center gap-2 text-gray-primary">
                      <SlackIcon className="h-6 w-6" />
                      Authorize Slack Workspace
                    </span>
                  </button>
                </a>
                {isImportFlow && (
                  <div className="w-full text-center text-sm text-gray-secondary">
                    <div>or</div>
                    <div>
                      <Link
                        to={"/importing"}
                        className="w-full text-center text-sm text-white underline decoration-1"
                      >
                        Skip
                      </Link>
                      , and authorize later
                    </div>
                  </div>
                )}
              </>
             {(
              <>
                {isImportFlow && (
                  <button
                    className="text-blue-500 flex w-56 items-center justify-center whitespace-nowrap rounded-full border border-gray-400 bg-white px-3 py-2.5 text-center text-base font-bold transition-colors hover:bg-gray-100 disabled:cursor-not-allowed disabled:bg-white disabled:opacity-50"
                    onClick={() => navigate("/importing")}
                  >
                    <span className="inline-flex flex-row items-center gap-2">
                      Let’s 🦆ing go!
                    </span>
                  </button>
                )}
              </>
            )}

            <div className="hidden flex-col gap-4 xl:mt-10 xl:flex">
              {renderBelowText()}
            </div>
          </div>
          <div className="flex flex-col gap-4 pb-8 xl:hidden">
            {renderBelowText()}
          </div>
        </>
      )}
    </>
  );
};
