import React from 'react';
import { SVGProps } from 'react';

interface NoSyncIconProps extends SVGProps<SVGSVGElement> {
  theme?: 'light' | 'dark';
}

export const NoSyncIcon: React.FC<NoSyncIconProps> = ({ theme, ...props }) => {
  const strokeColor = theme === 'dark' ? '#e8eaed' : '#000000';

  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 20L12 12M12 12L20 20M12 12L4 4M12 12L20 4"
        stroke={strokeColor}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
