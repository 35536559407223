import { z } from 'zod';
import { API_CUSTOM_PROMPTS_URL, ApiError } from "../../utils/api";
import { handleApiException, parsedFetch } from "../../utils/safeFetch";
import { getSession } from '../auth/session';
import { customPromptEnhancementListSchema, itemApiSchema } from '../info/schema';
import { infoItemKeys } from '../info/queries';
import { queryClient } from '../store';


export const customPromptApiSchema = z.object({
    id: z.string().uuid(),
    user_id: z.string().uuid(),
    custom_prompt: z.string(),
    is_active: z.boolean()
});

export const customPromptsApiSchema = z.array(customPromptApiSchema);

const getCustomPrompt = async (id: string) => {
  try {
    const response = await parsedFetch(
      customPromptApiSchema,
      `${API_CUSTOM_PROMPTS_URL}/custom_prompts/${id}`,
    );
    return response;
  } catch (error) {
    return handleApiException(ApiError.FailedToFetchCustomPrompts, error);
  }
};

const updateCustomPrompt = async (id: string, custom_prompt: string) => {
    const session = await getSession();
    try {
      const response = await parsedFetch(
        customPromptApiSchema,
        `${API_CUSTOM_PROMPTS_URL}/custom_prompts`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${session.access_token}`,
            },
            method: "PATCH",
            body: JSON.stringify({id, custom_prompt}),
        }
      );
      return response;
    } catch (error) {
      return handleApiException(ApiError.FailedToFetchCustomPrompts, error);
    }
  };

const updateCustomPromptEnhancements = async (
  customPromptId: string,
  threadsList: Pick<z.infer<typeof itemApiSchema>, "id" | "is_read">[],
): Promise<z.infer<typeof customPromptEnhancementListSchema>> => {
  const session = await getSession();

  try {
    const response = await parsedFetch(
      customPromptEnhancementListSchema,
      `${API_CUSTOM_PROMPTS_URL}/custom_prompts_enhancements/${customPromptId}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${session.access_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(threadsList),
      },
    );

    return response;
  } catch (error) {
    throw handleApiException(ApiError.FailedToUpdateInfoItems, error); // Use throw for consistency
  }
};

export const CustomPromptListMutation = (customPromptId: string) => ({
  mutationFn: async (
    threadsList: Pick<z.infer<typeof itemApiSchema>, "id" | "is_read">[],
  ) => {
    const queryKey = infoItemKeys.lists();
    await queryClient.cancelQueries({ queryKey });

    const previousQueries = queryClient.getQueriesData({ queryKey });
  
    try {
      const response = await updateCustomPromptEnhancements(customPromptId, threadsList);
      const parsedResponse = customPromptEnhancementListSchema.safeParse(response);

      if (!parsedResponse.success) {
        throw new Error('Failed to parse response');
      }

      queryClient.setQueriesData({ queryKey }, (oldData) => {
        const oldDataTyped = oldData as { pages: { list: typeof threadsList }[] } | undefined;
        if (!oldDataTyped) return oldData;

        const updatedData = {
          ...oldDataTyped,
          pages: oldDataTyped.pages.map((page) => ({
            ...page,
            list: page.list.filter((item) =>
              !parsedResponse.data.some((enhancement) => 
                item.id === enhancement.thread_pk_id && enhancement.is_read
              ),
            ),
          })),
        };

        return updatedData;
      });

      return response;
    } catch (error) {
      if (previousQueries) {
        queryClient.setQueriesData({ queryKey }, previousQueries);
      }
      throw error;
    }
  },
});

interface CustomPrompt {
  id: string;
  user_id: string;
  custom_prompt: string;
  is_active: boolean;
}

const getAllCustomPrompts = async (retryCount = 3, retryDelay = 1000) => {
  const session = await getSession();
  
  const attemptFetch = async (attemptsLeft: number): Promise<CustomPrompt[]> => {
    try {
      return await parsedFetch(
        customPromptsApiSchema,
        `${API_CUSTOM_PROMPTS_URL}/custom_prompts`,
        {
          headers: {
            Authorization: `Bearer ${session.access_token}`,
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      );
    } catch (error) {
      if (attemptsLeft === 1) {
        // Last attempt also failed, return a default value or handle as needed
        return Promise.resolve([]);
      }
      // Wait for retryDelay milliseconds before retrying
      await new Promise(resolve => setTimeout(resolve, retryDelay));
      // Retry with one less attempt left
      return attemptFetch(attemptsLeft - 1);
    }
  };

  return attemptFetch(retryCount);
};

export const getCustomPromptsQuery = (id: string) => ({
  queryKey: ["get-custom-prompt-" + id],
  queryFn: async () => {
    return await getCustomPrompt(id);
  },
  staleTime: 5000
});

export const getAllCustomPromptsQuery = () => ({
    queryKey: ["get-all-custom-prompts"],
    queryFn: async () => {
        return await getAllCustomPrompts();
    },
    staleTime: 5000
  });


const createCustomPrompt = async (custom_prompt: string) => {
    const session = await getSession();
  
    try {
      const response = await parsedFetch(
        customPromptApiSchema,
        `${API_CUSTOM_PROMPTS_URL}/custom_prompts`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${session.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({custom_prompt})
        },
      );
  
      return response;
    } catch (error) {
      return handleApiException(ApiError.FailedToCreateCustomPrompts, error);
    }
  };

export const createCustomPromptMutation = () => ({
  mutationFn: async (custom_prompt: string) => {
    try {
      const response = await createCustomPrompt(custom_prompt);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
});

export const updateCustomPromptMutation = () => ({
    mutationFn: async (id: string, custom_prompt: string) => {
      try {
        const response = await updateCustomPrompt(id, custom_prompt);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  });