import logoInvertedIcon from "../assets/images/logo-sm-inverted.svg";
import logoIcon from "../assets/images/logo-sm.svg";
import { IS_NOTIFICATION_SERVICE_AVAILABLE } from "../utils/consts";
import { getPreferredColorSchema } from "../utils/preferredColorSchema";
import { captureException } from "./monitoring";


export const sendNotification = (
  title: string,
  body?: string,
  data?: unknown,
) => {
  if (
    !IS_NOTIFICATION_SERVICE_AVAILABLE ||
    Notification.permission !== "granted"
  )
    return;
    console.log("Sending Notification:", title, body, data);
    navigator.serviceWorker.ready.then((registration) => {
      registration.showNotification(title, {
        body,
        icon: getPreferredColorSchema() === "dark" ? logoInvertedIcon : logoIcon,
        data,
      }).catch((error) => {
        console.error("Error Showing Notification:", error);
        captureException(error);
      });
    }).catch((error) => {
      console.error("Error Getting Service Worker:", error);
      captureException(error);
    });
};
