import { Navigate, useRouteError } from "react-router-dom";
import { z } from "zod";

import { ApiError } from "../../utils/api";
import { ResponseError } from "../../utils/safeFetch";
import { Default } from "./Default";
import { NotFound } from "./NotFound";
import { Server } from "./Server";

export const ErrorRoot = () => {
  const error = useRouteError() as Error;

  if (error instanceof ResponseError) {
    switch (error.message) {
      case ApiError.UserSessionNotFound:
      case ApiError.FailedToAuthenticate:
      case ApiError.FailedToRefreshSession:
        return <Navigate to={`/login`} replace />;
      case ApiError.UserNotFound:
      case ApiError.FailedToVerifyAccountSetup:
        return <Navigate to={`/authError?error=userNotFound`} replace />;
      case ApiError.FailedToAuthorizeGoogleApis:
        return <Navigate to={`/authError`} replace />;
    }

    if (error.status === 404) {
      return <NotFound />;
    }
  }

  if (
    error instanceof z.ZodError ||
    ("status" in error &&
      typeof error.status === "number" &&
      error.status >= 500)
  ) {
    return <Server />;
  }

  return <Default error={error} />;
};
