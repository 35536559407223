import { z } from "zod";

import {
  ApiError,
  transformGmailLinksToHTML,
  transformSlackLinksToHTML,
} from "../../utils/api";
import { replaceEmojiSlugs } from "../../utils/emoji";
import { handleApiException } from "../../utils/safeFetch";


export enum Origin {
  Gmail = "gmail",
  Slack = "slack",
}

const participantsSchema = z.array(
  z.object({
    user: z.string(),
    last_activity: z.string().datetime({ offset: true }),
  }),
);

export const serializeThreadPayload = (
  item: z.infer<typeof threadApiSchema>,
) => {
  return {
    id: item.id,
    threadId: item.thread_id,
    summary: item.origin === Origin.Slack
    ? replaceEmojiSlugs(transformSlackLinksToHTML(item.summary ?? ""))
    : transformGmailLinksToHTML(item.summary ?? ""),
    shortSummary:item.origin === Origin.Slack
    ? replaceEmojiSlugs(transformSlackLinksToHTML(item.short_summary ?? ""))
    : transformGmailLinksToHTML(item.short_summary ?? ""),
    deadlineAt: item.deadline_at,
    sentAt: item.sent_at,
    createdAt: item.created_at,
    updatedAt: item.updated_at,
    isUrgent: item.is_urgent,
    isRead: item.is_read,
    topics: item.topics,
    participants: item.participants,
    matrixAction: item.matrix_action,
    origin: item.origin,
    subject: item.subject,
    permalink: item.permalink,
  };
};
export const serializeThreadListPayload = async (
  data: z.infer<typeof threadListApiSchema>,
) => {
  const { items, next_cursor } = data;

  const result = threadListSerializedSchema.safeParse({
    items: items.map((item) => {
      return serializeThreadPayload(item);
    }),
    nextCursor: next_cursor,
  });

  if (!result.success) {
    return handleApiException(
      ApiError.FailedToSerializeInfoItems,
      result.error,
    );
  }

  return Promise.resolve(result.data);
};

export const threadApiSchema = z.object({
  id: z.string(),
  thread_id: z.string(),
  created_at: z.string().datetime({ offset: true }).nullable().optional(),
  updated_at: z.string().datetime({ offset: true }).nullable().optional(),
  sent_at: z.string().datetime({ offset: true }).nullable().optional(),
  deadline_at: z.string().datetime({ offset: true }).nullable().optional(),
  is_urgent: z.boolean().nullable().optional(),
  is_read: z.boolean().nullable().optional(),
  is_deleted: z.boolean().nullable().optional(),
  topics: z.array(z.string()).optional().nullable(),
  origin: z.enum([Origin.Gmail, Origin.Slack]).nullable().optional(),
  summary: z.string().nullable().optional(),
  short_summary: z.string().nullable().optional(),
  final_score: z.number().nullable(),
  matrix_action: z.string(),
  enhancement_status: z.string().nullable().optional(),
  subject: z.string().optional().nullable(),
  participants: participantsSchema,
  permalink: z.string().nullable().optional(),
});

export const threadListApiSchema = z.object({
  items: z.array(threadApiSchema),
  next_cursor: z.string().nullable().optional(),
});

export const threadSerializedSchema = z.object({
  id: z.string(),
  threadId: z.string(),
  createdAt: z.string().datetime({ offset: true }),
  updatedAt: z.string().datetime({ offset: true }),
  deadlineAt: z.string().datetime({ offset: true }).optional().nullable(),
  sentAt: z.string().datetime({ offset: true }),
  isUrgent: z.boolean().optional().nullable(),
  isRead: z.boolean().optional().nullable(),
  topics: z.array(z.string()).optional().nullable(),
  origin: z.enum([Origin.Gmail, Origin.Slack]),
  summary: z.union([z.string(), z.null()]).optional(),
  shortSummary: z.union([z.string(), z.null()]).optional(),
  matrixAction: z.string().optional().nullable(),
  subject: z.string().optional().nullable(),
  permalink: z.string().nullable().optional(),
  participants: participantsSchema,
});

export const threadListSerializedSchema = z.object({
  items: z.array(threadSerializedSchema),
  nextCursor: z.string().nullable().optional(),
});
