import { SVGProps } from "react";

export const SpinnerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M36.728 11.2722L33.9 14.1002C31.6098 11.8098 28.5963 10.3843 25.373 10.0667C22.1496 9.74902 18.9159 10.5588 16.2227 12.3582C13.5295 14.1575 11.5435 16.835 10.6031 19.9344C9.66268 23.0338 9.82605 26.3635 11.0654 29.3559C12.3047 32.3484 14.5432 34.8186 17.3997 36.3456C20.2561 37.8727 23.5536 38.362 26.7304 37.7304C29.9071 37.0987 32.7666 35.385 34.8215 32.8814C36.8765 30.3778 37.9998 27.2391 38 24.0002H42C42 28.1646 40.556 32.2001 37.9141 35.4192C35.2722 38.6383 31.5959 40.8418 27.5115 41.6542C23.4271 42.4666 19.1874 41.8377 15.5147 39.8745C11.8421 37.9114 8.96374 34.7356 7.37012 30.8882C5.77651 27.0408 5.56623 22.7598 6.77512 18.7748C7.984 14.7897 10.5373 11.3471 13.9998 9.03351C17.4624 6.71992 21.6201 5.67851 25.7644 6.08671C29.9088 6.49492 33.7834 8.3275 36.728 11.2722Z"
      fill="currentColor"
    />
  </svg>
);
