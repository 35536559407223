import { QueryClient } from "@tanstack/react-query";
import {
  ActionFunction,
  Form,
  Link,
  LoaderFunction,
  useActionData,
  useLoaderData,
} from "react-router-dom";

import { Button } from "../../../components/button";
import { ArrowLeftIcon } from "../../../components/icons";
import { Paper } from "../../../components/paper";
import { Switch } from "../../../components/switch";
import { Toast } from "../../../components/toast";
import { gmailSettingsMutation } from "../../../services/integrations/gmail/mutation";
import { gmailQuery } from "../../../services/integrations/gmail/query";
import { gmailSettingsActionSerializedSchema } from "../../../services/integrations/gmail/schema";
import { integrationsQuery } from "../../../services/integrations/query";

interface FormActionResponse {
  message: { key: string; type: "error" | "success"; content: string };
}

// eslint-disable-next-line react-refresh/only-export-components
export const action = (queryClient: QueryClient) =>
  (async ({ request }): Promise<FormActionResponse> => {
    const formData = Object.fromEntries(await request.formData());
    const updates = {
      ...formData,
      auto_labelling: formData?.auto_labelling === "on" ? true : false,
    };

    try {
      const settings = gmailSettingsActionSerializedSchema.parse(updates);

      const { mutationFn } = gmailSettingsMutation();

      await mutationFn(settings);

      const { queryKey } = integrationsQuery();
      await queryClient.invalidateQueries({
        queryKey,
        refetchType: "all",
      });

      return {
        message: {
          key: new Date().toISOString(),
          type: "success",
          content: "Settings updated succesfully!",
        },
      };
    } catch (error) {
      return {
        message: {
          key: new Date().toISOString(),
          type: "error",
          content: "Oops! Something went wrong! Try again later.",
        },
      };
    }
  }) satisfies ActionFunction;

// eslint-disable-next-line react-refresh/only-export-components
export const loader = (queryClient: QueryClient) =>
  (async () => {
    const data = await queryClient.ensureQueryData({
      ...gmailQuery(),
    });

    return data;
  }) satisfies LoaderFunction;

export const Gmail = () => {
  const actionData = useActionData() as Awaited<
    ReturnType<ReturnType<typeof action>>
  >;

  const data = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >;

  return (
    <>
      <main className="mx-4 grid h-full w-full max-w-screen-2xl grow grid-cols-1 grid-rows-[min-content_min-content] gap-12 px-6 py-4 lg:py-12">
        <h1 className="flex items-center gap-3 text-2xl text-gray-primary">
          <Link to="/settings">
            <ArrowLeftIcon className="h-6 w-6" />
          </Link>
          Gmail integration
        </h1>
        <Form method="POST" className="flex flex-col gap-6">
          <section className="flex flex-col gap-2">
            <h2 className="text-xl font-semibold text-gray-primary">
              Let Lesen.AI adapt to your personal habits
            </h2>
            <p className="text-base  text-gray-primary">
              Lesen.AI seamlessly adapts to your personal habits and routines.
              Based on your preferences, Lesen.AI changes its behavior regarding
              the creation of to-dos and the synchronizing between your Gmail
              Inbox and Lesen.AI. Regardless, Lesen.AI will never permanently
              delete any of your emails.
            </p>
          </section>
          <fieldset className="flex flex-col gap-4">
            <Paper className="px-6 py-4">
              <div className="radio-btn-container">
                <input
                  type="radio"
                  id="inbox_0_classic"
                  name="parrot_to_gmail_sync"
                  defaultChecked={
                    data?.parrot_to_gmail_sync === "inbox_0_classic"
                  }
                  value="inbox_0_classic"
                />
                <span className="checkmark"></span>
                <div className="flex flex-col gap-3">
                  <label
                    className="cursor-pointer text-base font-medium text-gray-primary"
                    htmlFor="inbox_0_classic"
                  >
                    Inbox 0 “Classic” - All emails in my inbox are potential
                    to-dos, I am archiving my emails when they are done
                  </label>
                  <p className="whitespace-normal text-sm text-gray-secondary">
                    To-do creation: All emails in your inbox are analyzed by
                    Lesen.AI, regardless of their read / unread state.
                    <br />
                    Lesen.AI to Gmail: Completing a to-do in Lesen.AI will
                    automatically archive the corresponding email in Gmail.
                    <br />
                    Gmail to Lesen.AI: Archiving an email in Gmail will
                    automatically mark the corresponding to-do in Lesen.AI as
                    completed.
                  </p>
                </div>
              </div>
            </Paper>
            <Paper className="px-6 py-4">
              <div className="radio-btn-container">
                <input
                  type="radio"
                  id="inbox_0_alt"
                  name="parrot_to_gmail_sync"
                  defaultChecked={data?.parrot_to_gmail_sync === "inbox_0_alt"}
                  value="inbox_0_alt"
                />
                <span className="checkmark"></span>
                <div className="flex flex-col gap-3">
                  <label
                    className="cursor-pointer text-base font-medium text-gray-primary"
                    htmlFor="inbox_0_alt"
                  >
                    Inbox 0 “Alt“ - All unread emails in my Inbox are potential
                    open to-dos, I am leaving emails on read, when they are done
                  </label>
                  <p className="whitespace-normal text-sm text-gray-secondary">
                    To-do creation: Only unread emails, are analyzed by
                    Lesen.AI.
                    <br />
                    Lesen.AI to Gmail: Completing a to-do in Lesen.AI will
                    automatically mark the corresponding email in Gmail as read.
                    <br />
                    Gmail to Lesen.AI: Leaving an email in Gmail on read will
                    automatically mark the corresponding to-do in Lesen.AI as
                    completed.
                  </p>
                </div>
              </div>
            </Paper>
            <Paper className="px-6 py-4">
              <div className="radio-btn-container">
                <input
                  type="radio"
                  id="no_sync"
                  name="parrot_to_gmail_sync"
                  defaultChecked={data?.parrot_to_gmail_sync === "no_sync"}
                  value="no_sync"
                />
                <span className="checkmark"></span>
                <div className="flex flex-col gap-3">
                  <label
                    className="cursor-pointer text-base font-medium text-gray-primary"
                    htmlFor="no_sync"
                  >
                    No Sync - No action in your inbox
                  </label>
                  <p className="whitespace-normal text-sm text-gray-secondary">
                    Completing a to-do in Lesen.AI or marking them as not
                    relevant will result in NO action in your Gmail inbox.
                    <br />
                    <span className="text-error-regular">
                      *This setting will require you to clean up your Gmail
                      manually and IS NOT RECOMMENDED.
                    </span>
                  </p>
                </div>
              </div>
            </Paper>
          </fieldset>
          <section className="flex flex-col gap-6">
            <h2 className="text-xl font-semibold text-gray-primary">
              Additional Settings
            </h2>
            <div>
              <Switch
                label="Auto-labeling in Gmail"
                id="auto_labelling"
                name="auto_labelling"
                labelSide="right"
                defaultChecked={data?.auto_labelling ?? false}
              />
              <p className="whitespace-normal pt-4 text-sm text-gray-secondary">
                All emails that are included in Lesen.AI and are automatically
                labeled in Gmail. Easily allowing you to review all the rest at
                the end of the day.
              </p>
            </div>
          </section>
          <section className="max-w-xs">
            <Button variant="primary" as="button" type="submit">
              Save Changes
            </Button>
          </section>
        </Form>
      </main>

      {actionData?.message ? (
        <Toast
          key={actionData?.message.key}
          type={actionData?.message.type}
          variant="primary"
          message={actionData?.message.content}
        />
      ) : null}
    </>
  );
};
