import { API_VIP_CONTACTS_URL, ApiError } from "../../utils/api";
import { handleApiException, parsedFetch } from "../../utils/safeFetch";
import { getSession } from "../auth/session";
import { vipContactsActionSerializedSchema } from "./schema";

export const vipCommsKeys = {
    all: ["vip-contacts"] as const,
    vip: () => [...vipCommsKeys.all, "vip"] as const,
  };

const getVipContacts = async () => {
  const session = await getSession();

  try {
    const response = await parsedFetch(
        vipContactsActionSerializedSchema,
      `${API_VIP_CONTACTS_URL}/users/${session.user.id}/vip_contacts`,
      {
        method: "GET",
      },
    );
    return response;
  } catch (error) {
    return handleApiException(ApiError.FailedToFetchGmailSettings, error);
  }
};

export const vipContactsQuery = () => ({
  queryKey: vipCommsKeys.vip(),
  queryFn: async () => {
    const contacts = await getVipContacts();
    return contacts;
  },
});
