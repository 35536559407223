import { z } from "zod";

import { API_BACKEND_URL, ApiError } from "../../utils/api";
import { add, formatISO } from "../../utils/date";
import { handleApiException, parsedFetch } from "../../utils/safeFetch";
import { getSession } from "../auth/session";
import { Deadline, Origin, infoItemListFilterApiSchema } from "./schema";

export const DEADLINE_MAP = {
  [Deadline.OverdueAndToday]: "Overdue + Today",
  [Deadline.Tomorrow]: "Tomorrow",
  [Deadline.In2Days]: "In 2 Days",
  [Deadline.In7Days]: "In 7 Days",
  [Deadline.In30Days]: "In 30 Days",
};

export const DEADLINE_OPTIONS_TUPLE = [
  Deadline.OverdueAndToday,
  Deadline.Tomorrow,
  Deadline.In2Days,
];

export const DEADLINE_OPTIONS_ALL_MAP = {
  [Deadline.OverdueAndToday]: "Overdue + Today",
  [Deadline.Tomorrow]: "Tomorrow",
  [Deadline.In2Days]: "Within 2 days",
  [Deadline.In7Days]: "Within 7 days",
  [Deadline.In30Days]: "Within 30 days",
};

export const ORIGIN_OPTIONS_MAP = {
  [Origin.Gmail]: "Gmail",
  [Origin.Slack]: "Slack",
};

export const SUBJECTS_MAP = {
  major_software_issues: "Major Software Issue",
  out_of_stock: "Out of Stock",
  harassment: "Harassment",
  accidents: "Accidents",
  customer_complains: "Customer Complains",
  major_operations_issues: "Major Operational Issue",
};

export const INFO_ITEM_FILTER_DEFAULTS: z.infer<
  typeof infoItemListFilterApiSchema
> = {
  // non-configurable
  hide_read: true,
  origin: null,
  custom_prompt_id: null,

  // configurable
  urgent_comms: true,
  subjects: ["major_software_issues", "major_operations_issues"],
  hide_self_communication: true,
  deadline_at: null,
};

// Include all info items
export const INFO_ITEM_FILTER_VIEW_ALL: Partial<
  z.infer<typeof infoItemListFilterApiSchema>
> = {
  hide_read: true,
  custom_prompt_id: null,
  urgent_comms: false,
  subjects: [],
  hide_self_communication: false,
  deadline_at: null,
  origin: null,
  vip_contacts: null,
};

export const INFO_ITEM_FILTER_VIP_CONTACTS: Partial<
  z.infer<typeof infoItemListFilterApiSchema>
> = {
  hide_read: true,
  custom_prompt_id: null,
  urgent_comms: false,
  subjects: [],
  hide_self_communication: false,
  deadline_at: null,
  origin: null,
  vip_contacts: true
};

const fetchInfoItemFilter = async () => {
  try {
    const session = await getSession();

    const response = await parsedFetch(
      infoItemListFilterApiSchema,
      `${API_BACKEND_URL}/users/${session.user.id}/settings/filters`,
      {
        method: "GET",
      },
    );

    return response;
  } catch (error) {
    // NOTE: in case user does not have filters created we create them
    // expecting same response as GET as per API spec
    if (error instanceof Response && error.status === 404) {
      return await createInfoItemFilter();
    }

    return handleApiException(ApiError.FailedToFetchFilterSettings, error);
  }
};

const createInfoItemFilter = async () => {
  try {
    const session = await getSession();

    const response = await parsedFetch(
      infoItemListFilterApiSchema,
      `${API_BACKEND_URL}/users/${session.user.id}/settings/filters`,
      {
        method: "POST",
        body: JSON.stringify(INFO_ITEM_FILTER_DEFAULTS),
      },
    );

    return response;
  } catch (error) {
    return handleApiException(ApiError.FailedToCreateFilterSettings, error);
  }
};

export const getInfoItemFilter = async () => {
  try {
    const data = await fetchInfoItemFilter();

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const serializeInfoItemFilter = (
  filters: z.infer<typeof infoItemListFilterApiSchema>,
  nextCursor?: string,
) => {
  const params = new URLSearchParams([["limit", "50"]]);

  // Always order_by updated_at
  params.append("order_by", "sent_at");

  if (nextCursor) {
    params.append("cursor", nextCursor);
  }

  if (filters?.hide_read) {
    params.append("is_read", "false");
  }

  if (filters?.custom_prompt_id) {
    params.append("custom_prompt_id", filters.custom_prompt_id);
  }

  if (filters?.hide_self_communication) {
    params.append("is_sent", "false");
  }
  
  if (filters?.urgent_comms) {
    params.append("is_urgent", "true");
  }

  if (filters?.vip_contacts) {
    params.append("vip_contacts", "true");
  }

  if (filters?.deadline_at) {
    const deadline_at = new Date();
    deadline_at.setHours(0, 0, 0, 0);

    switch (filters.deadline_at) {
      case Deadline.OverdueAndToday:
        params.append("deadline_at", formatISO(add(deadline_at, { days: 1 })));
        break;
      case Deadline.Tomorrow:
        params.append("deadline_at", formatISO(add(deadline_at, { days: 2 })));
        break;
      case Deadline.In2Days:
        params.append("deadline_at", formatISO(add(deadline_at, { days: 3 })));
        break;
      case Deadline.In7Days:
        params.append("deadline_at", formatISO(add(deadline_at, { days: 8 })));
        break;
      case Deadline.In30Days:
        params.append("deadline_at", formatISO(add(deadline_at, { days: 31 })));
        break;
    }
  }

  if (filters?.subjects?.length) {
    filters.subjects.forEach((subject) => {
      params.append("subjects", SUBJECTS_MAP[subject]);
    });
  }

  if (filters?.origin) {
    params.append("origin", filters.origin);
  }

  return params.toString();
};
