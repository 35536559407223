import { API_VIP_CONTACTS_URL, ApiError } from "../../utils/api";
import { handleApiException, parsedFetch } from "../../utils/safeFetch";
import { getSession } from '../auth/session';
import { vipContactsUpdateSerializedSchema } from "./schema";


const updateVipContacts = async (vip_contacts: string[]) => {
  const maxRetries = 3;
  const baseDelay = 1000;
  let retries = 0;

  const session = await getSession();

  while (retries <= maxRetries) {
      try {
          const response = await parsedFetch(
              vipContactsUpdateSerializedSchema,
              `${API_VIP_CONTACTS_URL}/users/vip_contacts`,
              {
                  headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${session.access_token}`,
                  },
                  method: "PATCH",
                  body: JSON.stringify({vip_contacts}),
              }
          );
          return response;
      } catch (error) {
          if (retries === maxRetries) {
              console.log(`Max retries reached for updating VIP contacts: ${error}`);
              return handleApiException(ApiError.FailedToUpdateVipComms, error);
          }
          await new Promise(resolve => setTimeout(resolve, baseDelay * (2 ** retries)));
          retries++;
          console.error(`Retry attempt ${retries} failed for updateVipContacts. Retrying...`);
      }
  }
};

export const updateVipContactsMutation = () => ({
  mutationFn: async (vip_contacts: string[]) => {
      try {
          const response = await updateVipContacts(vip_contacts);
          return Promise.resolve(response);
      } catch (error) {
          return Promise.reject(error);
      }
  },
});