import { API_BASE_URL, ApiError } from "../../utils/api";
import { handleApiException, parsedFetch } from "../../utils/safeFetch";
import { integrationsApiSchema } from "./schema";

const getIntegrations = async () => {
  try {
    const response = await parsedFetch(
      integrationsApiSchema,
      `${API_BASE_URL}/integrations`,
      {
        method: "GET",
      },
    );

    const result = [
      {
        name: "google_events",
        is_enabled: true,
      },
      ...response,
    ];

    return result;
  } catch (error) {
    return handleApiException(ApiError.FailedToFetchIntegrations, error);
  }
};

export const integrationsKeys = {
  all: ["integrations"] as const,
  gmail: () => [...integrationsKeys.all, "gmail"] as const,
};

export const integrationsQuery = () => ({
  queryKey: integrationsKeys.all,
  queryFn: async () => {
    const integrations = await getIntegrations();

    return integrations;
  },
});
