import {
  CONNECTION_STATE,
  useInfoListSubscriptionConnectionStatus,
} from "../../services/info/subscription";

const useHotCommsConnectionStatus = (): {
  status: "error" | "fetching" | "connected" | "connecting" | "disconnected";
} => {
  // const { data: filters } = useQuery({
  //   ...infoItemHotCommsFilterQuery(),
  // });
  // const { status, fetchStatus } = useInfiniteQuery({
  //   ...infoItemListInfiniteQuery(filters!),
  // });

  const connectionState = useInfoListSubscriptionConnectionStatus();

  return {
    status:
      connectionState === CONNECTION_STATE.Connecting
        ? "connecting"
        : connectionState === CONNECTION_STATE.Open
          ? "connected"
          : "disconnected",
  };
};

const RefreshIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
  >
    <path
      d="M7.05602 2.66675C6.79641 2.07223 6.36902 1.56636 5.8262 1.21112C5.28339 0.855875 4.64874 0.666691 4.00002 0.666748C2.27168 0.666748 0.850016 1.98241 0.68335 3.66675"
      stroke="white"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.66666 2.66667H7.13333C7.15959 2.66667 7.1856 2.66149 7.20986 2.65144C7.23413 2.64139 7.25618 2.62666 7.27475 2.60809C7.29332 2.58952 7.30805 2.56747 7.3181 2.5432C7.32815 2.51894 7.33333 2.49293 7.33333 2.46667V1M0.960327 5.33333C1.47499 6.51067 2.64966 7.33333 4.01633 7.33333C5.74499 7.33333 7.16633 6.01767 7.33333 4.33333"
      stroke="white"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.35002 5.3335H0.88335C0.830306 5.3335 0.779436 5.35457 0.741928 5.39207C0.704421 5.42958 0.68335 5.48045 0.68335 5.5335V7.00016"
      stroke="white"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Avatar = ({
  src,
  initials,
}: {
  src?: string | null;
  initials?: string | null;
}) => {
  const { status } = useHotCommsConnectionStatus();

  const avatar = src ? (
    <img className="h-8 w-8 rounded-full" src={src} alt="Profile Avatar" />
  ) : (
    <div className="flex h-8 w-8 items-center justify-center rounded-full bg-primary-default text-base font-medium text-white">
      {initials ?? "P"}
    </div>
  );

  return (
    <div className="relative">
      {avatar}
      <span
        className={`absolute -right-0.5 bottom-0 flex h-3 w-3 items-center justify-center rounded-full ring-1 ring-white ring-offset-0 transition-colors ${
          status === "error"
            ? "bg-error-regular"
            : status === "connecting" || status === "disconnected"
              ? "animate-spin bg-alert-regular"
              : status === "fetching"
                ? "animate-spin bg-warning-regular"
                : "bg-success-regular"
        }`.trim()}
        title="Cockpit Connection Status"
      >
        {status === "fetching" ||
        status === "connecting" ||
        status === "disconnected" ? (
          <RefreshIcon />
        ) : null}
      </span>
    </div>
  );
};

export default Avatar;
