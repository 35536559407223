import { Range, Root, SliderProps, Thumb, Track } from "@radix-ui/react-slider";

export const Slider = (props: SliderProps) => (
  <Root
    className={`relative flex h-[18px] w-full touch-none select-none items-center hover:cursor-pointer ${
      props.disabled ? "opacity-50" : ""
    }`.trim()}
    {...props}
  >
    <Track className="h-2 grow rounded-full bg-gray-100">
      <Range
        className={`absolute h-2 rounded-full bg-primary-500 transition-opacity ${
          props.disabled ? "opacity-0" : "opacity-100"
        }`.trim()}
      />
    </Track>
    <Thumb
      className={`block h-[18px] w-[18px] rounded-full  shadow-sm transition-all  focus:outline-none ${
        props.disabled
          ? "cursor-not-allowed opacity-0"
          : "bg-primary-default hover:bg-primary-dark hover:shadow-md"
      }`.trim()}
    />
  </Root>
);
