import React from 'react';
import { QueryClient } from '@tanstack/react-query';
import {
  ActionFunction,
  Form,
  Link,
  LoaderFunction,
  useActionData,
  useLoaderData,
  useNavigation,
  useSubmit,
} from 'react-router-dom';
import { ArchiveIcon, ArrowLeftIcon, NoSyncIcon, ReadIcon } from '../../components/icons';
import { Toast } from '../../components/toast';
import { gmailSettingsMutation } from '../../services/integrations/gmail/mutation';
import { gmailQuery } from '../../services/integrations/gmail/query';
import { gmailSettingsActionSerializedSchema } from '../../services/integrations/gmail/schema';
import { integrationsQuery } from '../../services/integrations/query';
import { IntegrationBox } from '../../components/SocialMediaIntegrations/SocialMediaIntegrations';

interface FormActionResponse {
    message: {
      key: string;
      type: 'error' | 'success';
      content: string;
    };
  }

interface LoaderData {
    parrot_to_gmail_sync: "inbox_0_classic" | "inbox_0_alt" | "no_sync" | null;
    auto_labelling: boolean | null;
  }

// eslint-disable-next-line react-refresh/only-export-components
export const action = (queryClient: QueryClient): ActionFunction<FormActionResponse> =>
  (async ({ request }): Promise<FormActionResponse> => {
    const formData = await request.formData();

    const updates = {
      parrot_to_gmail_sync: formData.get('parrot_to_gmail_sync'),
      auto_labelling: formData.get('auto_labelling') === 'on',
    };

    try {
      const settings = gmailSettingsActionSerializedSchema.parse(updates);
      const { mutationFn } = gmailSettingsMutation();

      await mutationFn(settings);

      const { queryKey } = integrationsQuery();
      await queryClient.invalidateQueries({
        queryKey,
        refetchType: 'all',
      });

      return {
        message: {
          key: new Date().toISOString(),
          type: 'success',
          content: 'Settings updated successfully!',
        },
      };
    } catch (error) {
      console.error('Error during form submission:', error);
      return {
        message: {
          key: new Date().toISOString(),
          type: 'error',
          content: 'Oops! Something went wrong! Try again later.',
        },
      };
    }
  }) satisfies ActionFunction;

// eslint-disable-next-line react-refresh/only-export-components
export const loader = (queryClient: QueryClient): LoaderFunction<LoaderData> =>
  (async () => {
    const data = await queryClient.ensureQueryData({
      ...gmailQuery(),
    });

    return data;
  }) satisfies LoaderFunction;

const Spinner: React.FC = () => (
<div className="flex justify-center items-center">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
</div>
);

const SpinnerContainer: React.FC<{ isSubmitting: boolean }> = ({ isSubmitting }) => (
    <div className="h-8 flex justify-center items-center my-2">
      <div className={`transition-opacity duration-300 ${isSubmitting ? 'opacity-100' : 'opacity-0'}`}>
        <Spinner />
      </div>
    </div>
  );

export const SyncSettings: React.FC = () => {
  const actionData = useActionData() as FormActionResponse | undefined;
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  const data = useLoaderData() as LoaderData;;


  const submit = useSubmit();

  const handleIntegrationBoxClick = (value: string) => {
    if (isSubmitting) return;
    const form = document.getElementById('integration-form') as HTMLFormElement;
    if (form) {
      const formData = new FormData(form);
      formData.set('parrot_to_gmail_sync', value);
      submit(formData, { method: 'post', action: '/sync-settings' });
    }
  };
  
  return (
    <>
      <main className="mx-4 flex w-full flex-grow flex-col items-center gap-12 overflow-hidden px-6 py-2 lg:w-[1380px] lg:py-8 xl:max-w-full">
        <h1 className="flex items-center gap-3 text-3xl font-bold">
          <Link to="/home">
            <ArrowLeftIcon className="h-6 w-6" />
          </Link>
          Sync Settings
        </h1>
        <Form method="POST" action="/sync-settings" className="flex flex-col gap-6" id="integration-form">
          <section className="flex flex-col text-center items-center gap-2">
          <p className="text-base w-[80%] sm:w-[80%] md:w-[60%] lg:w-[60%]">
              To keep your Gmail inbox and Slack workspace in sync with Lesen please specify how
              the dismissal of threads in Lesen should affect the related thread in your Gmail Inbox and
              Slack Workspace (unless &quot;No Sync&quot; is chosen, conversation are always marked as read for both &quot;Archive&quot; and &quot;Read&quot;. )
            </p>
          </section>
          <SpinnerContainer isSubmitting={isSubmitting} />
          <fieldset className="flex w-full justify-center gap-4 my-2">
    
          
            <div className="flex flex-col items-center">
              <div className="cursor-pointer" onClick={() => handleIntegrationBoxClick('inbox_0_classic')}>
                <IntegrationBox
                  icon={<ArchiveIcon className="h-7 w-7" theme={data?.parrot_to_gmail_sync === 'inbox_0_classic' ? 'light' : 'dark'} />}
                  active={data?.parrot_to_gmail_sync === 'inbox_0_classic'}
                />
              </div>
              <span className="block text-center my-2">Archive</span>
            </div>
            <div className="flex flex-col items-center">
              <div className="cursor-pointer" onClick={() => handleIntegrationBoxClick('inbox_0_alt')}>
                <IntegrationBox
                  icon={<ReadIcon className="h-7 w-7" theme={data?.parrot_to_gmail_sync === 'inbox_0_alt' ? 'light' : 'dark'} />}
                  active={data?.parrot_to_gmail_sync === 'inbox_0_alt'}
                />
              </div>
              <span className="block text-center my-2">Read</span>
            </div>
            <div className="flex flex-col items-center">
              <div className="cursor-pointer" onClick={() => handleIntegrationBoxClick('no_sync')}>
                <IntegrationBox
                  icon={<NoSyncIcon className="h-7 w-7" theme={data?.parrot_to_gmail_sync === 'no_sync' ? 'light' : 'dark'} />}
                  active={data?.parrot_to_gmail_sync === 'no_sync'}
                />
              </div>
              <span className="block text-center my-2">No Sync</span>
            </div>
          </fieldset>
          <input type="hidden" name="parrot_to_gmail_sync" value={data?.parrot_to_gmail_sync || ''} />
        </Form>
      </main>
      {actionData?.message ? (
        <Toast
          key={actionData?.message.key}
          type={actionData?.message.type}
          variant="primary"
          message={actionData?.message.content}
        />
      ) : null}
    </>
  );
};