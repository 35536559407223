import { Link } from "react-router-dom";

export const Default = ({ error }: { error?: Error }) => {
  return (
    <main className="flex h-full min-h-screen w-full flex-col items-center justify-center">
      <section className="mx-4 flex h-full w-full flex-col items-center justify-center gap-4 text-3xl ">
        <h1 className="text-center font-bold ">Oops!</h1>
        {error ? (
          <p>
            <i>{error.message}</i>
          </p>
        ) : null}
        <p className="px-6 text-gray-primary">
          Sorry, an unexpected error has occurred.
          <br />
          <Link
            to="/login"
            className=" text-white underline underline-offset-4"
          >
            Back to Login.
          </Link>
        </p>
      </section>
    </main>
  );
};
