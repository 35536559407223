import { AnimatePresence, motion } from "framer-motion";
import { ReactNode } from "react";

/**
 * NOTE: The sidebar is of type inline, but breaks out of the flow of the page
 * on smaller screens as the width estate becomes cumbersome for inline elements.
 */
export const Sidebar = ({
  side,
  open = false,
  children,
}: {
  side: "left" | "right";
  open: boolean;
  children: ReactNode;
}) => {
  const motionProps = {
    layout: true,
    key: "content",
    initial: "collapsed",
    animate: "open",
    exit: "collapsed",
    transition: { curve: [0.87, 0, 0.13, 1], duration: 0.15 },
  };

  return (
    <AnimatePresence initial={false}>
      {open && (
        <motion.aside
          {...motionProps}
          variants={{
            open: { opacity: 1, width: "auto" },
            collapsed: { opacity: 1, width: 0 },
          }}
          className="fixed inset-0 z-40 h-screen w-screen md:relative md:z-0 md:h-auto md:w-full"
        >
          <motion.div
            {...motionProps}
            variants={{
              open: { translateX: 0 },
              collapsed: { translateX: side === "left" ? "-100%" : "100%" },
            }}
            className="h-full w-max"
          >
            <div className="flex h-screen w-screen flex-col md:relative md:h-full md:w-[33vw] md:min-w-[280px] md:max-w-[480px]">
              {children}
            </div>
          </motion.div>
        </motion.aside>
      )}
    </AnimatePresence>
  );
};
