import { SessionContextProvider } from "@supabase/auth-helpers-react";
import React, { createContext } from "react";

import { supabaseClient } from "../../services/auth/session";

export const SessionContext = createContext(undefined);

export const SessionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <SessionContextProvider supabaseClient={supabaseClient}>
      {children}
    </SessionContextProvider>
  );
};
