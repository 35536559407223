import { QueryClient } from "@tanstack/react-query";
import { Link, LoaderFunction, useLoaderData } from "react-router-dom";

import slackSaveForLater from "../../../assets/images/slack_save_for_later.jpg";
import { Button } from "../../../components/button";
import { ArrowLeftIcon } from "../../../components/icons";
import { slackQuery } from "../../../services/integrations/slack/query";

// eslint-disable-next-line react-refresh/only-export-components
export const loader = (queryClient: QueryClient) =>
  (async () => {
    const data = await queryClient.ensureQueryData({
      ...slackQuery("settings/integrations/slack"),
    });

    return data;
  }) satisfies LoaderFunction;

export const Slack = () => {
  const data = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >;

  return (
    <main className="mx-4 grid h-full w-full max-w-screen-2xl grow grid-cols-1 grid-rows-[min-content_min-content] gap-12 px-6 py-4 lg:py-12">
      <h1 className="flex items-center gap-3 text-2xl text-gray-primary">
        <Link to="/settings">
          <ArrowLeftIcon className="h-6 w-6" />
        </Link>
        Slack integration
      </h1>
      <section className="flex flex-col gap-12">
        <div className="flex flex-col gap-4">
          <p className="text-sm text-[#2A5E87]">
            <span className="mr-2 inline-flex h-[18px] w-[18px] items-center justify-center rounded-lg bg-[#D9E2E9] text-xs font-medium text-black">
              1
            </span>
            Authorize Lesen.AI to access your Slack messages
          </p>
          <Button
            variant="primary"
            as="a"
            href={data}
            className="inline-flex max-w-sm"
          >
            Authorize Slack
          </Button>
        </div>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-[#2A5E87]">
            <span className="mr-2 inline-flex h-[18px] w-[18px] items-center justify-center rounded-lg bg-[#D9E2E9] text-xs font-medium text-black">
              2
            </span>
            Capture a Slack message using the “Save for later” icon
          </p>
          <img src={slackSaveForLater} alt="Slack" className="max-w-sm" />
        </div>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-[#2A5E87]">
            <span className="mr-2 inline-flex h-[18px] w-[18px] items-center justify-center rounded-lg bg-[#D9E2E9] text-xs font-medium text-black">
              3
            </span>
            See Slack messages in Lesen.AI
          </p>
        </div>
      </section>
    </main>
  );
};
