import { Session, SupabaseClient } from "@supabase/supabase-js";
import Cookies from "js-cookie";
import { JwtPayload, jwtDecode } from "jwt-decode";

import { ApiError } from "../../utils/api";
import { ResponseError } from "../../utils/safeFetch";
import { captureException, captureMessage, setUser } from "../monitoring";
import { queryClient } from "../store";
import { tracking } from "../tracking";

export const supabaseClient = new SupabaseClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY,
  {
    auth: {
      storage: {
        getItem: (key) => {
          return Cookies.get(key) ?? null;
        },
        setItem: (key, value) => {
          Cookies.set(key, value, {
            expires: 365,
          });
        },
        removeItem: (key) => {
          Cookies.remove(key);
        },
      },
    },
  },
);


type CustomSession = Omit<Session, 'user'>

async function getSessionManually(): Promise<CustomSession>{
  const customSession:CustomSession = {
    access_token:'',
    expires_in: 0,
    refresh_token: '',
    token_type: '',
  }

  // Getting the session info from redirect url on signup or signin
  const params = parseHashParametersFromURL(location.href)

  let supaBaseSession = null 
  
  // If there is no session then get the session from supabase which gets it from localstorage or session storage
  // This case will be true when use just reloads the page
  if(!params.access_token)
    supaBaseSession = await getSession();

  if(supaBaseSession){
    customSession.access_token = supaBaseSession.access_token
    customSession.refresh_token = supaBaseSession.refresh_token
    customSession.provider_refresh_token = supaBaseSession.provider_refresh_token
    customSession.expires_at = supaBaseSession.expires_at
    customSession.expires_in = supaBaseSession.expires_in
    customSession.provider_token = supaBaseSession.provider_token
    customSession.token_type = supaBaseSession.token_type
  } else if(params.access_token && params.refresh_token) {
    customSession.access_token = params.access_token
    customSession.refresh_token = params.refresh_token
    customSession.provider_refresh_token = params.provider_refresh_token
    customSession.expires_at = +params.expires_at
    customSession.expires_in = +params.expires_in
    customSession.provider_token = params.provider_token
    customSession.token_type = params.token_type
  }

  return Promise.resolve<CustomSession>(customSession)
  
  function parseHashParametersFromURL(href: string) {
    const result: Record<string,string> = {}
  
    const url = new URL(href)
  
    if (url.hash && url.hash.startsWith('#')) {
      try {
        const hashSearchParams = new URLSearchParams(url.hash.substring(1))
        hashSearchParams.forEach((value, key) => {
          result[key] = value
        })
      } catch (e) {
        // hash is not a query string
      }
    }
  
    // search parameters take precedence over hash parameters
    url.searchParams.forEach((value, key) => {
      result[key] = value
    })
  
    return result
  }
}

export const authenticate = async () => {
  const session = await getSessionManually()

  const authResponse = await supabaseClient.auth.setSession({
    access_token: session.access_token,
    refresh_token: session.refresh_token
  });

  if (authResponse.error) {
    captureMessage("Failed to authenticate user", {
      level: "warning",
      extra: {
        authResponse,
      },
    });
    await signout();

    if (authResponse.error.status === 404) {
      return Promise.reject(new ResponseError(ApiError.UserNotFound, 404));
    } else {
      return Promise.reject(
        new ResponseError(ApiError.FailedToAuthenticate, 401),
      );
    }
  }

  // Write google refresh token to database when user authorizes
  // It is available within first request after authorization
  if (session.provider_refresh_token && authResponse.data.user) {
    await supabaseClient
      .from("profile")
      .update({ google_refresh_token: session.provider_refresh_token })
      .eq("id", authResponse.data.user.id);
  } else {
    captureMessage("Failed to retrieve refresh token from the session", {
      level: "warning",
      extra: {
        session: JSON.stringify({
          ...session,
          ...authResponse.data.user
        }),
      },
    });
  }


  try {
    setUser({ id: authResponse.data.user?.id });
    tracking?.identify(authResponse.data.user?.email);
  } catch (error) {
    captureMessage("Failed to identify user", {
      level: "info",
      extra: {
        id: authResponse.data.user?.id,
        email: authResponse.data.user?.email,
      },
    });
  }

  return Promise.resolve({
    ...session,
    user: authResponse.data.user
  });
};

export interface LoginQuerySuccess {
  redirectTo: string;
}

export interface LoginQueryError {
  message: string;
}

export const login = async ({
  prompt,
}: {
  prompt: "consent" | "select_account";
}) => {
  const { data, error } = await supabaseClient.auth.signInWithOAuth({
    provider: "google",
    options: {
      scopes: ["https://www.googleapis.com/auth/gmail.modify", "profile"].join(
        " ",
      ),
      queryParams: {
        access_type: "offline",
        prompt,
        include_granted_scopes: "true",
      },
      skipBrowserRedirect: true,
    },
  });

  if (error) {
    await signout();
    console.error(error);
    return Promise.reject({
      message: error.message ? error.message : "Failed to login",
    });
  }

  return Promise.resolve({ redirectTo: data.url });
};

export const signout = async () => {
  queryClient.clear();

  await clearSession();
};

export const clearSession = async () => {
  await supabaseClient.auth.signOut();
};

export const getSession = async () => {
  const {
    data: { session },
  } = await supabaseClient.auth.getSession();

  if (!session)
    return Promise.reject(new ResponseError(ApiError.UserSessionNotFound, 404));

  const validatedSession = await validateSession(session);

  return Promise.resolve(validatedSession);
};

export const validateSession = async (session: Session) => {
  try {
    const decodedToken = jwtDecode<JwtPayload>(session.access_token, {
      header: false,
    });

    const currentTime = Math.floor(Date.now() / 1000);
    if (!decodedToken?.exp || decodedToken.exp < currentTime) {
      return Promise.resolve(await refreshSession());
    }

    return Promise.resolve(session);
  } catch (error) {
    captureException(new ResponseError(ApiError.FailedToValidateSession, 401), {
      level: "error",
      extra: {
        session,
      },
    });
    return Promise.reject(
      new ResponseError(ApiError.FailedToValidateSession, 401),
    );
  }
};

export const refreshSession = async () => {
  try {
    const {
      data: { session },
      error,
    } = await supabaseClient.auth.refreshSession();

    if (error ?? !session) {
      captureException(
        new ResponseError(ApiError.FailedToRefreshSession, 401),
        {
          level: "error",
          extra: {
            session,
            error,
          },
        },
      );
      return Promise.reject(
        new ResponseError(ApiError.FailedToRefreshSession, 401),
      );
    }

    return session;
  } catch (error) {
    captureException(new ResponseError(ApiError.FailedToRefreshSession, 401), {
      level: "error",
      extra: {
        error,
      },
    });
    return Promise.reject(
      new ResponseError(ApiError.FailedToRefreshSession, 401),
    );
  }
};

export const stringifySupabaseSession = (session: Session): string => {
  return JSON.stringify([
    session.access_token,
    session.refresh_token,
    session.provider_token,
    session.provider_refresh_token,
    session.user.factors,
  ]);
};
