import React, { useRef, FormEvent } from 'react';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { format, isEqual, sub } from 'date-fns';
import { z } from 'zod';
import { CloseIcon, GmailIcon, SlackIcon, SendMessageIcon } from '../icons';
import { Toast } from '../toast';
import tickSrc from '../../assets/images/tick.svg';
import { archiveThreadMutation, postReplyMutation } from '../../services/thread/mutations';
import { threadSerializedSchema, Origin } from '../../services/thread/schema';
import parse from 'html-react-parser';

interface ThreadProps {
  onSuccessfulReply: (id: string) => void;
  onSuccessfulArchive: (id: string) => void;
  data: z.infer<typeof threadSerializedSchema>;
  isNewThread?: boolean;
}

interface Participant {
  user: string;
  last_activity: string;
}

export const Thread: React.FC<ThreadProps> = ({
  data,
  onSuccessfulReply,
  onSuccessfulArchive,
  isNewThread = false,
}) => {
  const replyInput = useRef<HTMLInputElement>(null);
  const threadRef = useRef<HTMLDivElement>(null);

  const replyMutation = useMutation({
    mutationFn: postReplyMutation,
    onSuccess: () => {
      onSuccessfulReply(data.threadId);
    },
  });

  const archiveMutation = useMutation({
    mutationFn: archiveThreadMutation,
    onSuccess: () => {
      onSuccessfulArchive(data.threadId);
    },
    onError: (error) => {
      console.error('Failed to archive thread:', error);
    }
  });

  const getOriginSubject = () => {
    let subjectLine = 'go to ';
    switch (data.origin) {
      case Origin.Gmail:
        subjectLine += 'Gmail - Subject:';
        if (data.subject) {
          subjectLine += ` "${data.subject}"`;
        }
        break;
      case Origin.Slack:
        subjectLine += 'Slack';
        if (data.subject) {
          subjectLine += ` in #${data.subject}`;
        }
        break;
      default:
        break;
    }

    if (data.permalink) {
      return (
        <a href={data.permalink} target="_blank" rel="noreferrer">
          {subjectLine}
        </a>
      );
    }

    return subjectLine;
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (replyMutation.isPending || replyMutation.isSuccess) return;
    const message = replyInput.current?.value.trim() ? replyInput.current?.value.trim() : '';
    if (!message) return;

    threadRef.current?.focus();
    replyMutation.mutate({
      threadId: data.threadId,
      message,
    });
  };

  const getNewestParticipant = (participants: Participant[]): Participant => {
    return participants.reduce((newest, participant) => {
      return new Date(participant.last_activity) > new Date(newest.last_activity) ? participant : newest;
    });
  };

  const newestParticipant = getNewestParticipant(data.participants);

  const renderSuccess = () => {
    if (replyMutation.isSuccess)
      return (
        <div>
          <div className="absolute left-0 top-0 h-full w-full rounded bg-white opacity-60"></div>
          <div className="absolute left-0 top-0 flex h-full w-full">
            <div className="m-auto flex h-12 items-center justify-center gap-3 rounded-lg bg-black p-3">
              <img className="h-4 w-4" src={tickSrc} alt="" /> <span className="text-white">Sent</span>
            </div>
          </div>
        </div>
      );
  };

  const onThreadCloseClickHandler = () => {
    archiveMutation.mutate(data.threadId);
  };

  const safeParse = (input: string): React.ReactNode => {
    try {
      return parse(input, {}) as React.ReactNode;
    } catch (error) {
      console.error('Error parsing input:', error, 'Input:', input);
      return input;
    }
  };

  const summary: React.ReactNode | string | null = safeParse(data.summary ?? '');

  return (
    <>
      <div ref={threadRef} className={'relative rounded bg-white p-6 text-gray-secondary'}>
        {renderSuccess()}
        <div className="flex justify-between">
          {isNewThread ? <div className="notification-icon"></div> : null}
          <div>
            Last activity: <span className="font-medium">{newestParticipant.user}</span>
          </div>
          <CloseIcon className="w-7 cursor-pointer close-icon" onClick={onThreadCloseClickHandler} />
        </div>
        <div className="text-xs underline">{getOriginSubject()}</div>
        <div
          className={classNames('mt-2 font-bold text-sm', {
            'text-yellow-primary': data.matrixAction === 'schedule',
            'text-green-primary': data.matrixAction === 'delegate',
            'text-red-primary': data.matrixAction === 'do',
          })}
        >
          {data.matrixAction === 'delete' && 'No'}
          {data.matrixAction === 'schedule' && 'Medium'}
          {data.matrixAction === 'delegate' && 'Low'}
          {data.matrixAction === 'do' && 'High'} Priority
        </div>
        <p className="my-4 text-2xl text-black">{summary}</p>
        <div className="text-md mb-2">{getDateLabel(data.sentAt)}</div>
        <div className="flex h-12 w-full items-center rounded-lg bg-gray-50 p-2">
          <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-white p-2">
            {data.origin == Origin.Gmail ? <GmailIcon className="h-4 w-4" /> : <SlackIcon className="h-4 w-4" />}
          </div>
          <form onSubmit={handleSubmit} className="grow">
            <input ref={replyInput} className="w-full bg-transparent px-3 outline-none" type="text" placeholder="Reply here..." />
          </form>
          <SendMessageIcon className="h-8 w-8 cursor-pointer" onClick={handleSubmit} />
        </div>
      </div>
      {replyMutation.isError ? <Toast key="error-toast" type="error" variant="primary" message={'Failed to post reply.'} /> : null}

      {archiveMutation.isError ? <Toast key="error-toast" type="error" variant="primary" message={'Failed to archive.'} /> : null}
    </>
  );
};

const getDateLabel = (date: string) => {
  const dateObj = new Date(format(new Date(date), 'P'));
  const today = new Date(format(new Date(), 'P'));

  if (isEqual(dateObj, today)) {
    return (
      <>
        Today&nbsp;
        <wbr />
        <span className="whitespace-nowrap">{format(new Date(date), 'p')}</span>
      </>
    );
  }

  if (isEqual(dateObj, sub(today, { days: 1 }))) {
    return (
      <>
        Yesterday&nbsp;
        <wbr />
        <span className="whitespace-nowrap">{format(new Date(date), 'p')}</span>
      </>
    );
  }

  return format(new Date(date), 'dd.MM.yyy');
};
