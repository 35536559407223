import { SVGProps } from "react";

export const GmailIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.36364 19.5H6.54545V11.7727L2 8.36365V18.1364C2 18.8909 2.61136 19.5 3.36364 19.5Z"
        fill="#4285F4"
      />
      <path
        d="M17.4546 19.5H20.6364C21.3909 19.5 22 18.8886 22 18.1364V8.36365L17.4546 11.7727"
        fill="#34A853"
      />
      <path
        d="M17.4546 5.8636V11.7727L22 8.3636V6.54542C22 4.85905 20.075 3.89769 18.7273 4.90905"
        fill="#FBBC04"
      />
      <path
        d="M6.54544 11.7727V5.86365L12 9.95456L17.4545 5.86365V11.7727L12 15.8636"
        fill="#EA4335"
      />
      <path
        d="M2 6.54542V8.3636L6.54545 11.7727V5.8636L5.27273 4.90905C3.92273 3.89769 2 4.85905 2 6.54542Z"
        fill="#C5221F"
      />
    </svg>
  );
};
