import { useEffect, useState } from "react";
import { useFetcher } from "react-router-dom";

import { action } from "../../routes/info";
import { Toast } from "../toast";

type ActionDataType = Awaited<ReturnType<ReturnType<typeof action>>>;

export const UndoArchiveToast = () => {
  const [[single, all], setToasts] = useState<(ActionDataType | undefined)[]>(
    [],
  );
  const fetcher = useFetcher({ key: "info-item-dismiss" });
  const actionData = fetcher.data as ActionDataType;

  useEffect(() => {
    if (actionData?.intent === "dismiss") {
      if (actionData.source === "all") {
        setToasts([single, actionData]);
      } else {
        setToasts([actionData, all]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData]);

  function handleUndo(source: string | undefined, ids: string[] | undefined) {
    if (!source || !ids) return;

    fetcher.submit(
      {
        intent: "restore",
        source,
        ids,
      },
      { method: "post", action: "/info", encType: "application/json" },
    );
  }

  return (
    <>
      <Toast
        open={!!single}
        onOpenChange={(open) => !open && setToasts([undefined, all])}
        type="info"
        variant="archive"
        message="Message archived!"
        action={{
          label: "Undo",
          onClick: () => handleUndo(single?.source, single?.ids),
        }}
      />
      <Toast
        open={!!all}
        onOpenChange={(open) => !open && setToasts([single, undefined])}
        type="info"
        variant="archive"
        message="All messages archived!"
        action={{
          label: "Undo",
          onClick: () => handleUndo(all?.source, all?.ids),
        }}
      />
    </>
  );
};
