import { captureException } from "@sentry/react";
import { useState } from "react";
import { Link, Navigate } from "react-router-dom";

import notificationsChromeImg from "../../assets/images/notifications-chrome.png";
import notificationsSafariImg from "../../assets/images/notifications-safari.png";
import { Button } from "../../components/button";
import { ArrowLeftIcon } from "../../components/icons";
import { sendNotification } from "../../services/notification";
import { IS_NOTIFICATION_SERVICE_AVAILABLE } from "../../utils/consts";

export const Notifications = () => {
  const [permission, setPermission] = useState<NotificationPermission>(
    Notification.permission,
  );
  const isGranted = permission === "granted";

  const authorizeNotifications = async () => {
    try {
      const permission = await Notification.requestPermission();

      setPermission(permission);

      if (permission === "granted") {
        sendNotification(
          "Notifications enabled",
          "You will now receive notifications from Lesen.AI",
          {
            url: "/",
          },
        );
      }
    } catch (error) {
      captureException(error);

      throw error;
    }
  };

  const testNotifications = () => {
    sendNotification(
      "Notifications test",
      "You should see this notification in your system",
      {
        url: "/",
      },
    );
  };

  if (!IS_NOTIFICATION_SERVICE_AVAILABLE) {
    return <Navigate to="/settings" replace />;
  }

  return (
    <main className="mx-4 grid h-full w-full max-w-screen-2xl grow grid-cols-1 grid-rows-[min-content_min-content] gap-12 px-6 py-4 lg:py-12">
      <h1 className="flex items-center gap-3 text-2xl text-gray-primary">
        <Link to="/settings">
          <ArrowLeftIcon className="h-6 w-6" />
        </Link>
        Notifications
      </h1>
      <section className="flex flex-col gap-12">
        <div className="flex flex-col gap-4">
          <p className="text-sm text-[#2A5E87]">
            <span className="mr-2 inline-flex h-[18px] w-[18px] items-center justify-center rounded-lg bg-[#D9E2E9] text-xs font-medium text-black">
              1
            </span>
            Authorize Lesen.AI to send you browser notifications
          </p>
          <Button
            variant="primary"
            as="button"
            className="inline-flex max-w-sm"
            disabled={permission === "denied"}
            onClick={isGranted ? testNotifications : authorizeNotifications}
          >
            {isGranted ? "Test Notifications" : "Turn on Notifications"}
          </Button>
          {permission === "denied" && (
            <p className="max-w-sm text-sm text-error-regular">
              Notifications have been denied. Go to your browser settings to
              enable them.
            </p>
          )}
        </div>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-[#2A5E87]">
            <span className="mr-2 inline-flex h-[18px] w-[18px] items-center justify-center rounded-lg bg-[#D9E2E9] text-xs font-medium text-black">
              2
            </span>
            Wait for new messages to appear in{" "}
            <Link
              to="/"
              className="text-hyperlink-new underline decoration-1 hover:no-underline"
            >
              🔥 Hot Comms
            </Link>
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-[#2A5E87]">
            <span className="mr-2 inline-flex h-[18px] w-[18px] items-center justify-center rounded-lg bg-[#D9E2E9] text-xs font-medium text-black">
              3
            </span>
            See notifications in your system
          </p>
          <img
            src={notificationsChromeImg}
            alt="Notification Chrome"
            className="max-w-sm rounded-lg"
          />
          <img
            src={notificationsSafariImg}
            alt="Notification Safari"
            className="max-w-sm rounded-lg"
          />
        </div>
      </section>
    </main>
  );
};
