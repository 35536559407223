import { QueryClient } from "@tanstack/react-query";
import { ActionFunction } from "react-router-dom";
import { z } from "zod";

import {
  infoItemHotCommsFilterQuery,
  infoItemListInfiniteQuery,
} from "../../services/info/queries";
import { itemTableSerializedSchema } from "../../services/info/schema";
import { sendNotification } from "../../services/notification";

export type ActionData = Awaited<ReturnType<ReturnType<typeof action>>>;

export const action = (queryClient: QueryClient) =>
  (async ({ request }: { request: Request }) => {
    const formData = await request.formData();
    const intent = formData.get("intent");

    if (intent === "notify") {
      const itemId = formData.get("id") as
        | z.infer<typeof itemTableSerializedSchema>["id"]
        | null;

      if (!itemId) return null;

      const filters = await queryClient.ensureQueryData({
        ...infoItemHotCommsFilterQuery(),
      });
      const data = await queryClient.fetchInfiniteQuery({
        ...infoItemListInfiniteQuery(filters),
      });

      const item = data.pages
        .flatMap((page) => page.list)
        .find((item) => item.id === itemId);

      // Retrieve the archived message from localStorage so we do not pop up a browser notification for them when we restore them
      const currentArchivedItems = JSON.parse(localStorage.getItem("recentlyArchivedInfoItems") ?? "[]") as string[];
      const wasItemArchived = !!(item?.id && currentArchivedItems.includes(item?.id));

      if (item && !wasItemArchived) {
        void sendNotification("New message", item.topics?.join(", "), {
          url: `${window.location.origin}?newMessage=${item.id}`,
        });
      }

      return null;
    }

    return null;
  }) satisfies ActionFunction;
