import { ReactNode } from "react";

import loaderSrc from "../assets/images/loader.gif";
import logoSmSrc from "../assets/images/logo-text-sm.svg";

export const PageLoader = ({ children }: { children?: ReactNode }) => (
  <main className="flex h-screen w-full grow items-center justify-center px-4 py-6 sm:px-8">
    <section className="flex flex-col items-center justify-center gap-6 text-gray-primary">
      <img className="h-56 w-56" src={logoSmSrc} alt="" />
      <span className="text-2xl">communicating what matters</span>
      <img className="w-10" src={loaderSrc} alt="" />
      {children}
    </section>
  </main>
);
