import { updateVipContactsMutation } from '../../services/vip-comms/mutations';
import { ActionFunction, json } from "react-router-dom";


export const action: ActionFunction = async ({ request }) => {
    const formData = await request.formData();
    const contacts = JSON.parse(formData.get("contacts") as string) as string[];
  
    try {
      const { mutationFn: updateMutation } = updateVipContactsMutation();
      await updateMutation(contacts);
  
      // Return a response with a state
      return json({ message: "Updated VIP comms successfully!" }, {
        headers: {
          "Location": "/vip-comms?success",
        },
        status: 303,
      });
    } catch (error) {
    
      return json({ error: "Failed to update VIP comms, please try again later." }, {
        headers: {
          "Location": "/vip-comms?error",
        },
        status: 303,
      });
    }
  };