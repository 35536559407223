import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Toast } from '../../components/toast';


export const ApiToastManager = ({ setUpdateSuccessful, setIsLoading }: { 
  setUpdateSuccessful: React.Dispatch<React.SetStateAction<boolean>>, 
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const location = useLocation();
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState<"success" | "info" | "error">("success");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("success")) {
      setUpdateSuccessful(true);
      setMessage("Vip Comms successfully updated!");
      setShowToast(true);
      setToastType("success");
      searchParams.delete("success");
      window.history.replaceState(null, "", location.pathname + "?" + searchParams.toString());
      setIsLoading(false);
    } else if (searchParams.has("error")) {
      setUpdateSuccessful(false);
      setMessage("Vip Comms update failed!");
      setToastType("error");
      setShowToast(true);
      searchParams.delete("error");
      window.history.replaceState(null, "", location.pathname + "?" + searchParams.toString());
      setIsLoading(false);
    }
  }, [location, setUpdateSuccessful, setIsLoading]);

  return (
    showToast && (
      <Toast
        open={showToast}
        onOpenChange={() => setShowToast(false)}
        type={toastType}
        variant="archive"
        message={message}
        duration={7000}
      />
    )
  );
};
