import PropTypes from 'prop-types';
import { SVGProps } from 'react';

interface ArchiveIconProps extends SVGProps<SVGSVGElement> {
  theme?: 'light' | 'dark';
}

export const ArchiveIcon: React.FC<ArchiveIconProps> = ({ theme, ...props }) => {
  const strokeColor = theme === 'light' ? '#000000' : '#e8eaed';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke={strokeColor}
      className="size-6"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0-3-3m3 3 3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
      />
    </svg>
  );
};

ArchiveIcon.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
};

ArchiveIcon.defaultProps = {
  theme: 'dark',
};
