// NOTE: map picked from OSS project
// https://github.com/ealush/emoji-picker-react/blob/master/src/data/emojis.json
import EMOJI_MAP from "./emoji.json";

export function replaceEmojiSlugs(str: string): string {
  const regex = /:[a-z0-9_+]+(::skin-tone-[1-6])?:/g;

  return str.replace(regex, (match) => {
    type EmojiType = (typeof EMOJI_MAP)[keyof typeof EMOJI_MAP][number];

    let emoji: EmojiType | undefined;

    const slug = match.includes("::")
      ? match.split("::")[0].slice(1)
      : match.slice(1, -1);

    const skinTone = match.includes("::")
      ? match.split("::")[1].slice(-2, -1)
      : null;

    // Find emoji by slug
    for (const category of Object.values(EMOJI_MAP)) {
      emoji = category.find((emoji) =>
        emoji.n.includes(slug.replace(/_/g, " ")),
      );

      if (emoji) break;
    }

    if (!emoji) return match;

    // Handle skin tones
    if (skinTone && "v" in emoji && Array.isArray(emoji.v) && emoji.v.length) {
      let index = parseInt(skinTone, 10);

      if (index >= emoji.v.length) {
        index = emoji.v.length - 1;
      }

      const parts = emoji.v[index]
        .split("-")
        .map((code: string) => parseInt(code, 16));

      return String.fromCodePoint(...parts);
    }

    // Handle flags and other with multiple unicode characters
    if (emoji.u.includes("-")) {
      const parts = emoji.u
        .split("-")
        .map((code: string) => parseInt(code, 16));

      return String.fromCodePoint(...parts);
    }

    // Handle single unicode characters
    return "&#x" + emoji.u.toUpperCase() + ";";
  });
}
