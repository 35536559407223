import { API_BASE_URL, ApiError } from "../../../utils/api";
import { handleApiException, parsedFetch } from "../../../utils/safeFetch";
import { slackApiSchema } from "./schema";

const getSlackOAuthURL = async (source: string) => {
  try {
    const response = await parsedFetch(
      slackApiSchema,
      `${API_BASE_URL}/slack/install?source=${source}`,
      {
        method: "GET",
      },
    );

    return response;
  } catch (error) {
    return handleApiException(ApiError.FailedToFetchSlackOAuthURL, error);
  }
};

export const slackQuery = (source: string) => ({
  queryKey: ["integrations", "slack"],
  queryFn: async () => {
    const slack = await getSlackOAuthURL(source);

    return slack;
  },
});
