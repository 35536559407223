/**
 * @returns {string} String, one of `dark`, `light`, `no-preference`
 */
export function getPreferredColorSchema() {
  if (typeof window.matchMedia !== "function") return "no-preference";

  const isDark = window.matchMedia("(prefers-color-scheme: dark)");
  const isLight = window.matchMedia("(prefers-color-scheme: light)");

  if (isDark.matches) return "dark";
  else if (isLight.matches) return "light";
  return "no-preference";
}
