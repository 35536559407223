import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const useNewMessageHighlight = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchParams.has("newMessage")) {
        searchParams.delete("newMessage");
        setSearchParams(searchParams);
      }
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchParams, setSearchParams]);

  return searchParams.get("newMessage") ?? undefined;
};
