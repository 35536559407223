import { QueryClient } from "@tanstack/react-query";
import { Suspense, useState } from "react";
import {
  ActionFunction,
  Await,
  Link,
  LoaderFunction,
  defer,
  useFetcher,
  useLoaderData,
} from "react-router-dom";
import { z } from "zod";

import { PageLoader } from "../../components/PageLoader";
import { ArrowLeftIcon } from "../../components/icons";
import { Paper } from "../../components/paper";
import { Slider } from "../../components/slider";
import { Switch } from "../../components/switch";
import {
  DEADLINE_MAP,
  DEADLINE_OPTIONS_TUPLE,
  SUBJECTS_MAP,
} from "../../services/info/filters";
import { infoItemFilterSettingsMutation } from "../../services/info/mutations";
import { infoItemHotCommsFilterQuery } from "../../services/info/queries";
import { Deadline, infoItemListFilterApiSchema } from "../../services/info/schema";

export type LoaderData = Awaited<ReturnType<typeof getLoaderData>>;

// eslint-disable-next-line react-refresh/only-export-components
export const getLoaderData = async (queryClient: QueryClient) => {
  return Promise.resolve({
    filters: queryClient.fetchQuery({
      ...infoItemHotCommsFilterQuery(),
    }),
  });
};

// eslint-disable-next-line react-refresh/only-export-components
export const loader = (queryClient: QueryClient) =>
  (async () =>
    defer(await getLoaderData(queryClient))) satisfies LoaderFunction;

// eslint-disable-next-line react-refresh/only-export-components
export const action = () =>
  (async ({ request }: { request: Request }) => {
    const { intent, ...filters } = (await request.json()) as z.infer<
      typeof infoItemListFilterApiSchema
    > & { intent: "update" };

    infoItemListFilterApiSchema.parse(filters);

    if (intent === "update") {
      const { mutationFn } = infoItemFilterSettingsMutation();

      await mutationFn(filters);

      return null;
    }

    return null;
  }) satisfies ActionFunction;

export const Communication = () => {
  const data = useLoaderData() as LoaderData;
  const fetcher = useFetcher();

  function handleSubmit(filters: Awaited<LoaderData["filters"]>) {
    fetcher.submit(
      {
        ...filters,
        intent: "update",
      },
      {
        method: "post",
        action: "/hot-communication-settings",
        encType: "application/json",
      },
    );
  }

  return (
    <main className="mx-4 grid h-full w-full max-w-screen-2xl grid-flow-row auto-rows-min gap-12 px-6 py-4 lg:py-12">
      <h1 className="flex items-center gap-3 text-2xl text-gray-primary">
        <Link to="/">
          <ArrowLeftIcon className="h-6 w-6" />
        </Link>
        🔥 Hot Comms
      </h1>

      <div className="flex flex-col gap-6">
        <section className="flex flex-col gap-2">
          <h2 className="text-xl font-semibold text-gray-primary">
            Configure your hot communication
          </h2>
          <p className="text-base  text-gray-primary">
            Define what types of communication you want to have surfaced and get
            notified about in your hot communication
          </p>
        </section>

        <Suspense fallback={<PageLoader />}>
          <Await resolve={data.filters}>
            {(filters: Awaited<LoaderData["filters"]>) => {
              if (!filters) {
                return null;
              }

              return (
                <>
                  <Paper as="section" className="flex flex-col gap-3 px-6 py-4">
                    <Switch
                      label="Urgent comms"
                      id="urgent_comms"
                      labelSide="right"
                      defaultChecked={!!filters.urgent_comms}
                      onChange={(checked) => {
                        handleSubmit({
                          ...filters,
                          urgent_comms: checked,
                        });
                      }}
                    />
                    <p className="whitespace-normal text-sm text-gray-secondary">
                      Communication that you have sent yourself are excluded
                      from you hot communcation
                    </p>
                  </Paper>

                  <div className="grid grid-cols-[1fr_max-content_1fr] items-center">
                    <hr />
                    <span className="px-6 text-gray-primary">or</span>
                    <hr />
                  </div>

                  <Paper
                    as="section"
                    className="flex flex-col gap-3 px-6 pb-6 pt-4"
                  >
                    <p className="whitespace-normal">Subjects</p>
                    <p className="mb-4 whitespace-normal text-sm text-gray-secondary">
                      Identifying specific subjects that you want to be on top
                      off ASAP.
                    </p>
                    {Object.entries(SUBJECTS_MAP).map(([id, label]) => (
                      <Switch
                        key={id}
                        id={id}
                        label={label}
                        labelSide="right"
                        defaultChecked={filters.subjects?.includes(
                          id as keyof typeof SUBJECTS_MAP,
                        )}
                        onChange={(checked) => {
                          const subjects = checked
                            ? [
                                ...(filters.subjects ?? []),
                                id as keyof typeof SUBJECTS_MAP,
                              ]
                            : filters.subjects?.filter((_id) => _id !== id);
                          handleSubmit({
                            ...filters,
                            subjects,
                          });
                        }}
                      />
                    ))}
                  </Paper>

                  <div className="grid grid-cols-[1fr_max-content_1fr] items-center">
                    <hr />
                    <span className="px-6 text-gray-primary">or</span>
                    <hr />
                  </div>

                  <Deadlines
                    deadline={filters.deadline_at}
                    onChange={(deadline_at) => {
                      handleSubmit({
                        ...filters,
                        deadline_at: deadline_at ?? null,
                      });
                    }}
                  />

                  <section className="flex flex-col gap-6">
                    <h2 className="text-xl font-semibold text-gray-primary">
                      Additional Settings
                    </h2>
                    <Paper className="flex flex-col gap-3 px-6 py-4">
                      <Switch
                        label="Hide self-communication"
                        id="hide_self_communication"
                        labelSide="right"
                        defaultChecked={!!filters.hide_self_communication}
                        onChange={(checked) => {
                          handleSubmit({
                            ...filters,
                            hide_self_communication: checked,
                          });
                        }}
                      />
                      <p className="whitespace-normal text-sm text-gray-secondary">
                        Communication that you have sent yourself are excluded
                        from you hot communcation
                      </p>
                    </Paper>
                  </section>
                </>
              );
            }}
          </Await>
        </Suspense>
      </div>
    </main>
  );
};

const Deadlines = ({
  deadline,
  onChange,
}: {
  deadline: Awaited<Awaited<LoaderData["filters"]>["deadline_at"]>;
  onChange: (
    deadline_at: Awaited<LoaderData["filters"]>["deadline_at"],
  ) => void;
}) => {
  const defaultSliderIndex = deadline
    ? [DEADLINE_OPTIONS_TUPLE.indexOf(deadline)]
    : [1];
  const [sliderIndex, setSliderIndex] = useState(defaultSliderIndex);
  const [checked, setChecked] = useState(!!deadline);

  return (
    <Paper as="section" className="flex flex-col gap-3 px-6 pb-6 pt-4">
      <Switch
        label="Deadlines"
        id="deadlines_enabled"
        labelSide="right"
        checked={checked}
        onChange={(checked) => {
          setChecked(checked);
          if (checked) {
            setSliderIndex([1]);
          }

          onChange(
            checked ? DEADLINE_OPTIONS_TUPLE[defaultSliderIndex[0]] : undefined,
          );
        }}
      />
      <p className="mb-4 whitespace-normal text-sm text-gray-secondary">
        Never miss a deadline_at, we analyse your communications and detect
        ad-hoc close deadlines, define how close a deadline_at needs to be
        alerted
      </p>
      <div className="flex w-full flex-col">
        <Slider
          value={sliderIndex}
          max={DEADLINE_OPTIONS_TUPLE.length - 1}
          step={1}
          disabled={!checked}
          onValueChange={(value) => {
            if (!checked) {
              setChecked(true);
            }
            setSliderIndex(value);

            onChange(DEADLINE_OPTIONS_TUPLE[value[0]]);
          }}
        />

        <div
          className={`grid grid-cols-3 pt-2 font-semibold text-gray-disabled ${
            !checked ? "opacity-50" : ""
          }`}
        >
          <p
            className={`place-self-start transition-colors ${
              sliderIndex[0] === 0 && checked ? "text-gray-primary" : ""
            }`.trim()}
          >
            {DEADLINE_MAP[Deadline.OverdueAndToday]}
          </p>
          <p
            className={`place-self-center transition-colors ${
              sliderIndex[0] === 1 && checked ? "text-gray-primary" : ""
            }`.trim()}
          >
            {DEADLINE_MAP[Deadline.Tomorrow]}
          </p>
          <p
            className={`place-self-end transition-colors ${
              sliderIndex[0] === 2 && checked ? "text-gray-primary" : ""
            }`.trim()}
          >
            {DEADLINE_MAP[Deadline.In2Days]}
          </p>
        </div>
      </div>
    </Paper>
  );
};
