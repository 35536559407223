import { QueryClient, useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { Suspense } from "react";
import {
  ActionFunction,
  Await,
  Link,
  LoaderFunction,
  defer,
  useFetcher,
  useLoaderData,
} from "react-router-dom";
import { z } from "zod";

import { PageLoader } from "../../components/PageLoader";
import { ArrowLeftIcon } from "../../components/icons";
import { InfoList } from "../../components/info/InfoList";
import { Select } from "../../components/select";
import {
  DEADLINE_OPTIONS_ALL_MAP,
  ORIGIN_OPTIONS_MAP,
  SUBJECTS_MAP,
} from "../../services/info/filters";
import { infoItemFilterViewAllMutation } from "../../services/info/mutations";
import {
  infoItemListInfiniteQuery,
  infoItemViewAllFilterQuery,
} from "../../services/info/queries";
import { infoItemListFilterApiSchema } from "../../services/info/schema";

export type LoaderData = Awaited<ReturnType<typeof getLoaderData>>;

// eslint-disable-next-line react-refresh/only-export-components
export const getLoaderData = async (queryClient: QueryClient) => {
  const filters = await queryClient.fetchQuery({
    ...infoItemViewAllFilterQuery(),
  });

  return Promise.resolve({
    infoItems: queryClient.fetchInfiniteQuery({
      ...infoItemListInfiniteQuery(filters),
    }),
  });
};

// eslint-disable-next-line react-refresh/only-export-components
export const loader = (queryClient: QueryClient) =>
  (async () =>
    defer(await getLoaderData(queryClient))) satisfies LoaderFunction;

// eslint-disable-next-line react-refresh/only-export-components
export const action = () =>
  (async ({ request }: { request: Request }) => {
    const filters = (await request.json()) as z.infer<
      typeof infoItemListFilterApiSchema
    >;

    infoItemListFilterApiSchema.parse(filters);

    const { mutationFn } = infoItemFilterViewAllMutation();

    await mutationFn(filters);

    return null;
  }) satisfies ActionFunction;

const Filters = () => {
  const fetcher = useFetcher();
  const { data: filters } = useQuery({
    ...infoItemViewAllFilterQuery(),
  });

  function handleFilterChange(
    filters: Partial<z.infer<typeof infoItemListFilterApiSchema>>,
  ) {
    fetcher.submit(filters, {
      method: "post",
      action: "/view-all",
      encType: "application/json",
    });
  }

  return (
    <>
      <div className="hidden xl:block xl:text-base">Filter</div>
      <Select
        type="multi"
        trigger="Topic"
        items={Object.entries(SUBJECTS_MAP).map((item) => ({
          value: item[0],
          children: item[1],
        }))}
        defaultSelected={filters?.subjects ?? []}
        onCheckedChange={(items) =>
          handleFilterChange({
            subjects: items as z.infer<
              typeof infoItemListFilterApiSchema
            >["subjects"],
          })
        }
      />
      <Select
        type="single"
        trigger="Due Date"
        items={Object.entries(DEADLINE_OPTIONS_ALL_MAP).map((item) => ({
          value: item[0],
          children: item[1],
        }))}
        defaultSelected={filters?.deadline_at ? [filters.deadline_at] : []}
        onCheckedChange={([value]) =>
          handleFilterChange({
            deadline_at:
              (value as z.infer<
                typeof infoItemListFilterApiSchema
              >["deadline_at"]) ?? null,
          })
        }
      />
      <Select
        type="single"
        trigger="Source"
        items={Object.entries(ORIGIN_OPTIONS_MAP).map((item) => ({
          value: item[0],
          children: item[1],
        }))}
        defaultSelected={filters?.origin ? [filters.origin] : []}
        onCheckedChange={([value]) =>
          handleFilterChange({
            origin:
              (value as z.infer<typeof infoItemListFilterApiSchema>["origin"]) ??
              null,
          })
        }
      />
    </>
  );
};

const Main = () => {
  const { data: filters } = useQuery({
    ...infoItemViewAllFilterQuery(),
  });
  const { data, status, fetchNextPage } = useInfiniteQuery({
    ...infoItemListInfiniteQuery(filters!),
  });

  if (["pending", "error"].includes(status) || !data?.pages?.length)
    return null;

  const list = data?.pages.flatMap((page) => page.list);
  const lastPage = data?.pages[data.pages.length - 1];

  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <InfoList items={list} />
      {lastPage.nextCursor ? (
        <button
          type="button"
          onClick={() => fetchNextPage()}
          className="flex cursor-pointer flex-row items-center justify-center pt-5 gap-2 text-sm font-semibold text-primary-default transition-colors hover:text-error-dark"
        >
          Load more
        </button>
      ) : null}
    </div>
  );
};

export const ViewAll = () => {
  const { infoItems } = useLoaderData() as LoaderData;

  return (
    <main className="mx-4 grid h-full w-full max-w-screen-2xl grow grid-cols-1 grid-rows-[max-content_max-content_1fr] px-6 py-4 sm:py-12">
      <section className="flex flex-col gap-4">
        <div className="flex flex-col gap-4 lg:grid lg:grid-cols-2 lg:grid-rows-1 lg:items-center lg:justify-between xl:grid-cols-[max-content_0.5fr_1fr]">
          <h1 className="inline-flex flex-row items-center justify-start gap-4 text-2xl text-gray-primary">
            <Link to="/">
              <ArrowLeftIcon className="h-6 w-6" />
            </Link>
            All your communication easily accessible!
          </h1>

          <div className="grid grid-cols-1 grid-rows-3 gap-4 sm:grid-cols-3 sm:grid-rows-1 xl:col-start-3 xl:col-end-3 xl:grid-cols-[max-content_1fr_1fr_1fr] xl:items-center">
            <Filters />
          </div>
        </div>
        <Suspense fallback={<PageLoader />}>
          <Await resolve={infoItems}>
            {(infoItems: Awaited<LoaderData["infoItems"]>) => {
              const list = infoItems?.pages.flatMap((page) => page.list);

              return list?.length ? (
                <Main />
              ) : (
                <div className="mx-4 grid h-full w-full max-w-screen-2xl grow grid-cols-1 grid-rows-1 p-8">
                  <h1 className="self-center justify-self-center opacity-40">
                    Looks like there&apos;re no items!
                  </h1>
                </div>
              );
            }}
          </Await>
        </Suspense>
      </section>
    </main>
  );
};
