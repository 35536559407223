import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Toast } from "../toast";


export const ToastManager = () => {
  const location = useLocation();
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("promptSubmitted") === "true") {
      setMessage("Magic Comms successfully saved, analyzing threads...");
      setShowToast(true);
      searchParams.delete("promptSubmitted");
      window.history.replaceState(null, "", location.pathname + "?" + searchParams.toString());
    }
  }, [location]);

  return (
    showToast && (
      <Toast
        open={showToast}
        onOpenChange={() => setShowToast(false)}
        type="info"
        variant="archive"
        message={message}
        duration={7000}
      />
    )
  );
};
