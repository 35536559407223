import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

if (import.meta.env.MODE === "production" && import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://898444f3f4c5301ac84f6923e8b167d1@o4506099369246720.ingest.sentry.io/4506103470817280",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],
    tracePropagationTargets: ["app-stg.lesen.ai", "app.lesen.ai"],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  });
}

export {
  withErrorBoundary,
  setUser,
  captureException,
  captureMessage,
} from "@sentry/react";
