import { API_URL, ApiError } from "../../utils/api";
import { handleApiException, safeFetch } from "../../utils/safeFetch";
import { getSession, stringifySupabaseSession } from "./session";

export const verifyAccountSetup = async () => {
  try {
    const response = await safeFetch(`${API_URL}/verify-account-setup`, {
      method: "POST",
      headers: {
        "x-supabase-auth-token": stringifySupabaseSession(await getSession()),
      },
    });

    const data = (await response.json()) as unknown;

    return Promise.resolve(data);
  } catch (error) {
    return handleApiException(ApiError.FailedToVerifyAccountSetup, error);
  }
};
