import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { ComponentPropsWithRef, ElementRef, SVGProps, forwardRef } from "react";

type CheckboxElement = ElementRef<typeof CheckboxPrimitive.Root>;
type CheckboxProps = ComponentPropsWithRef<typeof CheckboxPrimitive.Root>;

const CheckIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path
        d="M3.3335 8.66663L6.00016 11.3333L12.6668 4.66663"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
);

CheckIcon.displayName = "CheckIcon";

export const Checkbox = forwardRef<CheckboxElement, CheckboxProps>(
  (props, ref) => {
    return (
      <span className="inline-flex h-6 flex-shrink-0 items-center align-top">
        <CheckboxPrimitive.Root
          {...props}
          ref={ref}
          className={`relative inline-flex h-4 w-4 cursor-pointer items-center justify-center rounded-sm border border-solid border-gray-secondary text-white transition-colors focus-within:outline focus-within:outline-2 focus-within:outline-offset-2 focus-within:outline-primary-500 disabled:cursor-not-allowed data-[state='checked']:border-primary-500 data-[state='checked']:bg-primary-500 disabled:data-[state='checked']:border-gray-400 disabled:data-[state='unchecked']:border-gray-400 disabled:data-[state='checked']:bg-gray-100 disabled:data-[state='unchecked']:bg-gray-100 disabled:data-[state='checked']:text-gray-400 disabled:data-[state='unchecked']:text-gray-400 ${
            props.className ?? ""
          }`.trim()}
        >
          <CheckboxPrimitive.CheckboxIndicator
            asChild
            className="absolute flex h-full w-full items-center justify-center"
          >
            <CheckIcon />
          </CheckboxPrimitive.CheckboxIndicator>
        </CheckboxPrimitive.Root>
      </span>
    );
  },
);

Checkbox.displayName = "Checkbox";
