import { z } from "zod";

import { 
  API_THREADS_SERVICE_URL,
  ApiError,
  removeBeginingTagOfString,
  transformGmailLinksToHTML,
  transformSlackLinksToHTML
} from "../../utils/api";
import { replaceEmojiSlugs } from "../../utils/emoji";
import { handleApiException, parsedFetch } from "../../utils/safeFetch";
import {
  INFO_ITEM_FILTER_VIEW_ALL,
  INFO_ITEM_FILTER_VIP_CONTACTS,
  getInfoItemFilter,
  serializeInfoItemFilter,
} from "./filters";
import {
  infoItemListFilterApiSchema,
  infoItemSerializedSchema,
  infoListInfiniteQueryFnParams,
  infoListSerializedSchema,
  itemApiSchema,
  itemListApiSchema,
  Origin
} from "./schema";

export const serializeInfoItemDetailPayload = async (
  data: z.infer<typeof itemApiSchema>,
) => {
  const result = infoItemSerializedSchema.safeParse({
    id: data.id,
    threadId: data.thread_id,
    description: replaceEmojiSlugs(
      transformSlackLinksToHTML(data.content ?? ""),
    ),
    summary: data.origin === Origin.Slack
    ? replaceEmojiSlugs(transformSlackLinksToHTML(data.summary ?? ""))
    : transformGmailLinksToHTML(data.summary ?? ""),
    shortSummary:data.origin === Origin.Slack
    ? replaceEmojiSlugs(transformSlackLinksToHTML(data.short_summary ?? ""))
    : transformGmailLinksToHTML(data.short_summary ?? ""),
    sender: data.sender,
    deadlineAt: data.deadline_at,
    createdAt: data.created_at,
    isUrgent: data.is_urgent,
    isRead: data.is_read,
    permalink: data.permalink,
    origin: data.origin,
    topics: data.topics,
    subjectLine: data.subject_line,
    participants: data.participants,
    infoItems: data.info_items,
  });

  if (!result.success) {
    return handleApiException(ApiError.FailedToSerializeInfoItem, result.error);
  }

  return Promise.resolve(result.data);
};

export const serializeInfoItemThreadsPayload = async (
  data: z.infer<typeof itemListApiSchema>,
) => {
  try {
    return await serializeInfoItemPayload(data);
  } catch (error) {
    return handleApiException(ApiError.FailedToSerializeInfoItemThreads, error);
  }
};

export const serializeInfoItemPayload = async (
  data: z.infer<typeof itemListApiSchema>,
) => {
  const { items, next_cursor } = data;

  const result = infoListSerializedSchema.safeParse({
    list: items.map((item) => ({
      id: item.id,
      threadId: item.thread_id,
      description: removeBeginingTagOfString(replaceEmojiSlugs(
        transformSlackLinksToHTML(item.content ?? ""),
      ).slice(0, 1024)),
      summary: removeBeginingTagOfString(replaceEmojiSlugs(
        transformSlackLinksToHTML(item.summary ?? ""),
      ).slice(0, 1024)),
      shortSummary: removeBeginingTagOfString(replaceEmojiSlugs(
        transformSlackLinksToHTML(item.short_summary ?? ""),
      ).slice(0, 1024)),
      sender: item.sender,
      deadlineAt: item.deadline_at,
      createdAt: item.sent_at,
      isUrgent: item.is_urgent,
      isRead: item.is_read,
      permalink: item.permalink,
      topics: item.topics,
      subjectLine: item.subject_line,
      participants: item.participants,
      infoItems: item.info_items,
      origin: item.origin,
    })),
    nextCursor: next_cursor,
  });

  if (!result.success) {
    return handleApiException(
      ApiError.FailedToSerializeInfoItems,
      result.error,
    );
  }

  return Promise.resolve(result.data);
};

export const getInfoDetails = async (id: string) => {
  try {
    const response = await parsedFetch(
      itemApiSchema,
      `${API_THREADS_SERVICE_URL}/threads/${id}`,
      {
        method: "GET",
      },
    );
    const data = await serializeInfoItemDetailPayload(response);

    return data;
  } catch (error) {
    return handleApiException(ApiError.FailedToFetchInfoItem, error);
  }
};

export const getInfoList = async (
  filter: z.infer<typeof infoItemListFilterApiSchema>,
  nextCursor?: string,
) => {
  try {
    const response = await parsedFetch(
      itemListApiSchema,
      `${API_THREADS_SERVICE_URL}/threads?${serializeInfoItemFilter(
        filter,
        nextCursor,
      )}`,
      {
        method: "GET",
      },
    );

    const data = await serializeInfoItemPayload(response);

    return data;
  } catch (error) {
    return handleApiException(ApiError.FailedToFetchInfoItems, error);
  }
};

export const infoItemKeys = {
  all: ["infoItems"] as const,
  lists: () => [...infoItemKeys.all, "list"] as const,
  list: (filters: z.infer<typeof infoItemListFilterApiSchema>) =>
    [...infoItemKeys.lists(), { filters }] as const,
  details: (id: string) => [...infoItemKeys.all, "detail", id] as const,
  filters: (id: string) => [...infoItemKeys.all, "filters", id] as const,
  threads: (threadId: string) => [...infoItemKeys.all, "threads", threadId] as const,
};

export const infoItemDetailQuery = (id: string) => ({
  queryKey: infoItemKeys.details(id),
  queryFn: async () => {
    const data = await getInfoDetails(id);

    return data;
  },
  staleTime: 10000
});

export const infoItemListQuery = (
  filter: z.infer<typeof infoItemListFilterApiSchema>,
) => ({
  queryKey: filter ? infoItemKeys.list(filter) : infoItemKeys.lists(),
  queryFn: async (params: unknown) => {
    const { pageParam } = infoListInfiniteQueryFnParams.parse(params);

    const data = await getInfoList(
      filter,
      pageParam?.nextCursor && pageParam?.nextCursor !== "initial"
        ? pageParam.nextCursor
        : undefined,
    );

    return data;
  },
  staleTime: 5000
});

type GetNextPageParam = Awaited<
  ReturnType<ReturnType<typeof infoItemListQuery>["queryFn"]>
>;

export const infoItemListInfiniteQuery = (
  filter: z.infer<typeof infoItemListFilterApiSchema>,
) => ({
  ...infoItemListQuery(filter),
  getNextPageParam: (lastPage: GetNextPageParam) => ({
    nextCursor: lastPage.nextCursor,
  }),
  initialPageParam: { nextCursor: "initial" }
});

export const infoItemHotCommsFilterQuery = (
  filters?: Partial<z.infer<typeof infoItemListFilterApiSchema>>,
) => ({
  queryKey: infoItemKeys.filters("hot-communication"),
  queryFn: async () => {
    const data = {
      ...(await getInfoItemFilter()),
      ...(filters ?? {}),
    };

    return infoItemListFilterApiSchema.parse(data);
  },
  staleTime: 5000, // Because we fetch from localStorage it is unclear when it updates
});

export const infoItemViewAllFilterQuery = () => ({
  queryKey: infoItemKeys.filters("view-all"),
  queryFn:  () => {
    const data = {
      ...INFO_ITEM_FILTER_VIEW_ALL
    };
    return infoItemListFilterApiSchema.parse(data);
  },
  staleTime: 5000,
});

export const infoItemVipContactsFilterQuery = () => ({
  queryKey: infoItemKeys.filters("vip-comms"),
  queryFn: () => {
    const data = {
      ...INFO_ITEM_FILTER_VIP_CONTACTS,
    };
    return infoItemListFilterApiSchema.parse(data);
  },
  staleTime: 5000,
});

export const customPromptsFilterQuery = (custom_prompt_id: string) => ({
  queryKey: infoItemKeys.filters("custom-prompts"),
  queryFn: () => {
    const data = { is_read: false, order_by: "sent_at", custom_prompt_id: custom_prompt_id }
    return infoItemListFilterApiSchema.parse(data);
  },
  staleTime: 5000,
});