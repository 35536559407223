import { QueryClient } from "@tanstack/react-query";
import { Suspense, useState } from "react";
import {
  ActionFunction,
  Await,
  Form,
  Link,
  LoaderFunction,
  Params,
  redirect,
  useLoaderData
} from "react-router-dom";

import { PageLoader } from "../../components/PageLoader";
import { Button } from '../../components/button';
import { ArrowLeftIcon } from "../../components/icons";
import { Paper } from "../../components/paper";
import { createCustomPromptMutation, getCustomPromptsQuery, updateCustomPromptMutation } from "../../services/magic-prompts/prompts";

export type LoaderData = Awaited<ReturnType<typeof getLoaderData>>;

// eslint-disable-next-line react-refresh/only-export-components
export const getLoaderData = async (
  queryClient: QueryClient,
  params: Params
  ) => {
  const { id } = params;

  if(!id) {
    return Promise.resolve({
      prompt: null
    })
  }
  
  return Promise.resolve({
    prompt: queryClient.fetchQuery({
      ...getCustomPromptsQuery(id),
    }),
  });
};

// eslint-disable-next-line react-refresh/only-export-components
  export const loader = (queryClient: QueryClient) =>
    (async ({params}: {params: Params}) => {
      if(params.id) {
        const data = await queryClient.ensureQueryData({
          ...getCustomPromptsQuery(params.id),
        });
        return data;
      }
      return null;
    }) satisfies LoaderFunction;

// eslint-disable-next-line react-refresh/only-export-components
export const action = () =>
  (async ({ request }) => {
    // Parse the form and retrieve the "magic-prompt" textarea value
    const formData = Object.fromEntries(await request.formData());
    const textareaValue = formData["magic-prompt"] as string;
    const existingCustomPromptId = formData["custom-prompt-id"] as string;

    try {
      const { mutationFn : updateMutation } = updateCustomPromptMutation();
      const { mutationFn: createMutation } = createCustomPromptMutation();
      
      const result = existingCustomPromptId ? 
        await updateMutation(existingCustomPromptId, textareaValue) :
        await createMutation(textareaValue);
      
      const custom_prompt_id = result.id;

      if(custom_prompt_id) {
        const res = await fetch("/magic-comms-personalization/" + custom_prompt_id);
        if (res.ok) {
          return redirect("/?promptSubmitted=true");  
        }
      }

      return { custom_prompt_id };
    } catch (error) {
      return { error: "Failed to create custom prompt, please try again later." };
    }
  }) satisfies ActionFunction;

export const MagicCommsPersonalization = () => {
  const data = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>;

  const [validForm, setValidForm] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState("placeholder");

  if(data?.id && data.custom_prompt !== currentPrompt) {
    setValidForm(false);
    setCurrentPrompt(data?.custom_prompt ?? "placeholder2");
  }
  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const currentValue = e.target.value;
    const isValid = currentValue.length > 0 && currentValue !== data?.custom_prompt;
    setValidForm(isValid)
  }

  return (
    <main className="sm:mt-10 grid h-full w-full grid-flow-row auto-rows-min items-center justify-center overflow-y-auto">
      <Paper as="section" className="p-4 sm:p-16 grow flex-col max-w-screen-lg">
        <h1 className="flex items-center gap-3 text-lg sm:text-2xl text-gray-primary">
          <Link to="/">
            <ArrowLeftIcon className="h-6 w-6" />
          </Link>
          Personalize 🔮 Magic Comms
        </h1>

        <div className="flex flex-col gap-6 max-w-[878px] text-gray-primary text-base mt-4">
          <section className="flex flex-col">
            <p>
              Your magic prompt will be applied to all your incoming communication to identify messages/communication of specific interest.
            </p>
          </section>

          <Suspense fallback={<PageLoader />}>
            <Await resolve={data}>
              {(prompt: Awaited<LoaderData["prompt"]>) => {
                return (
                  <Form method="POST" id="custom-magic-prompt">
                    <div className="grid text-gray-secondary">
                      <div className="flex items-baseline justify-between">
                        <div className="text-md leading-[35px] font-medium">
                          Return a positive value:
                        </div>
                      </div>
                      <div className="h-[220px] sm:h-[110px]">
                        <input name="custom-prompt-id" readOnly hidden value={prompt?.id} />
                        <textarea
                          name="magic-prompt"
                          className=" focus:border-primary-default border-2 border-transparent p-4 h-full w-full bg-gray-50 inline-flex appearance-none items-center justify-center rounded-[2px] leading-none outline-none resize-none placeholder-gray-300 text-gray-primary"
                          placeholder="Type your condition here. eg, if any communication or message contains reference to shipment delays."
                          defaultValue={prompt?.custom_prompt}
                          onChange={handleTextAreaChange}
                        />
                        </div>
                    </div>
                  </Form>
                );
              }}
            </Await>
          </Suspense>

          <section className="flex flex-col">
            <p className="font-medium text-base text-gray-secondary">
              Else, return a negative value.
            </p>
          </section>

          <section className="mt-4 block sm:hidden">
            <Button
              as="button"
              variant="primary"
              className="w-full self-end"
              disabled={!validForm}
              form="custom-magic-prompt"
              type="submit"
            >
              Save Changes
            </Button>
          </section>

          <section className="text-gray-primary text-base font-medium sm:mt-6">
            How to Use:
            <ul className="list-disc pl-4 text-sm mt-2 marker:text-gray-secondary gap-2 flex flex-col">
              <li>A simple magic prompt can be defined as text input. This input must be a simple condition for which, if true, you will be notified/highlighted.<br /><br />
              eg. Return a positive value &apos;if any of the communication mentions a delay in the warehouse X42&apos;.</li>
              <li>No highlight/notification will be given for a false condition.</li>
              <li>Everything that matches your magic prompt will be highlighted in your workstream.</li>
              <li>You can enable separate notifications for any communication that matches your magic prompt.</li>
              <li>Your magic prompt will only be applied to your future communication, not to your existing messages.</li>
            </ul>
          </section>

          <section className="items-end justify-end text-right self-end mt-28 hidden sm:block">
            <Button
              as="button"
              variant="primary"
              className="w-32 self-end"
              disabled={!validForm}
              form="custom-magic-prompt"
              type="submit"
            >
              Save Changes
            </Button>
          </section>
        </div>
      </Paper>
    </main>
  );
};