import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { ReactNode } from "react";

export type DropdownProps = {
  trigger: ReactNode;
  items: ReactNode[];
  align?: DropdownMenu.DropdownMenuContentProps["align"];
  sideOffset?: DropdownMenu.DropdownMenuContentProps["sideOffset"];
  onSelect?: DropdownMenu.DropdownMenuItemProps["onSelect"];
} & DropdownMenu.DropdownMenuProps;

export const Dropdown = ({
  trigger,
  items,
  align,
  sideOffset,
  onSelect,
}: DropdownProps) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="outline-none focus:outline-none">
        {trigger}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className="dropdown-transition z-50 min-w-[220px] rounded bg-white p-4 shadow-regular"
        align={align}
        sideOffset={sideOffset}
      >
        <DropdownMenu.Arrow className="fill-white" />

        <div className="space-y-4">
          {items.map((item, index) => (
            <DropdownMenu.Item
              key={index}
              asChild
              className="outline-none hover:outline-none focus:outline-none"
              onSelect={onSelect}
            >
              {item}
            </DropdownMenu.Item>
          ))}
        </div>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
