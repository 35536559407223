import {
  REALTIME_SUBSCRIBE_STATES,
  RealtimePostgresInsertPayload,
  RealtimePostgresUpdatePayload,
} from "@supabase/supabase-js";
import { QueryClient, RefetchOptions, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useFetcher } from "react-router-dom";
import { z } from "zod";

import { authQuery } from "../auth/queries";
import { supabaseClient } from "../auth/session";
import { captureException, captureMessage } from "../monitoring";
import { itemTableSerializedSchema } from "./schema";

export const WS_CHANNEL = "info_item_insert";
export const CUSTOM_PROMPTS_CHANNEL = "custom_prompts_enhancement_insert";
export const CUSTOM_PROMPTS_TABLE_NAME = "custom_prompts_enhancement";
export const THREADS_TABLE_NAME = "threads";
export const THREADS_CHANNEL = "threads_update";

let retries = 0;

interface RealtimePayload {
  new?: {
    participants?: string[];
  };
}

function onPGSQLChange(
  onUpdate: (item: z.infer<typeof itemTableSerializedSchema>) => void,
) {
  return function innerOnPGSQLChange(
    payload:
      | RealtimePostgresInsertPayload<NonNullable<unknown>>
      | RealtimePostgresUpdatePayload<NonNullable<unknown>>,
  ) {
    const transformDataForValidation = (data: RealtimePayload["new"]) => {
      return {
        ...data,
        participants: data?.participants ?? [],
      };
    };
    if (!payload.new) return;

    const transformedData = transformDataForValidation(payload.new);
    const result = itemTableSerializedSchema.safeParse(transformedData);
    if (!result.success) {
      captureException(result.error);
      return;
    }

    onUpdate(result.data);
  };
}

const createWebsocket = (
  userId: string,
  onUpdate: (item: z.infer<typeof itemTableSerializedSchema>) => void,
  channel: string,
  tableName: string,
) => {
  try {
    const tableOptions = {
      schema: "public",
      table: tableName,
      filter: `user_id=eq.${userId}`,
    };
    return supabaseClient
      .channel(channel)
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          ...tableOptions,
        },
        onPGSQLChange(onUpdate),
      )
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          ...tableOptions,
        },
        onPGSQLChange(onUpdate),
      )
      .subscribe((status) => {
        if (
          status === REALTIME_SUBSCRIBE_STATES.TIMED_OUT ||
          status === REALTIME_SUBSCRIBE_STATES.CHANNEL_ERROR
        ) {
          retries += 1;

          if (retries > 5) {
            captureMessage("Retrying threads subscription failed", {
              level: "info",
              extra: {
                retries: retries,
                status: status,
                tableOptions: tableOptions,
                wsChannel: channel,
              },
            });
            return;
          }

          setTimeout(() => {
            createWebsocket(userId, onUpdate, channel, tableName);
          }, 2000);
        }
      });
  } catch (error) {
    captureException(error);
  }
};

export enum CONNECTION_STATE {
  Connecting = "connecting",
  Open = "open",
  Closing = "closing",
  Closed = "closed",
}

export const useInfoListSubscriptionConnectionStatus = () => {
  const websocket = supabaseClient.channel(WS_CHANNEL).socket;
  const [connectionState, setConnectionStaet] = useState<CONNECTION_STATE>();

  useEffect(() => {
    const interval = setInterval(() => {
      setConnectionStaet(websocket.connectionState());
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [websocket]);

  return connectionState;
};

export const useInfoListSubscriptionWithNotification = () => {
  const fetcher = useFetcher({ key: "send-notification" });
  const { queryKey, queryFn } = authQuery();
  const { data: session } = useQuery({ queryKey, queryFn });

  useEffect(() => {
    if (!session?.user?.id) return;
    const websocket = createWebsocket(
      session.user.id,
      (item) => {
        fetcher.submit(
          { intent: "notify", id: item.id },
          { method: "post", action: "/notification" },
        );
      },
      WS_CHANNEL,
      THREADS_TABLE_NAME,
    );

    return () => {
      void websocket?.unsubscribe();
    };
  }, [session?.user?.id, fetcher]);
};

export const useCustomPromptEnhancementsSubscriptionWithNotification = (
  queryClient: QueryClient,
  customPromptId: string,
  filters: object,
) => {
  const fetcher = useFetcher({ key: "send-notification" });
  const { queryKey, queryFn } = authQuery();
  const { data: session } = useQuery({ queryKey, queryFn });

  useEffect(() => {
    if (!session?.user?.id) return;

    const websocket = createWebsocket(
      session.user.id,
      (item) => {
        fetcher.submit(
          { intent: "notify", thread_id: item.thread_id },
          { method: "post", action: "/notification" },
        );
        void queryClient.refetchQueries(filters, [
          "customPrompts",
          customPromptId,
        ] as RefetchOptions);
      },
      CUSTOM_PROMPTS_CHANNEL,
      CUSTOM_PROMPTS_TABLE_NAME,
    );

    return () => {
      void websocket?.unsubscribe();
    };
  }, [filters, fetcher, session?.user?.id, customPromptId, queryClient]);
};
