import * as Popover from '@radix-ui/react-popover';
import { CloseIcon } from '../icons';
import { useEffect, useState } from 'react';


export const TextOverflow = ({
  items,
  maxLengthSmall,
  maxLengthLarge
}: {
  items: string[];
  maxLengthSmall: number;
  maxLengthLarge: number;
}) => {
  const [open, setOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    // Hacking around the lack of capabilities of the redux component to eventually set an alignOffset
    const handleResize = () => {
      const isDesktop = window.matchMedia('(min-width: 768px)').matches;
      setIsDesktop(isDesktop);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if(!items) {
    return
  }

  const maxCharacters = isDesktop ? maxLengthLarge : maxLengthSmall;
  const displayItemsList = items.slice().reduce((acc, email, _index, src) => {
    const separator = acc ? ', ' : '';
    if (acc.length + separator.length + email.length > maxCharacters) {
      src.splice(1);
      return acc;
    }
    return acc + separator + email;
  }, '');
  const hiddenItemsCount = items.length - displayItemsList.split(', ').length;
  const hiddenItems = items.slice(displayItemsList.split(', ').length).join(', ');


  return (
    <div className="flex">
      <div className="justify-start">
        {displayItemsList}
      </div>
      <div className="items-end justify-end">
        <Popover.Root open={open} onOpenChange={setOpen}
        >
        <Popover.Trigger asChild>
          {hiddenItemsCount > 0 && <div>
            <span
              className="ml-1 cursor-pointer items-end text-blue-300"
              onClick={(event) => {
                event.preventDefault();
                setOpen(true);
              }}
            >
              +{hiddenItemsCount}
              </span>
          </div>
          }
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="flex bg-gray-primary mr-2 sm:mr-0 rounded py-3 px-4 pr-8 max-w-[300px] text-sm text-gray-300"
            align={isDesktop ? 'start' : 'center'}
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            <div>
              {hiddenItems}
            </div>
            <Popover.Close
              className="rounded-full w-5 h-5 inline-flex items-center justify-center absolute top-[5px] right-[12px] sm:right-[5px] outline-none cursor-pointer text-white"
              onClick={(event) => {
                event.preventDefault();
                setOpen(false);
              }}
              >
                <CloseIcon />
            </Popover.Close>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      </div>
    </div>
  )
};
