import { SVGProps } from "react";

export const ArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg 
    viewBox="0 0 12 8" 
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path 
      d="M1 6.5L6 1.5L11 6.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
