import { Link } from "react-router-dom";
import { useProfileQuery } from "../../services/profile/query";
import { Dropdown } from "../dropdown";
import {
  ChevronDown,
  CloseIcon,
  LogoutIcon,
} from "../icons";
import { Sheet } from "../sheet/Sheet";
import Avatar from "./Avatar";
import { Logo } from "./Logo";
import NavLink, { NavLinkVariant } from "./NavLink";
import { Close as CloseTrigger } from "@radix-ui/react-dialog";
import HeaderNavigation from "./headerNavigation/headerNavigation";
import { SyncIcon } from "../icons/Sync";
import { IntegrationsIcon } from "../icons/Integrations";


const integrationsNavigation = {
  label: "Integrations",
  href: "/integrations",
  Icon: IntegrationsIcon,
  isMenuItem: true,
};

const syncSettings = {
  label: "Sync Settings",
  href: "/sync-settings",
  Icon: SyncIcon,
  isMenuItem: true,
};

const logoutNavigation = {
  label: "Logout",
  href: "/logout",
  Icon: LogoutIcon,
  variant: NavLinkVariant.Danger,
  isMenuItem: true,
};

const navigation = [
  integrationsNavigation,
  syncSettings,
  logoutNavigation,
];

const MenuTrigger = ({
  fullName,
  avatarUrl,
}: {
  fullName?: string | null;
  avatarUrl?: string | null;
}) => (
  <div className="flex items-center gap-2">
    <Avatar src={avatarUrl} initials={fullName?.slice(0, 1)} />
    <span className="hidden text-base font-medium sm:block">
      {fullName ?? "User"}
    </span>
    <ChevronDown className="hidden h-6 w-6 sm:block" />
  </div>
);

const HomeLogo = () => (
  <Link to="/" className="relative justify-self-start lg:w-auto lg:border-none">
    <Logo />
  </Link>
);

export const Header = ({ className }: { className?: string }) => {
  const { data: profile } = useProfileQuery();

  return (
    <header
      className={`sticky top-[-1px] z-40 mt-[1px] w-full border-b border-solid border-b-transparent bg-main transition-colors ${
        className ?? ""
      }`.trim()}
    >
      <div className="mx-auto max-w-screen-3xl px-6 py-2 lg:py-0">
        <div className="grid grid-cols-[1fr,3fr,1fr] items-center lg:h-16 lg:grid-cols-3">
          <HomeLogo />
          <HeaderNavigation />
          <div className="hidden shrink-0 justify-self-end lg:block">
            <div className="ml-4 flex items-center lg:ml-6">
              <Dropdown
                trigger={
                  <MenuTrigger
                    fullName={profile?.full_name}
                    avatarUrl={profile?.avatar_url}
                  />
                }
                align="end"
                sideOffset={12}
                items={navigation
                  .filter((item) => item.isMenuItem === true)
                  .map((item) => (
                    <NavLink
                      className={"text-gray-primary"}
                      key={item.label}
                      {...item}
                    />
                  ))}
              />
            </div>
          </div>
          <div className="flex justify-self-end lg:hidden">
            <Sheet
              fullScreen={true}
              side="right"
              trigger={
                <MenuTrigger
                  fullName={profile?.full_name}
                  avatarUrl={profile?.avatar_url}
                />
              }
              content={
                <div className="flex h-full w-full flex-col gap-6 bg-main">
                  <div className="flex items-center justify-between px-6 py-2">
                    <Logo className="-ml-4 h-[48px] w-[48px]" />
                    <CloseTrigger>
                      <CloseIcon className="h-8 w-8 outline-none focus:outline-none" />
                    </CloseTrigger>
                  </div>
                  <nav className="px-6">
                    <ol className="space-y-6">
                      {navigation
                        .filter((item) => item.isMenuItem === true)
                        .map((item) => (
                          <li key={item.label} className="py-2 text-white">
                            <NavLink
                              {...item}
                              label={<CloseTrigger>{item.label}</CloseTrigger>}
                            />
                          </li>
                        ))}
                    </ol>
                  </nav>
                  <nav className="mt-auto px-6 pb-12">
                    <NavLink {...logoutNavigation} />
                  </nav>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </header>
  );
};

