import { z } from "zod";
import { customPromptsApiSchema } from "../magic-prompts/prompts";

export enum Deadline {
  OverdueAndToday = "overdue_and_today",
  Tomorrow = "tomorrow",
  In2Days = "in_2_days",
  In7Days = "in_7_days",
  In30Days = "in_30_days",
}

export enum Origin {
  Gmail = "gmail",
  Slack = "slack",
}

export const participantsSchema = z.array(
  z.object({
    user: z.string(),
    last_activity: z.string().datetime({ offset: true }),
  })
);

export const infoItemsSchema = z.array(
  z.object({
    sender: z.string().optional().nullable(),
    content: z.string().optional().nullable(),
    sent_at: z.string().datetime({ offset: true }),
  })
).optional().nullable();

export const infoItemSerializedSchema = z.object({
  id: z.string(),
  threadId: z.string(),
  content: z.string().optional().nullable(),
  sender: z.string().optional().nullable(),
  createdAt: z.string().datetime({ offset: true }),
  deadlineAt: z.string().datetime({ offset: true }).optional().nullable(),
  isRead: z.boolean().optional().nullable(),
  isUrgent: z.boolean().optional().nullable(),
  permalink: z.string().optional().nullable(),
  origin: z.enum([Origin.Gmail, Origin.Slack]).nullable().optional(),
  topics: z.array(z.string()).optional().nullable(),
  description: z.string().optional().nullable(),
  summary: z.string().optional().nullable(),
  shortSummary: z.string().optional().nullable(),
  subjectLine: z.string().optional().nullable(),
  participants: participantsSchema,
  infoItems: infoItemsSchema
});

export const infoListSerializedSchema = z.object({
  list: z.array(
    infoItemSerializedSchema.pick({
      id: true,
      content: true,
      description: true,
      summary: true,
      shortSummary: true,
      sender: true,
      createdAt: true,
      deadlineAt: true,
      isRead: true,
      isUrgent: true,
      topics: true,
      permalink: true,
      origin: true,
      threadId: true,
      subjectLine: true,
      participants: true,
      infoItems: true,
    }),
  ),
  nextCursor: z.string().optional().nullable(),
});


export const threadsListSeriazliedSchema = z.object({
  list: z.array(
    infoItemSerializedSchema.pick({
      id: true,
      content: true,
      description: true,
      summary: true,
      shortSummary: true,
      sender: true,
      createdAt: true,
      deadlineAt: true,
      isRead: true,
      isUrgent: true,
      topics: true,
      permalink: true,
      origin: true,
      threadId: true,
      subjectLine: true,
      participants: true,
      infoItems: true,
    }),
  ),
  nextCursor: z.string().optional().nullable(),
});


export const infoListInfiniteQueryFnParams = z.object({
  pageParam: z.object({
    nextCursor: z.string().optional().nullable(),
  }),
});

export const infoListInfiniteSerializedSchema = z.object({
  pages: z.array(infoListSerializedSchema),
  pageParams: z
    .array(
      z.object({
        nextCursor: z.string().optional().nullable(),
      }),
    )
    .optional()
    .nullable(),
});

export const itemTableSerializedSchema = z.object({
  id: z.string(),
  thread_id: z.string(),
  content: z.string().nullable().optional(),
  summary: z.string().nullable().optional(),
  short_summary: z.string().nullable().optional(),
  sender: z.string().nullable().optional(),
  created_at: z.string().datetime({ offset: true }).nullable().optional(),
  updated_at: z.string().datetime({ offset: true }).nullable().optional(),
  sent_at: z.string().datetime({ offset: true }).nullable().optional(),
  deadline_at: z.string().datetime({ offset: true }).nullable().optional(),
  is_urgent: z.boolean().nullable().optional(),
  is_read: z.boolean().nullable().optional(),
  permalink: z.string().nullable().optional(),
  origin: z.enum([Origin.Gmail, Origin.Slack]).nullable().optional(),
  subject_line: z.string().nullable().optional(),
  participants: participantsSchema,
  info_items: infoItemsSchema
});

export const customPromptEnhancementSchema = z.object({
  id: z.string(),
  thread_id: z.string(),
  thread_pk_id: z.string(),
  user_id: z.string(),
  custom_prompt_id: z.string(),
  is_active: z.boolean(),
  is_read: z.boolean(),
});

export const customPromptEnhancementListSchema = z.array(customPromptEnhancementSchema);

export const itemApiSchema = itemTableSerializedSchema.merge(
  z.object({
    topics: z.array(z.string()).nullable().optional()
  }),
);

export const itemListApiSchema = z.object({
  items: z.array(itemApiSchema),
  next_cursor: z.string().nullable().optional(),
});

export const infoItemListFilterApiSchema = z.object({
  subjects: z
    .array(
      z.enum([
        "major_software_issues",
        "out_of_stock",
        "harassment",
        "accidents",
        "customer_complains",
        "major_operations_issues",
      ]),
    )
    .nullable()
    .optional(),
  hide_read: z.boolean().nullable().optional(),
  hide_self_communication: z.boolean().nullable().optional(),
  custom_prompt_id: z.string().nullable().optional(),
  urgent_comms: z.boolean().nullable().optional(),
  deadline_at: z
    .enum([
      Deadline.OverdueAndToday,
      Deadline.Tomorrow,
      Deadline.In2Days,
      Deadline.In7Days,
      Deadline.In30Days,
    ])
    .nullable()
    .optional(),
  origin: z.enum([Origin.Gmail, Origin.Slack]).nullable().optional(),
  vip_contacts: z.boolean().nullable().optional(),
  is_read: z.boolean().nullable().optional(),
  order_by: z.string().nullable().optional(),
});


export const customPromptApiSchema = z.object({
  id: z.string().uuid(),
  user_id: z.string().uuid(),
  custom_prompt: z.string(),
  is_active: z.boolean()
});

// Define a TypeScript type for custom prompts by inferring from the Zod schema
export type CustomPrompt = z.infer<typeof customPromptApiSchema>;

// Similarly, define a type for an array of custom prompts
export type CustomPrompts = z.infer<typeof customPromptsApiSchema>;