import { API_BACKEND_URL, ApiError } from "../../../utils/api";
import { handleApiException, parsedFetch } from "../../../utils/safeFetch";
import { getSession } from "../../auth/session";
import { integrationsKeys } from "../query";
import { gmailSettingsApiSchema } from "./schema";

const getGmailSettings = async () => {
  const session = await getSession();

  try {
    const response = await parsedFetch(
      gmailSettingsApiSchema,
      `${API_BACKEND_URL}/users/${session.user.id}/settings/gmail`,
      {
        method: "GET",
      },
    );

    return response;
  } catch (error) {
    return handleApiException(ApiError.FailedToFetchGmailSettings, error);
  }
};

export const gmailQuery = () => ({
  queryKey: integrationsKeys.gmail(),
  queryFn: async () => {
    const settings = await getGmailSettings();

    return settings;
  },
});
