import { useCallback } from 'react';
import { AnimatePresence, Reorder } from 'framer-motion';
import { useFetcher } from 'react-router-dom';

import { InfoItem, InfoItemProps } from './InfoItem';

interface Props {
  items: Omit<InfoItemProps, 'onDismiss'>[];
}

export const InfoList = ({ items }: Props) => {
  const fetcher = useFetcher();

  const handleDismiss = useCallback((item: Omit<InfoItemProps, 'onDismiss'>) => () => {
    try {
      fetcher.submit(
        { ids: [item.id], intent: 'dismiss', source: 'single' },
        { method: 'post', action: '/info', encType: 'application/json' }
      );
    
    } catch (error) {
      console.error('Error dismissing item:', error);
    }
  }, [fetcher]); 

  return (
    <>
      <Reorder.Group onReorder={() => void 0} values={items} className="w-full space-y-3 mt-1 pb-3">
        <AnimatePresence initial={false}>
          {items.map((item) => (
            <Reorder.Item
              key={item.id}
              value={item}
              initial={{ opacity: 0, x: -30 }}
              animate={{
                opacity: 1,
                x: 0,
                transition: { duration: 0.15 },
              }}
              exit={{ opacity: 0, x: -30, transition: { duration: 0.15 } }}
              dragListener={false}
            >
              <InfoItem {...item} onDismiss={handleDismiss(item)} />
            </Reorder.Item>
          ))}
        </AnimatePresence>
      </Reorder.Group>
    </>
  );
};
