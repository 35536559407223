import { ReactNode } from "react";

export enum TagFill {
  LIGHT = "light",
  DARK = "dark",
}

export enum TagType {
  NEUTRAL = "NEUTRAL",
  WARNING = "WARNING",
  ALERT = "ALERT",
  SUCCESS = "SUCCESS",
  DANGER = "DANGER",
  INFO = "INFO",
  LIGHT = "LIGHT"
}

export interface TagProps {
  fill: TagFill;
  type: TagType;
  children: ReactNode;
}
