import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <main className="flex h-full min-h-screen w-full flex-col items-center justify-center">
      <section className="mx-4 flex h-full w-full max-w-screen-2xl flex-col items-center justify-center gap-4 text-3xl ">
        <h1 className="text-center font-bold ">Oops!</h1>
        <p className="px-6 text-gray-primary">
          Sorry, looks like the page doesn&apos;t exist.
          <br />
          <Link to=".." className=" text-white underline underline-offset-4">
            Go Back?
          </Link>
        </p>
      </section>
    </main>
  );
};
