import { QueryClient } from "@tanstack/react-query";
import { vipContactsQuery } from "../services/vip-comms/queries";

export type LoaderData = Awaited<ReturnType<typeof getLoaderData>>;

export const getLoaderData = async (queryClient: QueryClient) => {
  try {
    const contacts = await queryClient.fetchQuery({
      ...vipContactsQuery(),
    });
    return { contacts };
  } catch (error) {
    console.error('Error fetching contacts:', error);
    return { contacts: undefined };
  }
};