import { z } from 'zod';
import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Form, Link, useLoaderData } from "react-router-dom";
import { PageLoader } from "../../components/PageLoader";
import { CloseIcon, SpinnerIcon } from "../../components/icons";
import { Paper } from "../../components/paper";
import { TagListFull } from "../../components/tag/TagList";
import { TagFill, TagType } from "../../components/tag";
import { LoaderData } from "../../utils/vipContactsUtils";
import { Button } from "../../components/button";
import { Toast } from '../../components/toast';
import { ApiToastManager } from './vipCommsToast';



/**
 * Function to compare two arrays
 *
 * @param {string[]} array1 - The first array to compare
 * @param {string[]} array2 - The second array to compare
 * @return {boolean} Whether the two arrays are equal
 */
export const VipCommsPersonalization: React.FC = () => {
  const data = useLoaderData() as LoaderData | undefined;
  const initialContacts = useMemo(() => {
    return data?.contacts ?? [];
  }, [data?.contacts]);
  const [contacts, setContacts] = useState<string[]>(initialContacts);
  const inputAreaRef = useRef<HTMLSpanElement | null>(null);
  const tagContainerRef = useRef<HTMLDivElement | null>(null);
  const [validForm, setValidForm] = useState(false);
  const [isDuplicateTag, setIsDuplicateTag] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [updateSuccessful, setUpdateSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

   // Function to compare two arrays
   const arraysAreEqual = (array1: string[], array2: string[]) => {
    return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
  };

  useEffect(() => {
    // Capture the current value of the ref in a local variable
    const currentInput = inputAreaRef.current;
  
    const changeInputColor = (color: string) => {
      if (currentInput) {
        currentInput.style.color = color;
      }
    };
  
    if (isDuplicateTag) {
      changeInputColor('red');
    }
  
   
    const handleInputStart = () => {
      setIsDuplicateTag(false); 
      changeInputColor('');
    };
  
    currentInput?.addEventListener('input', handleInputStart);
  
    return () => {
      currentInput?.removeEventListener('input', handleInputStart);
      changeInputColor('');
    };
  }, [isDuplicateTag]);

  useEffect(() => {
    // Update validForm based on whether current and initial contacts are equal
    setValidForm(!arraysAreEqual(contacts, initialContacts));
    setFeedbackMessage('');
  }, [contacts, initialContacts]);

  const addNewTag = useCallback((newTag: string) => {
    setUpdateSuccessful(false);
    const emailSchema = z.string().email();
    const isValidEmail = (tag: unknown) => emailSchema.safeParse(tag).success;
    if (!newTag) return; // Exit if the new tag is empty
  
    const currentInput = inputAreaRef.current;
    if (!currentInput) return;
  
    if (contacts.length >= 10) {
      setFeedbackMessage('Oops! Contact limit of 10 reached.');
      setShowToast(true);
      currentInput.style.color = 'red';
      return;
    }
  
    if (contacts.includes(newTag)) {
      setFeedbackMessage('Oops! This contact is already added.');
      setShowToast(true);
      currentInput.style.color = 'red';
      return;
    }
  
    if (!isValidEmail(newTag)) {
      setFeedbackMessage('Oops! That email doesn\'t look right.');
      setShowToast(true);
      currentInput.style.color = 'red';
      return;
    }
  
    setContacts(prev => [...prev, newTag]);
    currentInput.innerText = ''; // Clear the input field
    setFeedbackMessage('');
    currentInput.style.color = '';
  }, [contacts]);
  
  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (tagContainerRef.current && !tagContainerRef.current.contains(e.target as Node)) {
        const newTag = inputAreaRef.current?.innerText.trim().toLowerCase() || '';
        if (newTag && !contacts.includes(newTag)) {
          addNewTag(newTag);
        } else {
          // Only set validForm to true if there are changes
          setValidForm(!arraysAreEqual(contacts, initialContacts));
        }
      }
    };
  
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [contacts, initialContacts, addNewTag]);
  
  const handleClick = () => {
    setIsLoading(true);
  };

  const handleInput = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key !== 'Enter') return;
  
    event.preventDefault(); // Prevent default 'Enter' behavior
    const newTag = inputAreaRef.current?.innerText.trim().toLowerCase() || '';
    addNewTag(newTag);
  };

  const focusInputArea = () => {
    inputAreaRef.current?.focus();
  };

  return (
    <main className="sm:mt-10 grid grid-flow-row auto-rows-min items-center justify-center overflow-y-auto">
      <Paper as="section" className="p-4 sm:p-4 grow flex-col max-w-screen-lg">
      <ApiToastManager setUpdateSuccessful={setUpdateSuccessful} setIsLoading={setIsLoading} />
        <h1 className="flex justify-between text-lg sm:text-2xl ml-3 text-black font-bold">
          Personalize your Following Feed
          <Link to="/following">
            <CloseIcon className="w-7 cursor-pointer"/> 
          </Link>
          
        </h1>

        <div className="flex flex-col gap-3 max-w-[878px] mt-2 ml-3 mr-3 flex flex-col">
          <section className="flex flex-col text-black">
            <p>We have analyzed your email history to identify your most important contacts.</p>
            <p>Feel free to adjust them for yours needs.</p>
          </section>
          {/* Display the feedback message */}
            {feedbackMessage && (
              <Toast
              open={showToast}
              onOpenChange={() => setShowToast(false)}
              type="error"
              variant="archive"
              message={feedbackMessage}
              duration={7000}
            />
            )}
          <Suspense fallback={<PageLoader />}>
          
            <div 
              ref={tagContainerRef}
              className={`focus:border-primary-default rounded-lg border-2 border-transparent p-4 h-[220px] sm:h-auto w-full bg-gray-50 appearance-none rounded-[2px] leading-none outline-none resize-none placeholder-gray-300 relative centered-container ${isDuplicateTag ? 'text-red' : 'text-gray-primary'}`}
              style={{ minHeight: '100px' }} 
              onClick={focusInputArea}
            >
              {isLoading && <SpinnerIcon className="h-12 w-12 text-primary-default spinner centered-spinner" />}
              <TagListFull
                tags={contacts.map((contact, index) => ({
                  type: TagType.LIGHT,
                  fill: TagFill.LIGHT,
                  children: (
                    <div className="flex items-center !text-black" key={index}>
                      {contact}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setContacts(contacts.filter(c => c !== contact));
                          setUpdateSuccessful(false);
                        }}
                        className="ml-2"
                      >
                        x
                      </button>
                    </div>
                  ),
                }))}
              />
              <span
                ref={inputAreaRef}
                contentEditable
                className="input-area"
                onKeyDown={handleInput}
                style={{ outline: "none", minWidth: "1em" }}
              ></span>
            </div>
            <Form method="post" id="vip-contacts-form" onSubmit={handleClick}>
              <input type="hidden" name="contacts" value={JSON.stringify(contacts)} />
              <section className="flex justify-end mt-3">
              <Button
                  as="button"
                  className="sm:!w-32 !w-full rounded-full border-slate-400"
                  variant="outline"
                  disabled={!validForm || updateSuccessful || isLoading}
                  form="vip-contacts-form"
                  type="submit"
                >
                  Save Changes
              </Button>
            </section>
            </Form>
          </Suspense>
        </div>
      </Paper>
    </main>
  );
};
