import { verifyAccountSetup } from "./account";
import { authGoogleApi } from "./google";
import { authenticate } from "./session";

export const authQuery = () => ({
  queryKey: ["auth"],
  queryFn: async () => {
    const session = await authenticate();

    return Promise.all([authGoogleApi(), verifyAccountSetup()]).then(
      () => session,
    );
  },
});
