import { LoaderFunction, redirect, useLoaderData } from "react-router-dom";

import { Toast } from "../components/toast";
import { getSession, login } from "../services/auth/session";
import { captureException } from "../services/monitoring";
import { Auth } from "./auth/Auth";

export type AuthLoaderData = Extract<
  Awaited<ReturnType<typeof loader>>,
  { authType: string }
>;

// eslint-disable-next-line react-refresh/only-export-components
export const loader = (async ({ request }) => {
  try {
    const session = await getSession();

    if (session) {
      return redirect("/");
    }
    // We want soft catch
    // eslint-disable-next-line no-empty
  } catch (e) {}

  const authLastLogin = localStorage.getItem("LAST_LOGIN_EMAIL");
  const { pathname, searchParams } = new URL(request.url);
  const authError = searchParams.get("error");
  const authType = pathname.includes("/login") ? "select_account" : "consent";
  let authUrl;

  try {
    const data = await login({ prompt: authType });

    if (data.redirectTo) {
      authUrl = data.redirectTo;
    }
  } catch (error) {
    console.error(error);
    captureException(error);

    return {
      authUrl: null,
      authType,
      authLastLogin,
      error: (error as Error).message,
    };
  }

  return {
    authUrl,
    authType,
    authLastLogin,
    error: authError,
  };
}) satisfies LoaderFunction;

export const Authentication = () => {
  const { error } = useLoaderData() as AuthLoaderData;

  return (
    <>
      <Auth />
      {error && <Toast type="error" variant="primary" message={error} />}
    </>
  );
};
