import * as Toast from "@radix-ui/react-toast";
import React, { createContext } from "react";

export const ToastContext = createContext(undefined);

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <Toast.Provider>
      {children}
      <Toast.Viewport className="fixed bottom-0 right-0 z-50 m-0 flex max-w-full list-none flex-col-reverse items-end gap-2 p-4 outline-none" />
    </Toast.Provider>
  );
};
