import { Outlet } from "react-router";

import { Header } from "../../components/header/Header";

export const DetailsLayout = () => {
  return (
    <div className="grid h-screen grid-cols-1 grid-rows-1 overflow-hidden bg-[#f9f9f9] sm:grid-rows-[auto_1fr]">
      <Header className="hidden sm:block" />
      <Outlet />
    </div>
  );
};
