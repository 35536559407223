import { ReactNode } from "react";

import logoSmSrc from "../../assets/images/logo-text-sm.svg";

export const LogoMessage = ({
  children,
  className,
}: {
  children?: ReactNode;
  className: string;
}) => {
  return (
    <section
      className={`flex flex-col items-center justify-center gap-6  ${className}`}
    >
      <img className="h-56 w-56" src={logoSmSrc} alt="" />
      {children}
    </section>
  );
};
