import { SVGProps } from "react";

export const SlackIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.50557 14.5016C6.50557 15.605 5.61377 16.4968 4.51035 16.4968C3.40693 16.4968 2.51512 15.605 2.51512 14.5016C2.51512 13.3982 3.40693 12.5063 4.51035 12.5063H6.50557V14.5016ZM7.50319 14.5016C7.50319 13.3982 8.39499 12.5063 9.49841 12.5063C10.6018 12.5063 11.4936 13.3982 11.4936 14.5016V19.4896C11.4936 20.5931 10.6018 21.4849 9.49841 21.4849C8.39499 21.4849 7.50319 20.5931 7.50319 19.4896V14.5016Z"
        fill="#E01E5A"
      />
      <path
        d="M9.49841 6.49045C8.39499 6.49045 7.50318 5.59865 7.50318 4.49523C7.50318 3.39181 8.39499 2.5 9.49841 2.5C10.6018 2.5 11.4936 3.39181 11.4936 4.49523V6.49045H9.49841ZM9.49841 7.50318C10.6018 7.50318 11.4936 8.39499 11.4936 9.49841C11.4936 10.6018 10.6018 11.4936 9.49841 11.4936H4.49523C3.39181 11.4936 2.5 10.6018 2.5 9.49841C2.5 8.39499 3.39181 7.50318 4.49523 7.50318H9.49841Z"
        fill="#36C5F0"
      />
      <path
        d="M17.4944 9.49841C17.4944 8.39499 18.3862 7.50318 19.4897 7.50318C20.5931 7.50318 21.4849 8.39499 21.4849 9.49841C21.4849 10.6018 20.5931 11.4936 19.4897 11.4936H17.4944V9.49841ZM16.4968 9.49841C16.4968 10.6018 15.605 11.4936 14.5016 11.4936C13.3982 11.4936 12.5064 10.6018 12.5064 9.49841V4.49523C12.5064 3.39181 13.3982 2.5 14.5016 2.5C15.605 2.5 16.4968 3.39181 16.4968 4.49523V9.49841Z"
        fill="#2EB67D"
      />
      <path
        d="M14.5016 17.4944C15.605 17.4944 16.4968 18.3862 16.4968 19.4896C16.4968 20.5931 15.605 21.4849 14.5016 21.4849C13.3982 21.4849 12.5064 20.5931 12.5064 19.4896V17.4944H14.5016ZM14.5016 16.4968C13.3982 16.4968 12.5064 15.605 12.5064 14.5016C12.5064 13.3982 13.3982 12.5063 14.5016 12.5063H19.5048C20.6082 12.5063 21.5 13.3982 21.5 14.5016C21.5 15.605 20.6082 16.4968 19.5048 16.4968H14.5016Z"
        fill="#ECB22E"
      />
    </svg>
  );
};
