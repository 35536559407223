import { memo } from "react";

import { InfoItemProps } from ".";
import { differenceInDays, differenceInHours } from "../../utils/date";

interface DeadlineLabelWithColor {
  label: string;
  variantClass: string;
  variant: string;
}
// eslint-disable-next-line react-refresh/only-export-components
export const getDeadlineLabelWithColor = (deadline: string): DeadlineLabelWithColor => {
  const deadlineDate = new Date(deadline);
  const currentDate = new Date();

  const timeDiff = differenceInHours(deadlineDate, currentDate);
  const daysDiff = differenceInDays(deadlineDate, currentDate);

  // Deadline is now since last minute
  if (daysDiff < 0 || timeDiff <= 0) {
    return {
      label: "Overdue",
      variantClass: "text-error-regular",
      variant: "error",
    };
  }

  // Deadline is today
  if (daysDiff === 0) {
    return {
      label: "Due Today",
      variantClass: "text-alert-regular",
      variant: "alert",
    };
  }

  // Deadline is tomorrow
  if (daysDiff === 1) {
    return {
      label: "Due Tomorrow",
      variantClass: "text-warning-regular",
      variant: "warning",
    };
  }

  return {
    label: `Due in ${daysDiff} days`,
    variantClass: "text-gray-secondary",
    variant: "default",
  };
};

export const DeadlineLabel = memo(
  ({ deadlineAt }: Pick<InfoItemProps, "deadlineAt">) => {
    if (!deadlineAt) return null;

    const { label, variantClass } = getDeadlineLabelWithColor(deadlineAt);
    return <p className={`text-xs ${variantClass}`.trim()}>{label}</p>;
  },
);

DeadlineLabel.displayName = "DeadlineLabel";
