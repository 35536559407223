import { Outlet } from "react-router";

import { Header } from "../../components/header/Header";

export const MainLayout = () => {
  return (
    <div className="flex min-h-screen flex-col items-center bg-main">
      <Header />
      <Outlet />
    </div>
  );
};
