import { Root, type SwitchProps, Thumb } from "@radix-ui/react-switch";
import { ReactNode } from "react";

type Props = {
  id: string;
  label: ReactNode;
  labelSide: "left" | "right";
  disabled?: boolean;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
} & Omit<SwitchProps, "onCheckedChange" | "onChange">;

const Label = ({
  htmlFor,
  label,
}: {
  htmlFor: Props["id"];
  label: Props["label"];
}) => (
  <label className="cursor-pointer" htmlFor={htmlFor}>
    {label}
  </label>
);

export const Switch = ({
  id,
  label,
  labelSide = "left",
  disabled,
  defaultChecked,
  checked,
  onChange,
  ...rootProps
}: Props) => {
  return (
    <div
      className={`flex items-center gap-4 ${
        disabled ? "opacity-50" : ""
      }`.trim()}
    >
      {labelSide === "left" && <Label htmlFor={id} label={label} />}
      <Root
        className="relative flex h-6 w-10 items-center rounded-full bg-gray-secondary shadow transition-colors focus:shadow-md data-[state='checked']:bg-primary-500"
        id={id}
        disabled={disabled}
        defaultChecked={defaultChecked}
        checked={checked}
        onCheckedChange={onChange}
        {...rootProps}
      >
        <Thumb className="h-5 w-5 translate-x-[2px] rounded-full bg-white shadow-sm transition-all data-[state='checked']:translate-x-[18px]" />
      </Root>
      {labelSide === "right" && <Label htmlFor={id} label={label} />}
    </div>
  );
};
