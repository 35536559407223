import { z } from "zod";

import { captureException } from "../monitoring";

export const postOAuthMessage = () => {
  if (window.opener) {
    const payload = windowMessageEventData.parse({
      source: "login-redirect",
      payload: JSON.stringify(window.location),
    });

    (window.opener as Window).postMessage(payload);

    window.close();
  }
};

const windowMessageEventData = z.object({
  source: z.string(),
  payload: z.string(),
});

const oAuthPayloadSerializedSchema = z.object({
  href: z.string(),
});

const oAuthMessageListener = (event: MessageEvent) => {
  // We only trust events from ourselves
  if (event.origin !== window.location.origin) {
    return;
  }

  const result = windowMessageEventData.safeParse(event.data);

  if (!result.success) {
    captureException("Invalid message received from auth popup",
      {
        extra: {
          event: event,
          result: result
        }
      },
    );
    return;
  }

  const {
    data: { source, payload },
  } = result;

  if (source === "login-redirect") {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const payloadJson = JSON.parse(payload);
      const payloadResult = oAuthPayloadSerializedSchema.safeParse(payloadJson);

      if (!payloadResult.success) {
        captureException(
          new Error(
            "Could not parse auth payload: " +
              JSON.stringify(payloadResult.error),
          ),
        );
        return;
      }

      const {
        data: { href },
      } = payloadResult;

      window.location.href = href + "&fromAuth=1";
    } catch (error) {
      captureException(
        new Error(
          "Could not parse auth json payload: " + JSON.stringify(payload),
        ),
      );
    }
  }
};

let windowObjectReference: Window | null = null;

export const initOAuth = (url: string) => {
  window.removeEventListener("message", oAuthMessageListener);

  if (windowObjectReference === null || windowObjectReference.closed) {
    windowObjectReference = window.open(
      url,
      undefined,
      "toolbar=no, menubar=no, width=600, height=700, top=0, left=0",
    );
  }

  if (windowObjectReference) {
    windowObjectReference.focus();
  }

  window.addEventListener("message", oAuthMessageListener, false);
};
