import { Link, useLoaderData } from "react-router-dom";

import { GoogleIcon } from "../../components/icons";
import { initOAuth } from "../../services/auth/oauth";
import { AuthLoaderData } from "../Authentication";

export const Auth = () => {
  const { authUrl, authType, authLastLogin } =
    useLoaderData() as AuthLoaderData;

  const renderBelowText = () => {
    if (authLastLogin && authType === "select_account") {
      return (
        <>
          You were previously logged in as {authLastLogin} <br />
          <span className="block pt-4 text-xs">
            To login with a different account, click ‘Login with Google’ and
            choose another account on the next screen.
          </span>
        </>
      );
    } else if (authType === "select_account") {
      return (
        <>
          By using Lesen, you are agreeing to our &nbsp;
          <a
            href="https://lesen.ai/terms-and-conditions"
            target="__blank"
            rel="noreferrer"
            className="text-hyperlink-new decoration-1 hover:no-underline"
          >
            Terms
          </a>
          ,{" "}
          <a
            href="https://lesen.ai/privacy-policy"
            target="__blank"
            rel="noreferrer"
            className="text-hyperlink-new decoration-1 hover:no-underline"
          >
            Privacy Policy
          </a>
        </>
      );
    } else if (authType === "consent") {
      return (
        <>
          By clicking &quot;<b>Signup with Google</b>&quot; you acknowledge that
          you have read, understood and agree to Lesen’s&nbsp;
          <a
            href="https://lesen.ai/terms-and-conditions"
            target="__blank"
            className="text-hyperlink-new decoration-1 hover:no-underline"
          >
            Terms & Conditions
          </a>
          ,&nbsp;
          <a
            href="https://lesen.ai/privacy-policy"
            target="__blank"
            className="text-hyperlink-new decoration-1 hover:no-underline"
          >
            Privacy Policy
          </a>
          &nbsp;and authorize sharing your data with OpenAI.
        </>
      );
    } else {
      return null;
    }
  };
  return (
    <>
      <div className="flex w-full flex-col items-center gap-4 xl:w-1/2 xl:items-center xl:px-36 xl:pt-32">
        <span className="text-2xl text-white">
          {authType === "select_account"
            ? "Ready to dive back in?"
            : "Let’s Create Your Account!"}
        </span>

        <button
          className="flex w-full items-center justify-center whitespace-nowrap rounded-full border border-gray-400 bg-white px-3 py-2.5 text-center text-base font-bold  text-black transition-colors hover:bg-gray-100 disabled:cursor-not-allowed disabled:bg-white disabled:opacity-50"
          onClick={() => (authUrl ? initOAuth(authUrl) : void 0)}
        >
          <span className="inline-flex flex-row items-center gap-2">
            <GoogleIcon className="h-4 w-4" />
            {!authUrl
              ? "Loading..."
              : authType === "select_account"
                ? "Continue with Google"
                : "Signup with Google"}
          </span>
        </button>
        <p className="text-white">
          {authType === "select_account"
            ? "Don't have an account?"
            : "Already a user?"}
          &nbsp;
          <Link
            to={authType === "select_account" ? "/signup" : "/login"}
            className="w-full text-center underline decoration-1"
          >
            {authType === "select_account" ? "Sign Up" : "Login"}
          </Link>
        </p>
        {/* Desktop */}
        <p className="mt-56 hidden w-full text-center text-sm text-gray-secondary xl:block">
          {renderBelowText()}
        </p>
      </div>
      {/* Mobile */}
      <p className="w-full pb-8 text-center text-sm text-gray-secondary xl:hidden">
        {renderBelowText()}
      </p>
    </>
  );
};
