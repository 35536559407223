import { QueryClient, useQuery } from "@tanstack/react-query";
import { ReactNode, Suspense, useEffect, useRef, useState } from "react";
import {
  Await,
  Link,
  LoaderFunction,
  Params,
  defer,
  redirect,
  useLoaderData,
  useLocation,
  useParams
} from "react-router-dom";
import { PageLoader } from "../../components/PageLoader";
import { Sidebar } from "../../components/Sidebar";
import { AlertIcon, ArrowDown, ArrowLeftIcon, ArrowUp, CloseIcon } from "../../components/icons";
import { getDeadlineLabelWithColor } from "../../components/info/DeadlineLabel";
import { Message } from "../../components/message";
import { Paper } from "../../components/paper";
import { Tag, TagFill, TagType } from "../../components/tag";
import { TagList } from "../../components/tag/TagList";
import { infoItemDetailQuery } from "../../services/info/queries";
import { Origin } from "../../services/info/schema";


export type LoaderData = Awaited<ReturnType<typeof getLoaderData>>;

// eslint-disable-next-line react-refresh/only-export-components
export const getLoaderData = async (
  queryClient: QueryClient,
  params: Params,
) => {
  const { id } = params;

  if (!id) throw redirect("/");

  const infoItem = await queryClient.fetchQuery({
    ...infoItemDetailQuery(id),
  })


  return Promise.resolve({
    infoItem
  });
};

// eslint-disable-next-line react-refresh/only-export-components
export const loader = (queryClient: QueryClient) =>
  (async ({ params }: { params: Params }) =>
    defer(await getLoaderData(queryClient, params))) satisfies LoaderFunction;

const Heading = () => {
  const { id } = useParams();
  const { data } = useQuery({
    ...infoItemDetailQuery(id!),
  });

  const location = useLocation().state as {
    prevPath: string
  };
  const previousPath = location?.prevPath ?? "/view-all";

  return (
    <h1 className="flex items-center gap-3 text-2xl text-gray-primary">
      <Link to={previousPath}>
        <ArrowLeftIcon className="h-6 w-6" />
      </Link>
      <span className="overflow-hidden text-ellipsis whitespace-nowrap">
        {data?.shortSummary
          ? data?.shortSummary
          : data?.subjectLine
            ? data?.subjectLine
              : "Untitled"}
      </span>
    </h1>
  );
};


const Participants = ({ participants }: { participants: string[] }) => {
  const [open, setOpen] = useState(false);

  const [isOverflowing, setIsOverflowing] = useState(false);
  const widthRef = useRef<HTMLSpanElement>(null);
  
  useEffect(() => {
    const checkOverflow = () => {
      const el = widthRef.current;
      const offsetWidth = el?.offsetWidth ?? 0;
      const scrollWidth = el?.scrollWidth ?? 0;
      
      setOpen(false);
      setIsOverflowing(offsetWidth < scrollWidth || false)
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  const participantsList = participants.join(', ');
  
  return (
    <div className="flex pt-4 sm:px-8 text-base text-gray-primary">
        <span className="text-gray-secondary pr-1">
          From: 
        </span>
        <span 
          ref={widthRef}
          className={!open ? "text-ellipsis max-h-6 whitespace-nowrap overflow-hidden" : ""}
        >
          {participantsList}
        </span>
        {isOverflowing &&
          <span className={"ml-2"}>
            {open && <ArrowUp className="inline-block w-3" onClick={() => setOpen(!open)}/>}
            {!open && <ArrowDown className="inline-block w-3" onClick={() => setOpen(!open)}/>}
          </span>
        }
    </div>
  )
}

const DueTag = ({ deadlineAt }: { deadlineAt: string }) => {
  const deadline = getDeadlineLabelWithColor(deadlineAt);

  let tagType = TagType.NEUTRAL;
  switch (deadline.variant) {
    case "error":
      tagType = TagType.DANGER;
      break;
    case "alert":
      tagType = TagType.ALERT;
      break;
    case "warning":
      tagType = TagType.WARNING;
      break;
    default:
      tagType = TagType.NEUTRAL;
      break;
  }

  return (
    <Tag type={tagType} fill={TagFill.LIGHT}>
      <span className="flex flex-row flex-nowrap items-center gap-2">
        {deadline.label} <AlertIcon className="h-4 w-4" />
      </span>
    </Tag>
  );
};

const Tags = () => {
  const { id } = useParams();
  const { data } = useQuery({
    ...infoItemDetailQuery(id!),
  });

  if (
    !data ||
    (!data?.isUrgent && !data?.deadlineAt && !data?.topics?.length)
  )
    return null;

  return (
    <div className="flex flex-wrap gap-2 pt-4 sm:px-8">
      {data.isUrgent ? (
        <Tag type={TagType.ALERT} fill={TagFill.LIGHT}>
          <span className="flex flex-row flex-nowrap items-center gap-2">
            Urgent <AlertIcon className="h-4 w-4" />
          </span>
        </Tag>
      ) : null}

      {data.deadlineAt ? <DueTag deadlineAt={data.deadlineAt} /> : null}

      {data.topics?.length ? (
        <TagList
          tags={data.topics.map((subject) => ({
            type: TagType.INFO,
            fill: TagFill.LIGHT,
            children: subject,
          }))}
        />
      ) : null}
    </div>
  );
};

const Summary = () => {
  const { id } = useParams();
  const { data } = useQuery({
    ...infoItemDetailQuery(id!),
  });

  const summary = 
    data?.summary ? data.summary :
    data?.description ? data.description: null;

  if (!summary) return null;

  return (
    <div className="flex flex-col self-start">
      <p className="text-lg font-medium text-gray-primary mb-4">Summary</p>
      <Message content={summary} />
      <hr className="mt-4 w-full" />
    </div>
  );
};

const MainContainer = ({ sidebarTrigger }: { sidebarTrigger: ReactNode }) => {
  const { id } = useParams();
  const { data } = useQuery({
    ...infoItemDetailQuery(id!),
  });

  const showSidebarTrigger = !!data?.threadId;
  const participantsList = data?.participants
                          .sort((a, b) => (b.last_activity > a.last_activity ? 1 : -1))
                          .map((participant) => participant.user) ?? [];

  return (
    <main className="flex h-full w-full grow flex-col sm:max-w-screen-2xl sm:px-6 sm:py-12">
      <Paper
        as="div"
        className="grid h-full grow grid-cols-1 grid-rows-[max-content_1fr_max-content]"
      >
        <section className="sticky top-0 bg-white p-4 sm:static sm:px-8 sm:pt-8 md:px-12 md:pt-12 lg:px-16 lg:pt-16 xl:px-20 xl:pt-20">
          <Heading />
          <Participants participants={participantsList} />
          <Tags />
        </section>
        <section className="flex h-full flex-col gap-4 overflow-x-auto px-4 pb-4 sm:px-16 sm:pb-8 md:px-20 md:pb-12 lg:px-24 lg:pb-16 xl:px-28 xl:pb-20">
          <Summary />
          <section className="sm:flex-col flex-row">
            {showSidebarTrigger &&
              sidebarTrigger
            }

            {data?.permalink &&
              <span className="pt-4 block sm:inline sm:pt-0">
                {showSidebarTrigger &&
                  <span className="mr-4 ml-4 text-gray-secondary hidden sm:inline">
                    {" • "}
                  </span>
                }
                <ConversationExternalLink
                  link={data.permalink}
                  origin={data?.origin}
                />
              </span>
            }
          </section>
        </section>
      </Paper>
    </main>
  );
};

const ConversationSidebarHeader = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <div className="flex flex-row flex-nowrap justify-between mb-4 border-b border-b-gray-100 p-4 text-gray-500 md:px-6 md:py-8">
      <p className="text-2xl font-semibold text-gray-primary">View Full Conversation</p>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className="text-gray-400"
      >
        <CloseIcon className="h-8 w-8" />
      </button>
    </div>
  );
};

const ConversationSidebarContent = () => {
  const { id } = useParams();

  const threadId = id ?? "";

  const { data: threads } = useQuery({
    ...infoItemDetailQuery(threadId),
    // Should only be enabled if we actually have a threadId (in theory it "should" always exist)
    enabled: threadId !== "",
  });

  const threadsList =
    threads?.infoItems?.map((thread) => ({
      // ToDo: remove once we have a proper handling for the name from the backend
      sender: thread.sender ? thread.sender.split("@")[0] : "Unknown sender",
      description: thread.content ?? "",
      sentAt: thread.sent_at.toString()
    }));

  return (
    <div className="h-full overflow-y-scroll flex flex-col mb-4 mx-6 gap-6 pb-8">
      {
        threadsList?.sort((a, b) => a.sentAt.localeCompare(b.sentAt))
          .map(({ sender, description, sentAt }, index) => {
            return (
              <Message
                sender={sender}
                content={description}
                timestamp={sentAt}
                key={index}
              />
            );
          })
      }
    </div>
  );
};

const ConversationSidebarContainer = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <Sidebar open={open} side="right">
      <Paper className="grid h-full grid-cols-1 grid-rows-[max-content_1fr] bg-white">
        <ConversationSidebarHeader open={open} setOpen={setOpen} />
        <ConversationSidebarContent />
      </Paper>
    </Sidebar>
  );
};

const ConversationSidebarTrigger = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <button
      type="button"
      onClick={() => setOpen(!open)}
      className="mt-auto inline-flex text-center text-base font-medium text-primary-default sm:mt-0 sm:text-left"
    >
      Show Full Conversation
    </button>
  );
};


const ConversationExternalLink = ({
  link,
  origin
}: {
  link: string,
  origin?: Origin | null,
}) => {
  return (
    <Link
      to={link}
      target="_blank"
      rel="noreferrer"
      className="inline-flex max-w-sm mt-auto text-center text-base font-medium text-primary-default sm:mt-0 sm:text-left capitalize"
    >
      {origin ? "Open in " + origin : "Open external link"}
    </Link>
  );
};


const Content = ({
  showSidebar
}: {
  showSidebar: boolean
}) => {
  const [open, setOpen] = useState(false);

  return (
    <section className="grid h-full w-full grow grid-cols-[1fr_auto] grid-rows-1 justify-items-center overflow-hidden">
      <MainContainer
        sidebarTrigger={<ConversationSidebarTrigger open={open} setOpen={setOpen} />}
      />
      {showSidebar && <ConversationSidebarContainer open={open} setOpen={setOpen} />}
    </section>
  );
};

export const Details = () => {
  const data = useLoaderData() as LoaderData;

  const showSidebar = !!data?.infoItem?.threadId

  return (
    <Suspense fallback={<PageLoader />}>
      <Await resolve={data.infoItem}>{() => <Content showSidebar={showSidebar} />}</Await>
    </Suspense>
  );
};
