import { AnchorHTMLAttributes, ButtonHTMLAttributes, ReactNode } from "react";

interface BaseProps {
  variant: "primary" | "outline";
  children: ReactNode;
  className?: string;
}

type ButtonProps = {
  as: "button";
} & BaseProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

type AnchorProps = {
  as: "a";
} & BaseProps &
  AnchorHTMLAttributes<HTMLAnchorElement>;

export const Button = ({
  as = "button",
  variant,
  children,
  className,
  ...buttonProps
}: ButtonProps | AnchorProps) => {
  const baseClass =
    "flex w-full items-center justify-center whitespace-nowrap px-1 py-1 text-center text-base font-medium transition-colors disabled:cursor-not-allowed disabled:opacity-50";
  const variantClass = {
    primary:
      "border border-transparent bg-primary-default hover:bg-primary-dark disabled:bg-primary-default text-white",
    outline:
      "border border-gray-200 bg-white hover:bg-gray-100 text-black disabled:bg-white",
  };

  if (as === "button") {
    return (
      <button
        className={`${baseClass} ${variantClass[variant]} ${className}`.trim()}
        {...(buttonProps as ButtonHTMLAttributes<HTMLButtonElement>)}
      >
        {children}
      </button>
    );
  }

  return (
    <a
      className={`${baseClass} ${variantClass[variant]} ${className}`.trim()}
      {...(buttonProps as AnchorHTMLAttributes<HTMLAnchorElement>)}
    >
      {children}
    </a>
  );
};
