import { memo } from "react";
import { Link, useLocation } from "react-router-dom";

import { useNewMessageHighlight } from "../../hooks/useNewMessageHighlight";
import { format, isEqual, sub } from "../../utils/date";
import { AlertIcon, ArchiveIcon, GmailIcon, SlackIcon } from "../icons";
import { Paper } from "../paper";
import { TagFill, TagType } from "../tag";
import { TagList } from "../tag/TagList";
import { DeadlineLabel } from "./DeadlineLabel";
import { TextOverflow } from "../textoverflow";

export interface InfoItemProps {
  id: string;
  threadId: string;
  onDismiss?: () => void;
  subjectLine?: string | null;
  shortSummary?: string | null;
  participants: InfoItemParticipants[];
  createdAt: string | null;
  deadlineAt?: string | null;
  permalink?: string | null;
  isUrgent?: boolean | null;
  isRead?: boolean | null;
  topics?: string[] | null;
  origin?: string | null;
}

export interface InfoItemParticipants {
  user: string;
  last_activity: string;
}

const getDateLabel = (date: string) => {
  const dateObj = new Date(format(new Date(date), "P"));
  const today = new Date(format(new Date(), "P"));

  if (isEqual(dateObj, today)) {
    return (
      <>
        Today&nbsp;
        <wbr />
        <span className="whitespace-nowrap">{format(new Date(date), "p")}</span>
      </>
    );
    // return `Today ${format(new Date(date), "p")}`;
  }

  if (isEqual(dateObj, sub(today, { days: 1 }))) {
    return (
      <>
        Yesterday&nbsp;
        <wbr />
        <span className="whitespace-nowrap">{format(new Date(date), "p")}</span>
      </>
    );
  }

  return format(new Date(date), "dd.MM.yyy");
};

const InfoItemContent = memo(
  ({
    subjectLine,
    shortSummary,
    participants,
    createdAt,
    deadlineAt,
    isUrgent,
    isRead,
    topics,
    origin,
    onDismiss,
  }: Omit<InfoItemProps, "threadId"  | "id" | "permalink">) => {
    function handleOnDismiss(e: React.MouseEvent<HTMLButtonElement>) {
      e.preventDefault();
      e.stopPropagation();

      onDismiss && onDismiss();
    }

     const participantsList = participants
                              .sort((a, b) => (b.last_activity > a.last_activity ? 1 : -1))
                              .map((participant) => participant.user) ?? [];

    return (
      <>
        <div className="grid grid-cols-1 gap-3 sm:grid-cols-[minmax(25%,_1fr)_max-content] sm:gap-4">
          <div className="grid grid-flow-row auto-rows-min gap-2 sm:gap-0">
            <div className="row-start-2 row-end-2 flex flex-row items-center gap-2 truncate sm:row-start-1 sm:row-end-1">
              {isUrgent ? (
                <AlertIcon className="h-4 w-4 shrink-0 text-alert-regular" />
              ) : null}
              <p
                className="truncate text-base"
                dangerouslySetInnerHTML={{
                  __html: shortSummary
                    ? shortSummary
                    : subjectLine
                      ? subjectLine
                        : "",
                }}
              />
            </div>

            <div className="row-start-1 row-end-2 flex flex-col sm:row-start-2 sm:row-end-2 sm:flex-row sm:items-center sm:gap-3 text-xs">
            {origin === 'slack' ? (
              <SlackIcon className="h-4 w-4 shrink-0" />
            ) : origin === 'gmail' ? (
              <GmailIcon className="h-4 w-4 shrink-0" />
            ) : null}
            <div className="text-gray-secondary">
              {participantsList && 
                <TextOverflow items={participantsList} maxLengthSmall={30} maxLengthLarge={50} />
              }
              </div>
              <div className="flex flex-row pt-0.5 sm:pt-0 items-center gap-3">
                <div className="hidden h-1 w-1 rounded-full bg-gray-secondary sm:block" />
                {createdAt && (
                  <p className="text-gray-secondary inline sm:block">
                    {getDateLabel(createdAt)}
                  </p>
                )}
                {deadlineAt ? (
                  <>
                    <div className="h-1 w-1 rounded-full bg-gray-secondary block" />
                    <DeadlineLabel deadlineAt={deadlineAt} />
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className="sm:max-w-xs md:max-w-md lg:max-w-lg">
            <div className="flex flex-row flex-wrap items-center justify-start gap-2 sm:justify-end">
              {topics?.length ? (
                <TagList
                  tags={topics.map((subject) => ({
                    type: TagType.INFO,
                    fill: TagFill.LIGHT,
                    children: subject,
                  }))}
                />
              ) : null}
            </div>
          </div>
        </div>

        {!isRead ? (
          <button
            type="button"
            className="flex h-[30px] flex-col items-center justify-center"
            onClick={handleOnDismiss}
          >
            <ArchiveIcon className="h-6 w-6 text-gray-secondary transition-all hover:cursor-pointer hover:text-gray-primary" />
          </button>
        ) : null}
      </>
    );
  },
);

InfoItemContent.displayName = "InfoItemContent";

export const InfoItem = memo(({ threadId, ...props }: InfoItemProps) => {
  const newMessage = useNewMessageHighlight();
  const location = useLocation();

  const previousPath = location.pathname.includes("/view-all") ? "/view-all" : "/";
  return (
    <Paper>
      <Link
        to={`/details/${threadId}`}
        state={{ prevPath: previousPath }}
        className={`grid grid-cols-[minmax(25%,_1fr)_max-content] gap-4 px-4 py-3 transition-all hover:cursor-pointer hover:shadow-m sm:grid-cols-[minmax(25%,_1fr)_max-content] ${
          newMessage === threadId ? "ring-2 ring-primary-300" : ""
        }`.trim()}
      >
        <InfoItemContent {...props} />
      </Link>
    </Paper>
  );
});

InfoItem.displayName = "InfoItem";
