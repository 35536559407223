import * as ToastPrimitives from "@radix-ui/react-toast";

import {
  CheckCircleIcon,
  CloseIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "../icons";

export interface ToastProps {
  message: string;
  type: "info" | "success" | "error";
  variant: "primary" | "archive";
  action?: {
    label: string;
    onClick: () => void;
  };
  open?: boolean;
  duration?: number;
  onOpenChange?: (open: boolean) => void;
}

export const Toast = ({
  message,
  type,
  variant,
  action,
  open,
  duration = 3000,
  onOpenChange,
}: ToastProps) => {
  const variantBaseClass = {
    primary:
      "justify-end bg-white text-black",
    archive:
      "justify-start bg-gray-primary text-white px-4",
  };

  const variantActionClass = {
    primary:
      "font-bold text-info-regular",
    archive:
      "underline text-blue-400",
  };

  const variantCloseButtonClass = {
    primary:
      "",
    archive:
      "absolute right-6",
  }

  const variantCloseIconClass = {
    primary:
      "h-5 w-5",
    archive:
      "h-6 w-6",
  }

  return (
    <ToastPrimitives.Root
      className={`toast-transition flex flex-row items-start gap-1 rounded-sm h-10 border-gray-400 pl-4 pt-2.5 shadow-lg ${variantBaseClass[variant]}`.trim()}
      open={open}
      duration={duration}
      onOpenChange={onOpenChange}
    >
      <ToastPrimitives.Title className="text-sm">
        {message}
      </ToastPrimitives.Title>
      {action ? (
        <ToastPrimitives.Action
          altText="Toast Action"
          className={`text-sm ${variantActionClass[variant]}`}
          onClick={action.onClick}
        >
          {action.label}
        </ToastPrimitives.Action>
      ) : (
        <>
          {type === "info" && (
            <InformationCircleIcon className="h-5 w-5 shrink-0 text-lg text-info-regular mr-4" />
          )}
          {type === "error" && (
            <ExclamationCircleIcon className="h-5 w-5 shrink-0 text-lg text-error-regular mr-4" />
          )}
          {type === "success" && (
            <CheckCircleIcon className="h-5 w-5 shrink-0 text-lg text-success-regular mr-4" />
          )}
        </>
      )}
      <ToastPrimitives.Close className={`${variantCloseButtonClass[variant]} shrink-0`}>
        <CloseIcon className={variantCloseIconClass[variant]} />
      </ToastPrimitives.Close>
    </ToastPrimitives.Root>
  );
};
