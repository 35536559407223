import { ReactNode } from "react";

export const Paper = ({
  as = "div",
  className,
  children,
}: {
  as?: "div" | "span" | "li" | "main" | "section";
  className?: string;
  children: ReactNode;
}) => {
  const Component = as;

  return (
    <Component
      className={`rounded-lg bg-white shadow-regular ${className ?? ""}`.trim()}
    >
      {children}
    </Component>
  );
};
