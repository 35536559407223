import { ComponentType, ReactNode, SVGProps, forwardRef } from "react";
import { NavLink as Link } from "react-router-dom";

// eslint-disable-next-line react-refresh/only-export-components
export enum NavLinkVariant {
  Standard = "standard",
  Danger = "danger",
}

interface NavLinkProps {
  href: string;
  label: ReactNode;
  Icon?: ComponentType<SVGProps<SVGSVGElement>>;
  variant?: NavLinkVariant.Standard | NavLinkVariant.Danger;
  className?: string | ((isActive: boolean) => string | undefined);
  labelClassName?: string;
  isMenuItem?: boolean | undefined;
}

const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (
    {
      href,
      label,
      Icon,
      variant = NavLinkVariant.Standard,
      className,
      labelClassName,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isMenuItem = true,
      ...props
    },
    ref,
  ) => {
    const variantClass = {
      [NavLinkVariant.Standard]: {
        default: "cursor-pointer",
        active: "font-semibold",
      },
      [NavLinkVariant.Danger]: {
        default: "cursor-pointer",
        active: "font-semibold",
      },
    };

    return (
      <Link
        to={href}
        ref={ref}
        {...props}
        className={({ isActive }) =>
          `flex items-center gap-1.5 text-lg transition-colors ${
            typeof className === "string" ? className : className?.(isActive)
          } ${
            isActive
              ? variantClass[variant].active
              : variantClass[variant].default
          }`.trim()
        }
      >
        {Icon && <Icon className="h-6 w-6 shrink-0" />}
        <span className={labelClassName}>{label}</span>
      </Link>
    );
  },
);

NavLink.displayName = "NavLink";

export default NavLink;
