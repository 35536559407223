import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FeedHeader } from "../feedHeader/FeedHeader";
import { FollowingHeader } from "../followingHeader/FollowingHeader";

const HeaderNavigation = () => {
    const location = useLocation();
    const [headerComponent, setHeaderComponent] = useState(<div></div>);
  
    useEffect(() => {
      switch (location.pathname) {
        case "/home":
          setHeaderComponent(<FeedHeader />);
          break;
        case "/following":
          setHeaderComponent(<FollowingHeader />);
          break;
        case "/vip-comms":
          setHeaderComponent(<FollowingHeader />);
          break;
        default:
          setHeaderComponent(<div></div>);
      }
    }, [location.pathname]);
  
    return <div>{headerComponent}</div>;
  };

  export default HeaderNavigation;