import logoSmSrc from "../../assets/images/logo-sm.svg";

export const Logo = ({ className }: { className?: string }) => (
  <>
    <img
      src={logoSmSrc}
      className={`block h-auto w-8 ${className ?? ""}`.trim()}
    />
  </>
);
