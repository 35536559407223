import { useQuery } from "@tanstack/react-query";

import { ApiError } from "../../utils/api";
import { handleApiException } from "../../utils/safeFetch";
import { getSession, supabaseClient } from "../auth/session";
import { profileApiSchema } from "./schema";

const getProfile = async () => {
  const session = await getSession();

  try {
    const response = await supabaseClient
      .from("profile")
      .select("*")
      .eq("id", session?.user.id)
      .single();

    const data = response.data as unknown;

    return profileApiSchema.parse(data);
  } catch (error) {
    return handleApiException(ApiError.UserNotFound, error);
  }
};

export const profileQuery = () => ({
  queryKey: ["profile"],
  queryFn: async () => {
    return await getProfile();
  },
});

export const useProfileQuery = () => {
  return useQuery(profileQuery());
};
