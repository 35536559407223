import { QueryClient } from "@tanstack/react-query";
import { LoaderFunction, useLoaderData } from "react-router-dom";

import { SocialMediaIntegrations } from "../../components/SocialMediaIntegrations/SocialMediaIntegrations";
import { slackQuery } from "../../services/integrations/slack/query";

// eslint-disable-next-line react-refresh/only-export-components
export const loader = (queryClient: QueryClient) =>
  (async () => {
    const data = await queryClient.ensureQueryData({
      ...slackQuery("import"),
    });

    return data;
  }) satisfies LoaderFunction;

export const Import = () => {
  const slackAuthUrl = useLoaderData() as string;

  return (
    <>
      <SocialMediaIntegrations
        slackAuthUrl={slackAuthUrl}
        isImportFlow={true}
      ></SocialMediaIntegrations>
    </>
  );
};
