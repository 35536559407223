import { Outlet } from "react-router";

import logoSmSrc from "../../assets/images/logo-text-sm.svg";
import logoFullSrc from "../../assets/images/logo-text.svg";

export const MainFlowLayout = () => {
  return (
    <main className="flex min-h-screen w-full justify-center xl:items-center">
      <section className="flex max-w-96 flex-col justify-between px-6 xl:h-[600px] xl:w-[1280px] xl:max-w-full xl:flex-row xl:items-center xl:items-stretch xl:py-24">
        <div className="p1-6 hidden w-1/2 items-center justify-center border-r xl:flex">
          <img src={logoFullSrc} className={`h-52`.trim()} />
        </div>
        <img src={logoSmSrc} className={`mt-4 h-24 xl:hidden`.trim()} />
        <Outlet />
      </section>
    </main>
  );
};
