import { formatDistanceToNow } from '../../utils/date';

export interface MessageProps {
  sender?: string;
  content: string;
  timestamp?: string;
}

export const Message = ({
  sender,
  content,
  timestamp
}: MessageProps) => {
  const date = timestamp && formatDistanceToNow(timestamp, { addSuffix: true }).replace("about", "");
  return (
    <div
      className="flex flex-col"
    >
      {sender &&
        <p className="flex flex-row align-text-bottom items-end">
          <span className="font-semibold text-base text-gray-secondary capitalize">
            {sender}
          </span>
          {date &&
            <span className="font-normal text-sm text-gray-300 pl-2">
              {date}
            </span>
          }
        </p>
      }
      <p
        className="font-normal text-base text-gray-primary"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </div>
  );
};