import { QueryClient } from "@tanstack/react-query";

interface NetworkError extends Error {
  status: number;
}

function isNetworkError(error: unknown): error is NetworkError {
  return (
    typeof error === "object" &&
    error !== null &&
    "status" in error &&
    typeof error.status === "number"
  );
}

function isServerError(error: unknown): boolean {
  return isNetworkError(error) && error.status >= 500 && error.status <= 599;
}

function retryAfterServerError(failureCount: number, error: unknown): boolean {
  if (isServerError(error)) {
    return failureCount < 2 ? true : false;
  }

  return false;
}

// NOTE: see https://tkdodo.eu/blog/react-query-and-type-script#what-about-error
// for more info on `error: unknown`
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: retryAfterServerError,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: retryAfterServerError,
    },
  },
});
