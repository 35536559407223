import { z } from "zod";

// NOTE: a) figure out how to reuse existing schema or b) reuse only types per property?
export const gmailSettingsActionSerializedSchema = z.object({
  parrot_to_gmail_sync: z.enum(["inbox_0_classic", "inbox_0_alt", "no_sync"]),
  auto_labelling: z.boolean().optional(),
});

export const gmailSettingsApiSchema = z.object({
  parrot_to_gmail_sync: z
    .enum(["inbox_0_classic", "inbox_0_alt", "no_sync"])
    .nullable(),
  auto_labelling: z.boolean().nullable(),
});
