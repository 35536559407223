import { Link } from "react-router-dom";

export const Server = () => {
  return (
    <main className="flex h-full min-h-screen w-full flex-col items-center justify-center">
      <section className="mx-4 flex h-full w-full flex-col items-center justify-center gap-4 text-3xl text-gray-primary">
      <h1 className="text-center font-bold text-white">Sorry!</h1>
        <p className="px-6">
          Looks like we&apos;ve made an error, we&apos;re working on fixing it!
        </p>
        <p>
          <Link
            to=".."
            relative="path"
            className="text-white underline underline-offset-4"
          >
            Go Back?
          </Link>
        </p>
      </section>
    </main>
  );
};
