import { RealtimeChannel } from "@supabase/supabase-js";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import importLoaderSrc from "../../assets/images/import-loader.gif";
import { verifyAccountSetup } from "../../services/auth/account";
import { getSession, supabaseClient } from "../../services/auth/session";
import {
  THREADS_CHANNEL,
  THREADS_TABLE_NAME,
} from "../../services/info/subscription";

export const Importing = () => {
  const renderBelowText = () => {
    return (
      <>
        <div className="w-full items-center text-center text-sm text-gray-secondary">
          We take data privacy very seriously, read our{" "}
          <a
            href="https://lesen.ai/encryption-policy"
            target="__blank"
            rel="noreferrer"
            className="text-hyperlink-new decoration-1 hover:no-underline"
          >
            Encryption Policy
          </a>
          ,{" "}
          <a
            href="https://lesen.ai/data-storage"
            target="__blank"
            rel="noreferrer"
            className="text-hyperlink-new decoration-1 hover:no-underline"
          >
            Data Storage
          </a>
          {", "}
          <a
            href="https://lesen.ai/regulation-policy"
            target="__blank"
            rel="noreferrer"
            className="text-hyperlink-new decoration-1 hover:no-underline"
          >
            AI Regulations Policy
          </a>
        </div>
      </>
    );
  };

  const navigate = useNavigate();

  let websocket: RealtimeChannel | null = null;
  const load = async () => {
    const session = await getSession();
    const reponse = await supabaseClient
      .from("threads")
      .select()
      .eq("user_id", session.user.id)
      .eq("enhancement_status", "done")
      .limit(1)
      .maybeSingle();

    if (reponse.data) {
      navigate("/home");
    } else {
      await verifyAccountSetup();

      const tableOptions = {
        schema: "public",
        table: THREADS_TABLE_NAME,
        filter: `user_id=eq.${session?.user?.id}`,
      };

      websocket = supabaseClient
        .channel(THREADS_CHANNEL)
        .on(
          "postgres_changes",
          {
            event: "UPDATE",
            ...tableOptions,
          },
          (payload: unknown) => {
            interface thread {
              new: {
                enhancement_status: string;
              };
            }

            if ((payload as thread).new.enhancement_status === "done") {
              void websocket?.unsubscribe();
              navigate("/home");
            }
          },
        )
        .subscribe();
    }
  };

  useEffect(() => {
    void load();
    const timer = setTimeout(
      () => {
        navigate("/home?error=import");
      },
      2 * 1000 * 60,
    ); // Two minutes

    return () => {
      void websocket?.unsubscribe();
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="my-auto flex w-full flex-col items-center gap-6 xl:w-1/2 xl:items-center xl:justify-center xl:px-32">
        <span className="text-center text-2xl font-bold">
          Connecting all dots...
        </span>
        <img className="h-48 w-48" src={importLoaderSrc} alt="" />
        <div className="w-full text-center text-sm text-gray-secondary">
          This might take a while 😅,
          <br />
          we will let you know when completed
        </div>
        <div className="hidden flex-col gap-4 xl:mt-32 xl:flex">
          {renderBelowText()}
        </div>
      </div>
      <div className="flex flex-col gap-4 pb-8 xl:hidden">
        {renderBelowText()}
      </div>
    </>
  );
};
