import { QueryClient } from "@tanstack/react-query";
import { Link, LoaderFunction, useLoaderData } from "react-router-dom";

import { SocialMediaIntegrations } from "../../components/SocialMediaIntegrations/SocialMediaIntegrations";
import { ArrowLeftIcon } from "../../components/icons";
import { slackQuery } from "../../services/integrations/slack/query";

// eslint-disable-next-line react-refresh/only-export-components
export const loader = (queryClient: QueryClient) =>
  (async () => {
    const data = await queryClient.ensureQueryData({
      ...slackQuery("integrations"),
    });

    return data;
  }) satisfies LoaderFunction;

export const Integrations = () => {
  const slackAuthUrl = useLoaderData() as string;
  return (
    <>
      <main className="mx-4 flex w-full max-w-sm flex-grow flex-col items-center gap-12 overflow-hidden px-6 py-2 lg:w-[1380px] lg:py-8 xl:max-w-full">
        <h1 className="flex items-center gap-3 text-3xl font-bold">
          <Link to="/home">
            <ArrowLeftIcon className="h-6 w-6" />
          </Link>
          Integrations
        </h1>
        <SocialMediaIntegrations
          slackAuthUrl={slackAuthUrl}
          isImportFlow={false}
        ></SocialMediaIntegrations>
        <div className="text-center built-by">Proudly built by Goran, Dipti, Adrian and Tim</div>
      </main>
    </>
  );
};
