import { ZodSchema } from "zod";

import { clearSession, getSession } from "../services/auth/session";
import { captureException, captureMessage } from "../services/monitoring";
import { ApiError } from "./api";

export class ResponseError extends Error {
  constructor(
    public readonly message: ApiError,
    public readonly status: number,
  ) {
    super(message);

    this.status = status;
  }
}

async function createFetchHeaders(init?: HeadersInit) {
  const session = await getSession();

  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${session.access_token}`,
    ...(init ?? {}),
  });

  return headers;
}

/**
 * TODO: handle schema parsing
 *
 * @param args typed fetch arguments
 * @returns Promise of typed response or a rejected promise
 */
export async function safeFetch(...args: Parameters<typeof fetch>) {
  const [input, init] = args;

  try {
    const response = await fetch(input, {
      ...(init ?? {}),
      // credentials: "include",
      headers: await createFetchHeaders(init?.headers),
    });

    await handleResponseExceptions(response);

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

type TypedResponse<T extends NonNullable<unknown>> = Promise<T>;

export async function parsedFetch<T extends NonNullable<unknown>>(
  schema: ZodSchema<T>,
  ...args: Parameters<typeof fetch>
): Promise<TypedResponse<T>> {
  const response = await safeFetch(...args);

  const parsed = schema.safeParse(await response.json());

  if (!parsed.success) {
    captureException(ApiError.FailedToParseResponse);
    return Promise.reject(parsed.error);
  }

  return parsed.data;
}

/**
 * NOTE: handling 403 may seem unnecessary, but it's here to prevent
 * the user from being stuck in a loop of 403s
 * as well as handle cases where the client code may accidentally not handle
 * session clearing properly (e.g. if the user is logged in but
 * their account is disabled, or a buggy session got switched out somehow, etc)
 * giving a way for the users to somehow recover
 *
 * @param response
 * @returns
 */
async function handleResponseExceptions(response: Response) {
  if (response.ok) return;

  if (response.status === 401) {
    captureMessage(ApiError.FailedToAuthenticate);
    await clearSession();
  } else if (response.status === 403) {
    captureMessage(ApiError.ResourceForbidden);
    await clearSession();
  }

  return Promise.reject(response);
}

export async function handleApiException(apiError: ApiError, error: unknown) {
  if (error instanceof Response) {
    const err = new ResponseError(apiError, error.status);
    captureException(err);
    return Promise.reject(err);
  }

  return Promise.reject(error);
}
