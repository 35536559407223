import React, { SVGProps } from 'react';

interface PriorityIconProps extends SVGProps<SVGSVGElement> {
  fill: string;
}

export const PriorityIcon: React.FC<PriorityIconProps> = ({ fill, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
  height="22px" width="22px"
  viewBox="0 0 478 512.29" 
  xmlnsXlink="http://www.w3.org/1999/xlink"
  fill={fill} 
  {...props}>
    <path d="M161.37 396.74H129.6V6.58c0-3.33-3.25-6.58-6.58-6.58H52.94c-3.41 0-6.63 2.93-6.63 6.58v390.16H14.58c-3.37 0-6.82 1.15-9.56 3.57-6.1 5.28-6.7 14.52-1.46 20.59l75.28 86.42c5.99 6.63 16.46 6.71 22.25-.27l71.07-85.87c2.38-2.62 3.81-6.11 3.81-9.84 0-8.13-6.51-14.6-14.6-14.6zM211.39 0c-3.33 0-6.59 3.25-6.59 6.58v56.6c0 3.37 2.94 6.63 6.59 6.63h260.03c3.65 0 6.58-3.26 6.58-6.63V6.58c0-3.33-3.25-6.58-6.58-6.58H211.39zm0 142.11c-3.17 0-6.59 3.37-6.59 6.59v56.59c0 3.26 2.98 6.63 6.59 6.63h192.84c3.61 0 6.58-3.26 6.58-6.63V148.7c0-3.34-3.33-6.59-6.58-6.59H211.39zm0 142.11c-3.17 0-6.59 3.33-6.59 6.59v56.59c0 3.3 2.94 6.63 6.59 6.63h125.65c3.65 0 6.58-3.25 6.58-6.63v-56.59c0-3.34-3.33-6.59-6.58-6.59H211.39z"/>
  </svg>
);
