import { QueryClient } from "@tanstack/react-query";
import { ReactNode } from "react";
import {
  Link,
  LoaderFunction,
  Outlet,
  useLoaderData,
  useLocation,
} from "react-router-dom";

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ExclamationCircleIcon,
} from "../../components/icons";
import {
  GmailIcon,
  GoogleCalendarIcon,
  SlackIcon,
} from "../../components/icons";
import { Paper } from "../../components/paper";
import { Tag, TagFill, TagType } from "../../components/tag";
import { integrationsQuery } from "../../services/integrations/query";
import { IS_NOTIFICATION_SERVICE_AVAILABLE } from "../../utils/consts";

// eslint-disable-next-line react-refresh/only-export-components
export const loader = (queryClient: QueryClient) =>
  (async () => {
    const data = await queryClient.ensureQueryData({
      ...integrationsQuery(),
    });

    return data;
  }) satisfies LoaderFunction;

const Integration = ({
  icon,
  title,
  description,
  status,
  type,
  to,
}: {
  icon: ReactNode;
  title: string;
  description: string;
  status?: "connected" | "disconnected";
  type?: "api" | "browser";
  to?: string;
}) => {
  // Sets the label to "Connected/Not Connected" for an API integration (e.g. Gmail, Slack, Calendar) or
  // "Enabled/Disabled" for a browser based integration (e.g. browser notifications)
  const statusLabel = (() => {
    if (type === undefined || type === "api") {
      return { success: "Connected", neutral: "Not Connected" };
    }
    else {
      return { success: "Enabled", neutral: "Disabled" };
    }
  })();

  return (
    <Paper className="flex flex-1 grow flex-col gap-6 p-6 md:p-8">
      <section className="flex w-full flex-row items-center justify-between">
        {icon}
        {status === undefined ? null : status === "connected" ? (
          <Tag fill={TagFill.LIGHT} type={TagType.SUCCESS}>
            {statusLabel.success}
          </Tag>
        ) : (
          <Tag fill={TagFill.LIGHT} type={TagType.NEUTRAL}>
            {statusLabel.neutral}
          </Tag>
        )}
      </section>
      <section>
        <h2 className="text-xl font-semibold text-gray-primary">{title}</h2>
        <p className="text-lg text-gray-primary">{description}</p>
      </section>
      {to ? (
        <Link
          to={to}
          className="mt-auto flex items-center gap-3 text-hyperlink-new transition-colors hover:text-hyperlink-visited"
        >
          View settings <ArrowRightIcon className="h-5 w-5" />
        </Link>
      ) : (
        <span className="mt-auto italic text-gray-primary">Coming soon...</span>
      )}
    </Paper>
  );
};

export const Settings = () => {
  const location = useLocation();
  const data = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >;

  interface Integrations {
    google_events: boolean;
    slack_events: boolean;
  }

  const integrations: Integrations = data.reduce(
    (acc, integration) => ({
      ...acc,
      [integration.name]: integration.is_enabled,
    }),
    {} as Integrations,
  );

  if (location.pathname === "/settings") {
    return (
      <main className="mx-4 grid h-full w-full max-w-screen-2xl grow grid-cols-1 grid-rows-[min-content_min-content_min-content_min-content] gap-12 px-6 py-4 lg:py-12">
        <h1 className="flex items-center gap-3 text-2xl text-gray-primary">
          <Link to="/">
            <ArrowLeftIcon className="h-6 w-6" />
          </Link>
          Integrations
        </h1>
        <section className="flex flex-col gap-4 lg:flex-1 lg:flex-row lg:flex-wrap">
          <Integration
            icon={<GmailIcon className="h-10 w-10" />}
            title="Gmail"
            description="Connect Google mail to sync your inbox"
            to="/settings/integrations/gmail"
            status={integrations.google_events ? "connected" : "disconnected"}
          />
          <Integration
            icon={<SlackIcon className="h-10 w-10" />}
            title="Slack"
            description="Receive call and message logs from your work"
            to="/settings/integrations/slack"
            status={integrations.slack_events ? "connected" : "disconnected"}
          />
          <Integration
            icon={<GoogleCalendarIcon className="h-10 w-10" />}
            title="Google Calendar"
            description="Enhance your calendar experience"
          />
        </section>
        {IS_NOTIFICATION_SERVICE_AVAILABLE && (
          <>
            <h1 className="flex items-center gap-3 text-2xl text-gray-primary">
              General
            </h1>
            <section className="flex flex-col gap-4 lg:flex-1 lg:flex-row lg:flex-wrap">
              <Integration
                icon={
                  <ExclamationCircleIcon className="h-10 w-10 text-success-dark" />
                }
                title="Notifications"
                description="Get notifications when new events occur"
                to="/settings/notifications"
                type="browser"
                status={
                  window.Notification.permission === "granted"
                    ? "connected"
                    : "disconnected"
                }
              />
            </section>
          </>
        )}
      </main>
    );
  }

  return <Outlet />;
};