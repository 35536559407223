import { z } from "zod";

import { API_BACKEND_URL, API_THREADS_SERVICE_URL, ApiError } from "../../utils/api";
import { handleApiException, parsedFetch } from "../../utils/safeFetch";
import { getSession } from "../auth/session";
import { queryClient } from "../store";
import { infoItemKeys } from "./queries";
import {
  infoItemListFilterApiSchema,
  infoListInfiniteSerializedSchema,
  itemApiSchema,
  itemListApiSchema,
} from "./schema";

const updateInfoItems = async (
  infoItemList: Partial<z.infer<typeof itemApiSchema>>[],
) => {
  const session = await getSession();

  try {
    const response = await parsedFetch(
      itemListApiSchema,
      `${API_THREADS_SERVICE_URL}/threads`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${session.access_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(infoItemList),
      },
    );

    return Promise.resolve(response);
  } catch (error) {
    return handleApiException(ApiError.FailedToUpdateInfoItems, error);
  }
};

export const infoItemListMutation = () => ({
  mutationFn: async (
    infoItemList: Pick<z.infer<typeof itemApiSchema>, "id" | "is_read">[],
  ) => {
    const queryKey = infoItemKeys.lists();
    await queryClient.cancelQueries({ queryKey });

    // Snapshot
    const previousQueries = queryClient.getQueriesData({ queryKey });

    queryClient.setQueriesData({ queryKey }, (queryCache: unknown) => {
      const oldQuery = infoListInfiniteSerializedSchema.safeParse(queryCache);

      if (!oldQuery.success) return queryCache;

      const { data } = oldQuery;

      const deserializedInfoItemList = infoItemList.map((item) => ({
        id: item.id,
        isRead: item.is_read,
      }));

      const newQueryCache = {
        ...data,
        pages: data.pages.map((page) => ({
          ...page,
          list: page.list
            .map((item) => {
              const newItem = deserializedInfoItemList.find(
                (_item) => _item.id === item.id,
              );

              if (!newItem) return item;

              return {
                ...item,
                isRead: newItem.isRead,
              };
            })
            // NOTE: because we don't show read items in the list
            // If you end up removing this or adding a new filter
            // make sure to update this logic
            // to ensure optimistic updates don't become out of sync
            .filter((item) => !item.isRead),
        })),
      };

      return newQueryCache;
    });
    const maxRetries = 3;
    let retries = 0;
    const baseDelay = 1000;

    while (retries <= maxRetries) {
      try {
        return await updateInfoItems(infoItemList);
      } catch (error) {
        if (retries === maxRetries) {
          queryClient.setQueriesData({ queryKey }, previousQueries);
          return Promise.reject(error);
        }
        await new Promise(resolve => setTimeout(resolve, baseDelay * (2 ** retries)));  // Exponential backoff
        retries++;
        console.error(`Retry attempt ${retries} failed for updateInfoItems. Retrying...`);
      }
    }
  },
});

const updateInfoItemFilterSettings = async (
  filters: z.infer<typeof infoItemListFilterApiSchema>,
) => {
  const session = await getSession();

  try {
    const response = await parsedFetch(
      infoItemListFilterApiSchema,
      `${API_BACKEND_URL}/users/${session.user.id}/settings/filters`,
      {
        method: "PATCH",
        body: JSON.stringify(filters),
      },
    );

    return Promise.resolve(response);
  } catch (error) {
    return handleApiException(
      ApiError.FailedToUpdateInfoItemFilterSettings,
      error,
    );
  }
};

export const infoItemFilterSettingsMutation = () => ({
  mutationFn: async (filters: z.infer<typeof infoItemListFilterApiSchema>) => {
    const queryKey = infoItemKeys.filters("hot-communication");

    await queryClient.cancelQueries({ queryKey });

    const previous = queryClient.getQueryData(queryKey);

    queryClient.setQueryData(queryKey, filters);

    try {
      return await updateInfoItemFilterSettings(filters);
    } catch (error) {
      queryClient.setQueryData(queryKey, previous);
      return Promise.reject(error);
    }
  },
});

export const infoItemFilterViewAllMutation = () => ({
  mutationFn: async (filters: z.infer<typeof infoItemListFilterApiSchema>) => {
    const queryKey = infoItemKeys.filters("view-all");

    await queryClient.cancelQueries({ queryKey });

    const previous = infoItemListFilterApiSchema.parse(
      await queryClient.getQueryData(queryKey),
    );

    return await queryClient.setQueryData(queryKey, {
      ...previous,
      ...filters,
    });
  },
});
