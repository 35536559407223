import { QueryClient } from "@tanstack/react-query";
import { ActionFunction } from "react-router-dom";
import { z } from "zod";

import { infoItemListMutation } from "../../services/info/mutations";
import { infoItemKeys } from "../../services/info/queries";

const InfoItemId = z.string();

const InfoItemIdList = z.array(InfoItemId);

const InfoItemUpdateApiSchema = z.object({
  ids: InfoItemIdList.optional(),
  intent: z.enum(["dismiss", "restore"]),
  source: z.enum(["single", "all"]),
});

const InfoItemListPayloadSerializedSchema = z.array(
  z.object({
    id: InfoItemId,
    is_read: z.boolean(),
  }),
);

export const action = (queryClient: QueryClient) =>
  (async ({ request }: { request: Request }) => {
    const body = (await request.json()) as z.infer<typeof InfoItemUpdateApiSchema>;

    const { ids, intent, source } = InfoItemUpdateApiSchema.parse(body);

    const result = InfoItemIdList.safeParse(ids);

    // NOTE: We probably want to throw here? given that subsequent restore actions are invalid?
    // for the current implementation - those subsequent actions should be prevented by the developer
    // as it would not be possible to trigger multiple subsequent restore actions from the Toast UI
    if (!result.success) return null;
    const { data } = result;

    // Store the archived message in localStorage so we do not pop up a browser notification for them when we restore them
    const currentArchivedItems = JSON.parse(localStorage.getItem("recentlyArchivedInfoItems") ?? "[]") as string[];
    const updatedArchivedItems = [...new Set([...currentArchivedItems, ...data])];
    localStorage.setItem("recentlyArchivedInfoItems", JSON.stringify(updatedArchivedItems));

    const payload = data.map((id) => ({
      id,
      is_read: intent === "restore" ? false : true,
    }));
    InfoItemListPayloadSerializedSchema.parse(payload);

    const { mutationFn } = infoItemListMutation();

    await mutationFn(payload);

    // ToDo: investigate why this was needed if the mutation already cancels the queries
    if(intent === "restore") {
      void queryClient.invalidateQueries({
        queryKey: infoItemKeys.lists(),
        refetchType: "all"
      });
    }
    
    return {
      source,
      intent,
      ids,
    };
  }) satisfies ActionFunction;
