import { SVGProps } from "react";

export const LockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="112"
    height="112"
    viewBox="0 0 112 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M91 35H77V24.5C77 18.9305 74.7875 13.589 70.8492 9.65076C66.911 5.71249 61.5695 3.5 56 3.5C50.4305 3.5 45.089 5.71249 41.1508 9.65076C37.2125 13.589 35 18.9305 35 24.5V35H21C19.1435 35 17.363 35.7375 16.0503 37.0503C14.7375 38.363 14 40.1435 14 42V91C14 92.8565 14.7375 94.637 16.0503 95.9497C17.363 97.2625 19.1435 98 21 98H91C92.8565 98 94.637 97.2625 95.9497 95.9497C97.2625 94.637 98 92.8565 98 91V42C98 40.1435 97.2625 38.363 95.9497 37.0503C94.637 35.7375 92.8565 35 91 35ZM42 24.5C42 20.787 43.475 17.226 46.1005 14.6005C48.726 11.975 52.287 10.5 56 10.5C59.713 10.5 63.274 11.975 65.8995 14.6005C68.525 17.226 70 20.787 70 24.5V35H42V24.5ZM91 91H21V42H91V91ZM61.25 66.5C61.25 67.5384 60.9421 68.5534 60.3652 69.4167C59.7883 70.2801 58.9684 70.953 58.0091 71.3504C57.0498 71.7477 55.9942 71.8517 54.9758 71.6491C53.9574 71.4465 53.0219 70.9465 52.2877 70.2123C51.5535 69.4781 51.0534 68.5426 50.8509 67.5242C50.6483 66.5058 50.7523 65.4502 51.1496 64.4909C51.547 63.5316 52.2199 62.7117 53.0833 62.1348C53.9466 61.5579 54.9616 61.25 56 61.25C57.3924 61.25 58.7277 61.8031 59.7123 62.7877C60.6969 63.7723 61.25 65.1076 61.25 66.5Z"
      fill="white"
      fillOpacity="0.5"
    />
  </svg>
);
