import { escapeForSlackWithMarkdown } from "slack-to-html";

export enum ApiError {
  ResourceForbidden = "Resource forbidden",
  RequestToResourceFailed = "Request to resource failed",
  UserNotFound = "User not found",
  UserSessionNotFound = "User session not found",
  FailedToFetch = "Failed to fetch",
  FailedToParseResponse = "Failed to parse response",
  FailedToAuthenticate = "Failed to authenticate",
  FailedToAuthorizeGoogleApis = "Failed to authorize google apis",
  FailedToVerifyAccountSetup = "Failed to verify account setup",
  FailedToFetchInfoItem = "Failed to fetch info item",
  FailedToSerializeInfoItem = "Failed to serialize info item",
  FailedToFetchInfoItems = "Failed to fetch info items",
  FailedToSerializeInfoItems = "Failed to serialize info items",
  FailedToFetchInfoItemThreads = "Failed to fetch info item threads",
  FailedToSerializeInfoItemThreads = "Failed to serialize info item threads",
  FailedToRefreshSession = "Failed to refresh session",
  FailedToValidateSession = "Failed to validate session",
  FailedToFetchSlackOAuthURL = "Failed to fetch slack oauth url",
  FailedToFetchIntegrations = "Failed to fetch integrations",
  FailedToUpdateGmailSettings = "Failed to update gmail settings",
  FailedToFetchGmailSettings = "Failed to fetch gmail settings",
  FailedToFetchVipContacts = "Failed to fetch VIP Contacts",
  FailedToUpdateInfoItems = "Failed to update info items",
  FailedToSerializeInfoItemList = "Failed to serialize info item list",
  FailedToCreateFilterSettings = "Failed to create filter settings",
  FailedToFetchFilterSettings = "Failed to fetch filter settings",
  FailedToFetchFilterSettingsNotFound = "Failed to fetch filter settings: not found",
  FailedToUpdateInfoItemFilterSettings = "Failed to update info item filter settings",
  FailedToFetchCustomPrompts = "Failed to fetch custom prompts",
  FailedToUpdateCustomPrompts = "Failed to update custom prompts",
  FailedToCreateCustomPrompts = "Failed to create custom prompts",
  FailedToUpdateVipComms = "Failed to update VIP comms",
  FailedToFetchThreads = "Failed to fetch threads",
  FailedToPostReply = "Failed to post reply",
  FailedToArchiveThread = "Failed to archive thread",
}

export const API_URL = import.meta.env.VITE_API_URL;

export const API_BASE_URL = import.meta.env.VITE_BASE_URL;

export const API_BACKEND_URL = import.meta.env.VITE_BACKEND_API_URL;

export const API_THREADS_SERVICE_URL = import.meta.env.VITE_THREADS_SERVICE_URL;

export const API_CUSTOM_PROMPTS_URL = import.meta.env
  .VITE_CUSTOM_PROMPTS_SERVICE_URL;

export const API_VIP_CONTACTS_URL = import.meta.env
  .VITE_VIP_CONTACTS_SERVICE_URL;


export const transformSlackLinksToHTML = (input: string): string => {
  try {
    const transformed = escapeForSlackWithMarkdown(input);

    // Ensure proper URL transformation and escaping of quotes
    return transformed.replace(/<(http:\/\/|https:\/\/|mailto:)\S+\|(\S+)>/g, (url) => {
      // Escape quotes in the URL to prevent breaking the HTML
      const safeUrl = url.replace(/"/g, '&quot;');
      return `<a href="${safeUrl}" target="_blank" rel="noopener noreferrer">${safeUrl}</a>`;
      
  });
  } catch (error) {
    // Fallback: Return the original match if an error occurs
    console.error('Error parsing URL:', error);
    return input;
}
};

export const transformGmailLinksToHTML = (input: string): string => {
  try {
    const transformed = escapeForSlackWithMarkdown(input);

    // Ensure proper URL transformation and escaping of quotes
    return transformed.replace(/(http:\/\/|https:\/\/)\S+/g, (url) => {
      // Escape quotes in the URL to prevent breaking the HTML
      const safeUrl = url.replace(/"/g, '&quot;');
      return `<a href="${safeUrl}" target="_blank" rel="noopener noreferrer">${safeUrl}</a>`;
      
  });
  } catch (error) {
    // Fallback: Return the original match if an error occurs
    console.error('Error parsing URL:', error);
    return input;
}
};


export const removeBeginingTagOfString = (input: string): string => {
  if ("<" == input.charAt(0)) {
    return input.slice(1);
  }
  return input;
};
