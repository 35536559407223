import {
  RealtimeChannel,
  RealtimePostgresUpdatePayload,
} from "@supabase/supabase-js";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { AnimatePresence, Reorder, useWillChange } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

import { LogoMessage } from "../../components/LogoMessage/LogoMessage";
import { PageLoader } from "../../components/PageLoader";
import { LoaderIcon } from "../../components/icons/Loader";
import { ToastManager } from "../../components/info/MagicPromptToast";
import { Thread } from "../../components/thread/Thread";
import { Toast } from "../../components/toast";
import { getSession, supabaseClient } from "../../services/auth/session";
import { THREADS_TABLE_NAME } from "../../services/info/subscription";
import { queryClient } from "../../services/store";
import { archiveThreadMutation } from "../../services/thread/mutations";
import {
  removeThreadFromQueryCache,
  threadsFetchQuery,
} from "../../services/thread/queries";
import {
  threadApiSchema,
  threadListSerializedSchema,
} from "../../services/thread/schema";
import { SplitButton } from "../../components/SplitButton";

const lastRepliedThreadIds = new Set<string>();

export const Feed = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isNewThreadAvailable, setIsNewThreadAvailable] = useState(false);
  const [orderBy, setOrderBy] = useState<'sent_at' | 'final_score'>('sent_at');
  const bottomRef = useRef(null);

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    isSuccess,
    isError: isFetchError,
  } = useInfiniteQuery({
    ...threadsFetchQuery(orderBy),
    getNextPageParam: (
      lastPage: z.infer<typeof threadListSerializedSchema>,
    ) => {
      if (lastPage.nextCursor == null) return undefined;
      return lastPage.nextCursor;
    },
    initialPageParam: "",
    select: (data) => ({
      pages: [...data.pages.flatMap((data) => data.items)],
      pageParams: data.pageParams,
    }),
  });

  const archiveMutation = useMutation({
    mutationFn: archiveThreadMutation,
    onSuccess: (_, threadId) => {
      onSuccessfulArchive(threadId);
    },
    onError: (error) => {
      console.error('Failed to archive thread:', error);
    }
  });

  useEffect(() => {
    const ref = bottomRef.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.intersectionRatio >= 0.75) {
          void fetchNextPage();
        }
      },
      { threshold: [1] },
    );
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage]);

  useEffect(() => {
    if (isSuccess && searchParams.get("error") == "import" && hasData()) {
      searchParams.delete("error");
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  let websocket: RealtimeChannel | null = null;
  const [newThreadId, setNewThreadId] = useState("");
  const load = async () => {
    const session = await getSession();
    const tableOptions = {
      schema: "public",
      table: THREADS_TABLE_NAME,
      filter: `user_id=eq.${session?.user?.id}`,
    };

    let timer: NodeJS.Timeout;

    websocket = supabaseClient
      .channel("threads")
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          ...tableOptions,
        },
        (
          payload: RealtimePostgresUpdatePayload<
            z.infer<typeof threadApiSchema>
          >,
        ) => {
          if (
            payload.new.enhancement_status == "done" &&
            payload.new.is_read == false &&
            payload.new.is_deleted == false
          ) {
            if (timer) {
              clearTimeout(timer);
            }
            timer = setTimeout(() => {
              if (lastRepliedThreadIds.has(payload.new.thread_id)) {
                return;
              }
              setNewThreadId(payload.new.thread_id);
              setIsNewThreadAvailable(true);
              
            }, 1_500);
          }
        },
      )
      .subscribe();
  };

  useEffect(() => {
    void load();
    return () => {
      void websocket?.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasData = () => {
    return data?.pages.length != 0;
  };

  const refetchFeed = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsNewThreadAvailable(false);
    void queryClient.invalidateQueries({ queryKey: ["thread"] });
  };

  const onSuccessfulReply = (threadId: string) => {
    lastRepliedThreadIds.add(threadId);
    setTimeout(() => {
      lastRepliedThreadIds.delete(threadId);
    }, 5_000);

    setTimeout(() => {
      removeThreadFromQueryCache(threadId);
    }, 2_000);

    setTimeout(() => {
      archiveMutation.mutate(threadId);
    }, 5_000);
    void queryClient.invalidateQueries({ queryKey: ["thread"] });
  };

  const onSuccessfulArchive = (threadId: string) => {
    lastRepliedThreadIds.add(threadId);
    setTimeout(() => {
      lastRepliedThreadIds.delete(threadId);
    }, 5_000);
    
    try {
      removeThreadFromQueryCache(threadId);
    } catch (error) {
      console.error('Error removing thread from cache:', error);
    }
    void queryClient.invalidateQueries({ queryKey: ["thread"] });
  };
  const willChange = useWillChange();

  const handleLeftClick = () => {
    setOrderBy('sent_at');
    refetchFeed();
  };

  const handleRightClick = () => {
    setOrderBy('final_score');
    refetchFeed();
  };

  return (
    <main className="mx-4 flex w-full flex-grow flex-col gap-3 overflow-hidden px-6 py-2 lg:w-[1000px] lg:py-8">
      <ToastManager />
      {isLoading ? <PageLoader /> : ""}
      <div className="w-full flex justify-end">
        <SplitButton
          onLeftClick={handleLeftClick}
          onRightClick={handleRightClick}
        />
      </div>
      {!hasData() ? (
        <div className="flex w-full flex-1 items-center justify-center">
          {searchParams.get("error") == "import" ? (
            <LogoMessage className="w-full text-warning-regular">
              <span className="text-2xl">Oops!</span>
              <a className="text-2xl" href="mailto:dipti@lesen.ai">
                Write to us!
              </a>
            </LogoMessage>
          ) : (
            <LogoMessage className="w-full">
              <span className="text-2xl">All caught up for now!</span>
            </LogoMessage>
          )}
        </div>
      ) : (
        ""
      )}

      <Reorder.Group
        onReorder={() => void 0}
        values={data?.pages || []}
        className="mt-1 w-full space-y-3 pb-3"
      >
        <AnimatePresence initial={false}>
          {data?.pages.map((item) => (
            <Reorder.Item
              key={item.id}
              value={item}
              initial={{ opacity: 0, x: -30 }}
              animate={{
                opacity: 1,
                x: 0,
                transition: { duration: 0.15 },
              }}
              exit={{
                opacity: 0,
                x: -20,
                transition: { duration: 0.15 },
              }}
              dragListener={false}
              style={{ willChange }}
            >
              <Thread
                data={item}
                key={item.id}
                onSuccessfulReply={onSuccessfulReply}
                onSuccessfulArchive={onSuccessfulArchive}
                isNewThread={item.threadId === newThreadId}
              />
            </Reorder.Item>
          ))}
        </AnimatePresence>
      </Reorder.Group>

      {!isFetchingNextPage ? (
        <div className="h-10 w-10" ref={bottomRef}></div>
      ) : (
        <div className="flex w-full items-center justify-center">
          <LoaderIcon />
        </div>
      )}

      {isNewThreadAvailable && (
        <div
          className="fixed bottom-6 left-0 right-0 m-auto w-48 cursor-pointer rounded-full bg-main px-6 py-4 text-center shadow-lg shadow-black"
          onClick={() => {
            refetchFeed();
          }}
        >
          Updates available
        </div>
      )}

      

      {isFetchError ? (
        <Toast
          key="error-toast"
          type="error"
          variant="primary"
          message={"Some error occurred."}
        />
      ) : null}
    </main>
  );
};

