import { QueryClient } from "@tanstack/react-query";
import { ActionFunction } from "react-router-dom";
import { z } from "zod";

import { infoItemKeys } from "../../services/info/queries";
import { CustomPromptListMutation } from "../../services/magic-prompts/prompts";

const threadId = z.string();

const ThreadIdList = z.array(threadId);

const ThreadUpdateApiSchema = z.object({
  ids: ThreadIdList.optional(),
  intent: z.enum(["dismiss", "restore"]),
  source: z.enum(["single", "all"]),
  customPromptId: z.string()
});

const ThreadListPayloadSerializedSchema = z.array(
  z.object({
    id: threadId,
    is_read: z.boolean(),
  }),
);

export const customPromptsAction = (queryClient: QueryClient) =>
  (async ({ request }: { request: Request }) => {
    const body = (await request.json()) as z.infer<typeof ThreadListPayloadSerializedSchema>;
    
    const { ids, intent, source, customPromptId } = ThreadUpdateApiSchema.parse(body);

    const result = ThreadIdList.safeParse(ids);

    if (!result.success) return null;
    const { data } = result;

    // Store the archived message in localStorage so we do not pop up a browser notification for them when we restore them
    const currentArchivedItems = JSON.parse(localStorage.getItem("recentlyArchivedThreads") ?? "[]") as string[];
    const updatedArchivedItems = [...new Set([...currentArchivedItems, ...data])];
    localStorage.setItem("recentlyArchivedThreads", JSON.stringify(updatedArchivedItems));

    const payload = data.map((id) => ({
      id,
      is_read: true
    }));
    ThreadListPayloadSerializedSchema.parse(payload);
  

    const { mutationFn } = CustomPromptListMutation(customPromptId);

    await mutationFn(payload);

    // ToDo: investigate why this was needed if the mutation already cancels the queries
    if(intent === "restore") {
      void queryClient.invalidateQueries({
        queryKey: infoItemKeys.lists(),
        refetchType: "all"
      });
    }
    
    return {
      source,
      intent,
      ids,
    };
  }) satisfies ActionFunction;
