import { useState } from "react";

import { Tag } from ".";
import { TagProps } from "./types";

export const TagList = ({
  tags,
  defaultOpen = false,
}: {
  tags: TagProps[];
  defaultOpen?: boolean;
}) => {
  const [open, setOpen] = useState(defaultOpen);

  const [firstTag, ...restTags] = tags;

  if (restTags.length === 0) return <Tag {...firstTag} />;

  return (
    <>
      <Tag {...firstTag} />

      {open ? (
        restTags.map((tag, index) => <Tag key={index} {...tag} />)
      ) : (
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setOpen(true);
          }}
          className="text-sm italic text-info-dark"
        >
          +{restTags.length}
        </button>
      )}
    </>
  );
};

export const TagListFull = ({
  tags,
}: {
  tags: TagProps[];
}) => {
  return (
    <>
      {tags.map((tag, index) => (
        <div key={index} className="mr-2 mb-2 inline-block"> {/* Add inline-block and spacing classes */}
          <Tag {...tag} />
        </div>
      ))}
    </>
  );
};