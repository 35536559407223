import { QueryClient } from "@tanstack/react-query";
import { Suspense } from "react";
import { Await, defer, useLoaderData, useNavigate } from "react-router-dom";

import { PageLoader } from "../../components/PageLoader";
import { verifyAccountSetup } from "../../services/auth/account";
import { authQuery } from "../../services/auth/queries";
import { getSession, supabaseClient } from "../../services/auth/session";

type LoaderData = Awaited<ReturnType<typeof getLoaderData>>;

const getLoaderData = (queryClient: QueryClient) => {
  const load = async () => {
    await queryClient.ensureQueryData({
      ...authQuery(),
      staleTime: 1000 * 60 * 60, // 1 hour
    });
    const session = await getSession();
    if (session) {
      const response = await supabaseClient
        .from("threads")
        .select()
        .eq("user_id", session.user.id)
        .eq("enhancement_status", "done")
        .limit(1)
        .maybeSingle();

      if (response.data) {
        return Promise.resolve("/home");
      } else {
        await verifyAccountSetup();
        return Promise.resolve("/import");
      }
    } else {
      return Promise.resolve("/login");
    }
  };
  return {
    load: load(),
  };
};

// eslint-disable-next-line react-refresh/only-export-components
export const preImportLoader = (queryClient: QueryClient) => {
  return () => defer(getLoaderData(queryClient));
};

export const PreImport = () => {
  const data = useLoaderData() as LoaderData;
  const navigate = useNavigate();

  void data.load.then((path: string) => {
    navigate(path);
  });

  return (
    <>
      <Suspense
        fallback={
          <div className="min-w-screen flex min-h-screen flex-col items-center bg-main">
            <PageLoader>
              <h1 className="text-xl ">Checking...</h1>
            </PageLoader>
          </div>
        }
      >
        <Await resolve={data.load}>{() => <></>}</Await>
      </Suspense>
    </>
  );
};
