import { API_THREADS_SERVICE_URL, ApiError } from "../../utils/api";
import { handleApiException, safeFetch } from "../../utils/safeFetch";

export interface PostReplyMutationParams {
  threadId: string;
  message: string;
}
export const postReplyMutation = (data: PostReplyMutationParams) => {
  return postReply(data.threadId, data.message);
};

const postReply = async (threadId: string, message: string) => {
  try {
    const response = await safeFetch(
      `${API_THREADS_SERVICE_URL}/threads/${threadId}/reply`,
      {
        method: "POST",
        body: JSON.stringify({
          message,
        }),
      },
    );
    return response;
  } catch (error) {
    return handleApiException(ApiError.FailedToPostReply, error);
  }
};

export const archiveThreadMutation = (threadId: string) => {
  return archiveThread(threadId);
};

const archiveThread = async (threadId: string) => {
  try {
    const response = await safeFetch(
      `${API_THREADS_SERVICE_URL}/threads/${threadId}`,
      {
        method: "PATCH",
        body: JSON.stringify({
          is_read: true,
        }),
      },
    );
    return response;
  } catch (error) {
    return handleApiException(ApiError.FailedToArchiveThread, error);
  }
};
