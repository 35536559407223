// src/components/SplitButton.tsx
import React, { useState } from 'react';
import { ClockIcon, PriorityIcon } from '../icons';


interface SplitButtonProps {
  onLeftClick: () => void;
  onRightClick: () => void;
}

export const SplitButton: React.FC<SplitButtonProps> = ({ onLeftClick, onRightClick }) => {
  const [activeSide, setActiveSide] = useState<'left' | 'right'>('left');

  const handleLeftClick = () => {
    setActiveSide('left');
    onLeftClick();
  };

  const handleRightClick = () => {
    setActiveSide('right');
    onRightClick();
  };

  return (
    <div className="split-button">
      <div
        className={`split-button-left ${activeSide === 'left' ? 'active' : ''}`}
        onClick={handleLeftClick}
        title='Order by time'
      >
        <ClockIcon fill={activeSide === 'left' ? '#FFFFFF' : '#007bff'} strokeWidth={15} />
      </div>
      <div
        className={`split-button-right ${activeSide === 'right' ? 'active' : ''}`}
        onClick={handleRightClick}
        title='Order by importance'
      >
        <PriorityIcon fill={activeSide === 'left' ? '#007bff' : '#FFFFFF'} />
      </div>
    </div>
  );
};
