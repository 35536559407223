import { InfiniteData } from "@tanstack/react-query";
import { z } from "zod";

import { API_THREADS_SERVICE_URL, ApiError } from "../../utils/api";
import { handleApiException, parsedFetch } from "../../utils/safeFetch";
import { queryClient } from "../store";
import {
  serializeThreadListPayload,
  threadListApiSchema,
  threadListSerializedSchema,
} from "./schema";

export const threadsFetchQuery = (orderBy = 'sent_at') => ({
  queryKey: ["thread", orderBy],
  queryFn: async ({
    pageParam,
  }: {
    pageParam?: string;
  }): Promise<z.infer<typeof threadListSerializedSchema>> => {
    const data = await fetchThreads(pageParam, null, orderBy);
    return data;
  },
  staleTime: 5000,
});

export const threadsFollowingFetchQuery = (vipContacts: string | null = null, orderBy = 'sent_at') => ({
  queryKey: ["threads_following", vipContacts, orderBy],
  queryFn: async ({
    pageParam,
  }: {
    pageParam?: string;
  }): Promise<z.infer<typeof threadListSerializedSchema>> => {
    const data = await fetchThreads(pageParam, vipContacts, orderBy);
    return data;
  },
  staleTime: 5000,
});

const fetchThreads = async (
  cursor: string | null = null, 
  vipContacts: string | null = null, 
  orderBy = 'sent_at'
): Promise<z.infer<typeof threadListSerializedSchema>> => {
  try {
    const params = new URLSearchParams([
      ["order_by", orderBy],
      ["is_read", "false"],
      ["is_delete", "false"]
    ]);
    if (cursor) params.append("cursor", cursor);
    if (vipContacts) params.append("vip_contacts", vipContacts);
    
    const response = await parsedFetch(
      threadListApiSchema,
      `${API_THREADS_SERVICE_URL}/threads?${params.toString()}`,
      {
        method: "GET",
      },
    );
    const data = await serializeThreadListPayload(response);
    return data;
  } catch (error) {
    return handleApiException(ApiError.FailedToFetchInfoItem, error);
  }
};

export const removeThreadFromQueryCache = (threadId: string) => {
  queryClient.setQueryData(
    ["thread"],
    (data: InfiniteData<z.infer<typeof threadListSerializedSchema>>) => {
      return {
        ...data,
        pages: data?.pages?.map((page) => {
          return {
            ...page,
            items: page.items.filter((item) => item.threadId !== threadId),
          };
        }),
      };
    },
  );
};

export const removeThreadFollowingFromQueryCache = (threadId: string) => {
  queryClient.setQueryData(
    ["threads_following"],
    (data: InfiniteData<z.infer<typeof threadListSerializedSchema>>) => {
      return {
        ...data,
        pages: data?.pages.map((page) => {
          return {
            ...page,
            items: page.items.filter((item) => item.threadId !== threadId),
          };
        }),
      };
    },
  );
};