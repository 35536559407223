import { SVGProps } from "react";

export const AlertIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.4585 21.4373H2.54348C2.32424 21.4366 2.10899 21.3787 1.91896 21.2694C1.72893 21.1601 1.57069 21.0031 1.45988 20.8139C1.34864 20.6243 1.2897 20.4087 1.28907 20.1889C1.28844 19.9691 1.34613 19.7531 1.45628 19.5629L10.9195 3.1871C11.0286 2.99705 11.1861 2.83921 11.3758 2.72953C11.5656 2.61985 11.7809 2.56223 12.0001 2.5625C12.4459 2.5625 12.8611 2.8019 13.0831 3.1877L22.5205 19.5221C22.6388 19.7116 22.7042 19.9294 22.71 20.1528C22.7158 20.3761 22.6617 20.5969 22.5533 20.7923C22.445 20.9878 22.2863 21.1506 22.0938 21.264C21.9013 21.3774 21.6819 21.4373 21.4585 21.4373Z"
      fill="currentColor"
    />
    <path
      d="M11.4141 9.32988V14.5505C11.4214 14.7262 11.4964 14.8922 11.6233 15.0139C11.7502 15.1355 11.9192 15.2035 12.0951 15.2035C12.2709 15.2035 12.4399 15.1355 12.5668 15.0139C12.6937 14.8922 12.7687 14.7262 12.7761 14.5505V9.32988C12.7759 9.1491 12.7042 8.97573 12.5766 8.84767C12.449 8.71961 12.2758 8.64728 12.0951 8.64648C11.7213 8.64648 11.4141 8.95428 11.4141 9.32988ZM12.0957 16.1399C11.7213 16.1399 11.4147 16.4435 11.4147 16.8191V17.2757C11.4159 17.4557 11.4881 17.628 11.6156 17.7552C11.7431 17.8823 11.9156 17.9541 12.0957 17.9549C12.4713 17.9549 12.7767 17.6471 12.7767 17.2757V16.8191C12.7767 16.4435 12.4707 16.1399 12.0957 16.1399Z"
      fill="white"
    />
  </svg>
);
