import * as RadixDialog from "@radix-ui/react-dialog";
import { ReactNode } from "react";

type SheetProps = {
  side: "left" | "right";
  trigger: ReactNode;
  content: ReactNode;
  fullScreen?: boolean;
  container?: HTMLElement;
} & RadixDialog.DialogProps;

export const Sheet = ({
  side,
  trigger,
  content,
  container,
  modal = false,
  fullScreen = false,
  ...dialogProps
}: SheetProps) => {
  const sheetTransitionDirection = {
    left: "sheet-transition-dir-left",
    right: "sheet-transition-dir-right",
  }[side];

  return (
    <RadixDialog.Root modal={modal || fullScreen} {...dialogProps}>
      <RadixDialog.Trigger className="outline-none focus:outline-none">
        {trigger}
      </RadixDialog.Trigger>
      <RadixDialog.Portal container={container}>
        <RadixDialog.Content
          className={`sheet-transition ${sheetTransitionDirection} ${
            fullScreen ? "fixed inset-0 z-50" : "h-full w-full"
          }`}
          // NOTE: This prevents outside click from closing the sheet, see docs
          onPointerDownOutside={(e) => e.preventDefault()}
        >
          {content}
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};
