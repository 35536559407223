import { z } from "zod";

import { API_BACKEND_URL, ApiError } from "../../../utils/api";
import { handleApiException, parsedFetch } from "../../../utils/safeFetch";
import { getSession } from "../../auth/session";
import { queryClient } from "../../store";
import { integrationsKeys } from "../query";
import { gmailSettingsActionSerializedSchema, gmailSettingsApiSchema } from "./schema";

const updateGmailSettings = async (
  settings: z.infer<typeof gmailSettingsActionSerializedSchema>,
) => {
  const session = await getSession();

  try {
    const response = await parsedFetch(
      gmailSettingsApiSchema,
      `${API_BACKEND_URL}/users/${session.user.id}/settings/gmail`,
      {
        method: "PATCH",
        body: JSON.stringify(settings),
      },
    );

    return Promise.resolve(response);
  } catch (error) {
    return handleApiException(ApiError.FailedToUpdateGmailSettings, error);
  }
};

export const gmailSettingsMutation = () => ({
  mutationFn: async (settings: z.infer<typeof gmailSettingsActionSerializedSchema>) => {
    const queryKey = integrationsKeys.gmail();

    await queryClient.cancelQueries({ queryKey });

    const previous = queryClient.getQueryData(queryKey);

    queryClient.setQueryData(queryKey, settings);

    try {
      return await updateGmailSettings(settings);
    } catch (error) {
      queryClient.setQueryData(queryKey, previous);
      return Promise.reject(error);
    }
  },
});
