import { Link } from "react-router-dom";

export const FollowingHeader = () => {
  return (
    <>
      <div className="flex justify-center gap-4 text-xl lg:text-3xl">
        <Link to={"/home"}>
          <span className="font-thin">
            For you
          </span>
        </Link>
        <Link className="font-black underline underline-offset-8" to={"/following"}>
          Following
        </Link>
      </div>
    </>
  );
};
