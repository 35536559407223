import { TagFill, TagProps, TagType } from "./types";

export const Tag = ({
  fill = TagFill.LIGHT,
  type = TagType.NEUTRAL,
  children,
}: TagProps) => {
  let tagClass = "";

  if (fill === TagFill.LIGHT) {
    switch (type) {
      case TagType.INFO:
        tagClass = "border-info-light bg-info-light text-info-regular";
        break;
      case TagType.DANGER:
        tagClass = "border-error-light bg-error-light text-error-regular";
        break;
      case TagType.ALERT:
        tagClass = "border-alert-light bg-alert-light text-alert-regular";
        break;
      case TagType.WARNING:
        tagClass = "border-warning-light bg-warning-light text-alert-dark";
        break;
      case TagType.SUCCESS:
        tagClass = "border-success-light bg-success-light text-success-dark";
        break;
      case TagType.LIGHT:
        tagClass = "bg-white border-transparent";
        break;
      case TagType.NEUTRAL:
      default:
        tagClass = "border-gray-50 bg-gray-50 text-gray-primary";
        break;
    }
  } else if (fill === TagFill.DARK) {
    switch (type) {
      case TagType.INFO:
        tagClass = "border-info-regular/10 bg-info-regular/60 text-white";
        break;
      case TagType.DANGER:
        tagClass = "border-error-regular/10 bg-error-regular/60 text-white";
        break;
      case TagType.ALERT:
        tagClass = "border-alert-regular/10 bg-alert-regular/60 text-white";
        break;
      case TagType.WARNING:
        tagClass = "border-warning-regular/10 bg-warning-regular/60 text-white";
        break;
      case TagType.SUCCESS:
        tagClass = "border-success-regular/10 bg-success-regular/60 text-white";
        break;
      case TagType.NEUTRAL:
      default:
        tagClass = "border-gray-primary/10 bg-gray-primary/60 text-white";
        break;
    }
  }

  return (
    <div
      className={`inline-flex cursor-default gap-2 rounded-sm border px-3 py-1 text-sm transition-colors ${tagClass}`}
    >
      {children}
    </div>
  );
};
