import { RouteObject, createBrowserRouter } from "react-router-dom";

import { Authentication, loader as authLoader } from "../routes/Authentication";
import { DetailsLayout } from "../routes/_layout/details";
import { MainLayout } from "../routes/_layout/main";
import { MainFlowLayout } from "../routes/_layout/mainFlow";
import { AuthError } from "../routes/auth/AuthError";
import { Details, loader as detailsLoader } from "../routes/details/Details";
import { ErrorRoot } from "../routes/error";
import { Feed } from "../routes/feed/feed";
import { Import, loader as importLoader } from "../routes/import/Import";
import { Importing } from "../routes/importing/Importing";
import { action as infoAction } from "../routes/info";
import {
  Integrations,
  loader as integrationLoader,
} from "../routes/integrations/Integrations";
import { loader as logoutLoader } from "../routes/logout";
import {
  MagicCommsPersonalization,
  action as magicCommsPersonalizationAction,
  loader as magicCommsPersonalizationLoader,
} from "../routes/magic-comms";
import { action as notificationAction } from "../routes/notification";
import { PreImport, preImportLoader } from "../routes/pre-import/preImport";
import { Root, loader as rootLoader } from "../routes/root";
import { Settings, loader as integrationsLoader } from "../routes/settings";
import {
  Communication,
  action as communicationAction,
  loader as communicationLoader,
} from "../routes/settings/Communication";
import { Notifications } from "../routes/settings/Notifications";
import {
  Gmail,
  action as gmailAction,
  loader as gmailLoader,
} from "../routes/settings/integrations/Gmail";

import {
  Slack,
  loader as slackLoader,
} from "../routes/settings/integrations/Slack";
import { customPromptsAction } from "../routes/threads";
import {
  ViewAll,
  action as viewAllAction,
  loader as viewAllLoader,
} from "../routes/view-all";
import { VipCommsPersonalization } from "../routes/vip-contacts";
import { action as vipCommsPersonalizationAction } from "../routes/vip-contacts/actionFunctions";
import { queryClient } from "../services/store";
import { getLoaderData as vipCommsLoader } from "../utils/vipContactsUtils";
import { Following } from "../routes/following/following";
import { SyncSettings, action as syncAction,
  loader as syncLoader, } from "../routes/settings/SyncSettings";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Root />,
    loader: rootLoader(queryClient),
    errorElement: <ErrorRoot />,
    children: [
      {
        element: <MainLayout />,
        children: [
          {
            path: "/home",
            element: <Feed />,
          },
          {
            path: "/following",
            element: <Following />,
          },
          {
            path: "/hot-communication-settings",
            element: <Communication />,
            loader: communicationLoader(queryClient),
            action: communicationAction(),
          },
          {
            path: "/view-all",
            element: <ViewAll />,
            loader: viewAllLoader(queryClient),
            action: viewAllAction(),
          },
          {
            path: "/vip-comms",
            element: <VipCommsPersonalization />,
            loader: () => vipCommsLoader(queryClient),
            action: vipCommsPersonalizationAction,
          },
          {
            path: "integrations",
            element: <Integrations />,
            loader: integrationLoader(queryClient),
          },
          {
            path: "/sync-settings",
            element: <SyncSettings />,
            loader: syncLoader(queryClient),
            action: syncAction(queryClient),
          },
          {
            path: "/settings",
            element: <Settings />,
            loader: integrationsLoader(queryClient),
            children: [
              {
                path: "/settings/integrations/gmail",
                element: <Gmail />,
                loader: gmailLoader(queryClient),
                action: gmailAction(queryClient),
              },
              {
                path: "/settings/integrations/slack",
                element: <Slack />,
                loader: slackLoader(queryClient),
              },
              {
                path: "/settings/notifications",
                element: <Notifications />,
              },
            ],
          },
        ],
      },
      {
        element: <DetailsLayout />,
        children: [
          {
            path: "/details/:id",
            element: <Details />,
            loader: detailsLoader(queryClient),
          },
          {
            path: "/magic-comms-personalization/:id?",
            element: <MagicCommsPersonalization />,
            loader: magicCommsPersonalizationLoader(queryClient),
            action: magicCommsPersonalizationAction(),
          },
        ],
      },
      {
        path: "/notification",
        action: notificationAction(queryClient),
      },
      {
        path: "/info",
        action: infoAction(queryClient),
      },
      {
        path: "/customPrompt",
        action: customPromptsAction(queryClient),
      },
    ],
  },
  {
    path: "/pre-import",
    element: <PreImport />,
    errorElement: <ErrorRoot />,
    loader: preImportLoader(queryClient),
  },
  {
    path: "",
    element: <MainFlowLayout />,
    errorElement: <ErrorRoot />,
    children: [
      {
        path: "login",
        element: <Authentication />,
        loader: authLoader,
      },
      {
        path: "signup",
        element: <Authentication />,
        loader: authLoader,
      },
      {
        path: "authError",
        element: <AuthError />,
      },
      {
        path: "import",
        element: <Import />,
        loader: importLoader(queryClient),
      },
      {
        path: "importing",
        element: <Importing />,
      },
      // {
      //   index: true,
      //   element: <Navigate to="/login" />,
      // },
    ],
  },
  {
    path: "/logout",
    loader: logoutLoader,
  },
];

export const router = createBrowserRouter(routes);
