import { SVGProps } from "react";

export const SendMessageIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 101 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_332_476)">
        <path
          d="M92.9794 39.5764C93.3058 40.6411 93.3418 41.7736 93.0836 42.8568C92.8255 43.9401 92.2827 44.9347 91.5112 45.7377L39.6467 100.685C38.9011 101.469 37.9664 102.048 36.9324 102.366C35.9769 102.651 34.9672 102.703 33.9877 102.518C33.0081 102.332 32.087 101.916 31.3013 101.302C30.5156 100.688 29.8881 99.8957 29.4711 98.9902C29.0542 98.0846 28.8599 97.0924 28.9046 96.0965L29.9208 63.1489C29.9307 62.8268 30.0399 62.5156 30.2336 62.258C30.4272 62.0004 30.6958 61.809 31.0024 61.71L58.0184 53.5051C58.4285 53.3815 58.8089 53.1749 59.1359 52.8981C59.4629 52.6213 59.7295 52.2803 59.9191 51.8961C60.1087 51.512 60.2173 51.0929 60.2381 50.665C60.259 50.2371 60.1916 49.8095 60.0402 49.4088C59.7389 48.6605 59.166 48.0536 58.4363 47.7097C57.7066 47.3659 56.8739 47.3104 56.1049 47.5543L29.1937 55.7274C28.8809 55.8224 28.5462 55.817 28.2367 55.7118C27.9271 55.6066 27.6584 55.4069 27.4683 55.141L8.30222 28.3269C7.55273 27.3036 7.13232 26.0766 7.09682 24.8087C7.06133 23.5408 7.41244 22.2921 8.1035 21.2285C8.79457 20.1649 9.79289 19.3368 10.9658 18.8541C12.1388 18.3714 13.4308 18.257 14.6703 18.526L88.3482 35.2961C89.4303 35.535 90.4295 36.0572 91.2433 36.8094C92.0571 37.5615 92.6563 38.5165 92.9794 39.5764Z"
          fill="black"
          fillOpacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_332_476">
          <rect
            width="100"
            height="100"
            fill="white"
            transform="translate(0.929688 0.96582)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
